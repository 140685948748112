import React from "react";
import { FAILD_TO_DELIVER } from "./UpdateBooking";

class BookingStatus extends React.Component {
  render() {
    const { strings } = this.props;
    let customStyle = "underProcess";
    if (this.props.status === "Failed to deliver") {
      customStyle = "processFailed";
    }
    const statusColor = this.props.status === FAILD_TO_DELIVER && "#d01c1a";
    return (
      <div className="BookingstatusMain">
        <p className="deliveryStatus">
          {strings &&
            strings.existingCustomerForm.existingCustomer.deliveryStatus}
        </p>
        <p className={customStyle} style={{ color: statusColor }}>
          {this.props.status}
        </p>
      </div>
    );
  }
}

BookingStatus.defaultProps = {
  status: ""
};

export default BookingStatus;
