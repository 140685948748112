export function getError(result) {
  let errorDeatails = {};
  if (result.error && result.error.errorCode >= 400) {
    let errorDeatails = {};
    errorDeatails.message = `${result.error && result.error.errorMessage}`;
    errorDeatails.errorCode = `${result.error && result.error.errorCode}`;
    errorDeatails.hasError = true;

    return errorDeatails;
  } else {
    errorDeatails.hasError = false;
    errorDeatails.message = `${result.error && result.error.errorMessage}`;
    return errorDeatails;
  }
}
