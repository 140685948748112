import * as actions from "../actions/return.action";
const OrderInquiry = (
  state = {
    OrderInquiryDataLoading: false,
    OrderInquiryData: {},
    OrderInquiryDataStatus: "",
    OrderInquiryDataError: null
  },
  action
) => {
  switch (action.type) {
    case actions.GET_ORDER_INQUIRY_DATA_REQUESTING:
      return Object.assign({}, state, {
        OrderInquiryDataLoading: true,
        OrderInquiryDataStatus: action.status
      });
    case actions.GET_ORDER_INQUIRY_DATA_SUCCESS: {
      return Object.assign({}, state, {
        OrderInquiryDataLoading: false,
        OrderInquiryData: action.data,
        OrderInquiryDataStatus: action.status
      });
    }
    case actions.GET_ORDER_INQUIRY_DATA_FAILURE: {
      return Object.assign({}, state, {
        OrderInquiryDataLoading: false,
        OrderInquiryDataStatus: action.status,
        OrderInquiryDataError: action.error
      });
    }

    default:
      return state;
  }
};

export default OrderInquiry;
