import { getLanguage } from "../../utils/LocalStorage";

const data_english = {
  homeForm: {
    orderDetails: {
      title: "Order Details",
      order: "Order #",
      store: "Store"
    },
    items: {
      quantity: "Quantity",
      totalItems: "Total items"
    },
    button: "Start your Booking",
    invalidUrl: "Kindly check the URL",
    tryAgain: "Try Again"
  },
  customerForm: {
    contactInfo: {
      title: "Contact Information",
      customerName: "Customer Name",
      mobile: "Mobile",
      email: "Email",
      someoneElseReceive: "Someone else to receive?",
      someoneCustomerName: "Customer Name",
      someoneMobile: "Mobile"
    },
    placeholder: {
      dropdown: "Please Select",
      name: "Please Enter Your Name",
      mobile: "Please Enter Mobile Number",
      email: "Please Enter Email",
      buildingNumber: "Please Enter Building Number",
      otherComments: "Other Comments"
    },
    deliveryAddress: {
      title: "Delivery Address",
      address: "Address",
      pinOnMap: "Pin On Map",
      what3Words: "What3Words",
      city: "City",
      district: "District",
      alertMesg: "Please select location inside the boundaries",
      mapButton: "Select This Address",
      exitMapAlertMesg: "Do you wish to close map without selecting location?"
    },
    mapForm: {
      title: "Delivery Address Map",
      findAddress: "Find an Address",
      markedAddress: "Marked Address",
      what3Words: "What3words",
      what3wordsPlaceholder: "" // دبوس.قصائد.الدارس
    },
    siteInformation: {
      title: "Site Information",
      building: "Building #",
      buildingType: "Building Type",
      floor: "Floor #",
      buildingTypes: [{ value: "Flat" }, { value: "Villa" }]
    },
    validation: {
      PLEASE_ENTER_CUSTOMER_NAME: "Please enter the customer name",
      PLEASE_ENTER_MOBILE_NIMBER: "Please enter the mobile number",
      PLEASE_ENTER_RECEIVER_NAME: "Please enter the receiver name",
      PLEASE_SELECT_THE_ADDRESS: "Please select the Address",
      PLEASE_SELECT_THE_CITY: "Please select the city",
      PLEASE_SELECT_THE_DISTRICT: "Please select the district",
      PLEASE_ENTER_THE_BUILDING_NEMBER: "Please enter the building number",
      PLEASE_ENTER_THE_BUILDING_TYPE: "Please select the building type",
      PLEASE_SELECT_THE_FLOOR: "Please select the floor",
      PLEASE_SELECT_THE_QUESTIONNAIRE: "Please Answer All the Questions",
      PLEASE_ENTER_THE_MANDATORY_FIELDS: "Please enter the mandatory fields",
      PLEASE_ENTER_ENTER_ID: "Please enter email id",

      NAME_MUST_BE: "Name must be 5 characters long!",
      MOBILE_NUMBER_MUST_BE: "Mobile number must be valid!",
      BUILDING_NUMBER_MUST_BE: "Building number must be 3 digits!",
      COMMENT_MUST_BE: "Comment must be 50 characters long!",
      RECEIVER_NAME_MUST_NOT_MATCH:
        "Please enter receiver name. It should not match customer name",
      RECEIVER_NUMBER_MUST_NOT_MATCH:
        "Please enter receiver number. It should not match customer number",
      ENTER_VALID_ENTER_ID: "Enter valid email id",
      PLEASE_SELECT_TIME_SLOT: "Please select time slot"
    },
    button: "Next  >  Delivery Timing"
  },
  calenderForm: {
    timeLine: {
      delieveryDetails: "Delivery Details",
      delieveryTiming: "Delivery Timing",
      confirmation: "Confirmation"
    },
    button: "Next  >  Confirmation",
    available: "Available",
    notAvailable: "Not Available",
    one: "1",
    two: "2",
    three: "3",
    alertHeader: "Confirm to submit",
    alertTitle: "Are you sure to confirm",
    alertButton: "Ok",
    slot: "slot on"
  },
  confirmationForm: {
    bookingSummary: {
      bookingSummaryTitle: "Booking Summary",
      contactInformation: "Contact information",
      edit: "Edit",
      deliveryAddress: "Delivery Address",
      siteInformation: "Site Information",
      building: "Building #",
      buildingType: "Building Type",
      floor: "Floor:",
      siteStatus: "Site Status:",
      otherComments: "OtherComments:",
      deliveryTime: "Delivery Time"
    },
    edit: "Edit",
    button: "Confirm"
  },
  thankYouForm: {
    thankYouTitle: "Thank You, your Booking is confirmed!",
    receiveInformation: "You will receive a information",
    sms: "SMS on",
    bookingNumber: "Booking Number",
    deliveryTime: "Delivery Time",
    pleaseNoteTitle: "Please Note",
    pleaseNote:
      "100 SAR will be charged for next delivery if the customer wasn't able to receive the shipped items.",
    contactUs: "Contact Us",
    note:
      "100 SAR will be charged for next delivery if the customer wasn't able to receive the shipped items.",
    toll: "Toll 920004123",
    available: "Available from 9:00 am - 11:00 pm",
    button: "Ok"
  },
  existingCustomerForm: {
    existingCustomer: {
      welcomeBack: "Welcome back",
      bookingConfirmed: "Your booking is confirmed.",
      deliveryStatus: "Delivery Status",
      underProcess: "Under process",
      bookingNumber: "Booking Number",
      deliveryTime: "Delivery Time",
      description:
        "You can update your booking details for free as long as the shipment status is still under process. Please contact us if you need help or for any further details.",
      areYouSureMesg: "Are you sure you want to start new booking?"
    }
  },
  footerForm: {
    termsAndCondition: "Terms & Conditions",
    termsAndConditionLink: "https://www.extra.com/en-sa/terms",
    copyright: "Copyright © 2019 extra."
  }
};

const data_arabic = {
  homeForm: {
    orderDetails: {
      title: "تفاصيل الطلب",
      order: "الطلب",
      store: "المعرض"
    },
    items: {
      quantity: "الكمية",
      totalItems: "عدد المنتجات"
    },
    button: "حجز موعد",
    invalidUrl: "يرجى التحقق من عنوان URL",
    tryAgain: "حاول ثانية"
  },

  customerForm: {
    contactInfo: {
      title: "بيانات العميل",
      customerName: "الاسم",
      mobile: "رقم الجوال",
      email: "البريد الإلكتروني",
      someoneElseReceive: "استلام بواسطة شخص آخر",
      someoneCustomerName: "إسم المستلم",
      someoneMobile: "رقم جوال المستلم"
    },
    placeholder: {
      dropdown: "الرجاء اختيار",
      name: "يرجى إدخال اسمك",
      mobile: "الرجاء إدخال رقم الجوال",
      email: "الرجاء إدخال البريد الإلكتروني",
      buildingNumber: "الرجاء إدخال رقم المبنى",
      otherComments: "تعليقات أخرى"
    },
    deliveryAddress: {
      title: "تفاصيل العنوان",
      address: "العنوان",
      pinOnMap: "تحديد بواسطة الخريطة",
      what3Words: "تحديد بواسطة / ثلاث كلمات",
      city: "المدينة",
      district: "الحي",
      alertMesg: "يرجى تحديد الموقع داخل الحدود",
      mapButton: "اختر هذا العنوان",
      exitMapAlertMesg: "هل ترغب في إغلاق الخريطة دون تحديد الموقع؟"
    },
    mapForm: {
      title: "خريطة عنوان التوصيل",
      findAddress: " البحث عن عنوان",
      markedAddress: "العنوان المحدد",
      what3Words: "العنوان المحدد",
      what3wordsPlaceholder: ""
    },
    siteInformation: {
      title: "تفاصيل الموقع",
      building: "رقم المبنى",
      buildingType: "نوع المبنى",
      floor: "رقم الطابق",
      buildingTypes: [{ value: "شقة" }, { value: "فيلا" }]
    },
    validation: {
      PLEASE_ENTER_CUSTOMER_NAME: "الرجاء إدخال اسم العميل",
      PLEASE_ENTER_MOBILE_NIMBER: "الرجاء إدخال رقم الجوال",
      PLEASE_ENTER_RECEIVER_NAME: "الرجاء إدخال اسم المتلقي",
      PLEASE_SELECT_THE_ADDRESS: "يرجى اختيار العنوان",
      PLEASE_SELECT_THE_CITY: "يرجى اختيار المدينة",
      PLEASE_SELECT_THE_DISTRICT: "يرجى اختيار المنطقة",
      PLEASE_ENTER_THE_BUILDING_NEMBER: "الرجاء إدخال رقم المبنى",
      PLEASE_ENTER_THE_BUILDING_TYPE: "يرجى اختيار نوع المبنى",
      PLEASE_SELECT_THE_FLOOR: "يرجى اختيار الكلمة",
      PLEASE_SELECT_THE_QUESTIONNAIRE: "الرجاء الإجابة على جميع الأسئلة",
      PLEASE_ENTER_THE_MANDATORY_FIELDS: "الرجاء إدخال الحقول الإلزامية",
      PLEASE_ENTER_ENTER_ID: "الرجاء إدخال معرف البريد الإلكتروني",

      NAME_MUST_BE: "يجب أن لا يقل الاسم عن خمسة أحرف",
      MOBILE_NUMBER_MUST_BE: "يجب أن يكون رقم الجوال صالحًا!",
      BUILDING_NUMBER_MUST_BE: "يجب أن لا يقل رقم المبنى عن ثلاثة أحرف",
      COMMENT_MUST_BE: "يجب أن لا يقل طول التعليقات عن 50 حرفًا!",
      RECEIVER_NAME_MUST_NOT_MATCH:
        "الرجاء إدخال اسم المستلم. لا ينبغي أن يتطابق مع اسم العميل",
      RECEIVER_NUMBER_MUST_NOT_MATCH:
        "الرجاء إدخال رقم المستلم. لا ينبغي أن يطابق رقم العميل",
      ENTER_VALID_ENTER_ID: "أدخل معرف بريد إلكتروني صالح",
      PLEASE_SELECT_TIME_SLOT: "يرجى تحديد موعد التوصيل"
    },
    button: "التالي  >  تحديد الموعد"
  },
  calenderForm: {
    timeLine: {
      delieveryDetails: "البيانات",
      delieveryTiming: "الموعد",
      confirmation: "التأكيد"
    },
    button: "التالي  >  مراجعة وتأكيد الحجز",
    available: "متاح",
    notAvailable: "غير متاح",
    one: "١",
    two: "٢",
    three: "٣",
    alertHeader: "تأكيد الحجز",
    alertTitle: "سيتم تأكيد الحجز ليكون في حدود الساعة ",
    alertButton: "نعم",
    slot: "في يوم"
  },
  confirmationForm: {
    bookingSummary: {
      bookingSummaryTitle: "مراجعة البيانات",
      contactInformation: "بيانات العميل",
      edit: "تعديل",
      deliveryAddress: "العنوان",
      siteInformation: "تفاصيل الموقع",
      building: "رقم المبنى",
      buildingType: "نوع المبنى",
      floor: "رقم الطابق",
      siteStatus: "جاهزية الموقع",
      otherComments: "ملاحظات",
      deliveryTime: "تاريخ التوصيل"
    },
    edit: "تعديل",
    button: "تأكيد الحجز"
  },
  thankYouForm: {
    thankYouTitle: "شكراً لك ، تم تأكيد الحجز بنجاح",
    receiveInformation: "سيتم إرسال رسالة نصية لي",
    bookingNumber: "رقم الحجز",
    deliveryTime: "تاريخ التوصيل",
    pleaseNoteTitle: ": ملحوظة", // newely added
    pleaseNote:
      "في حالة عدم تواجد العميل في الموعد المتفق عليه ، سيتم احتساب تكلفة إضافية بقيمة ١٠٠ ريال لإعادة التوصيل",
    contactUs: "تواصل معنا",
    toll: "٩٢٠٠٠٤١٢٣", // newely added
    available: "متاح من ٩ ص - ١١ م",
    button: "No button???"
  },
  existingCustomerForm: {
    existingCustomer: {
      welcomeBack: "اهلاً بك ",
      bookingConfirmed: "تم تأكيد موعد النوصيل",
      deliveryStatus: "حالة التوصيل",
      underProcess: "",
      bookingNumber: "رقم الحجز",
      deliveryTime: "تاريخ التوصيل",
      areYouSureMesg: "هل تريد الاستمرار بتعديل موعد التوصيل الخاص بطلبك ؟",
      description:
        "يمكنك تعديل موعد التوصيل طالما لم يتم تحضير أو شحن المنتج، يمكنك التواصل معنا للمساعدة او لمزيد من الاستفسارات"
    }
  },
  footerForm: {
    termsAndCondition: "الشروط و الأحكام",
    termsAndConditionLink: "https://www.extra.com/ar-sa/terms",
    copyright: "حقوق الطبع و النشر محفوظة @ ٢٠١٩ "
  }
};

const data = () => (getLanguage() == "ar" ? data_arabic : data_english);
export default data;
