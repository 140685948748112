import React, { Component } from "react";
import Note from "./Note";
import BookingDetailsBox from "../../return/components/BookingDetailsBox";
import ContactUs from "../../return/components/ContactUs";
import Check from "@material-ui/icons/Check";
import ExistingJson from "../../../constants/json/ExistingCustomerJson";
import LoaderIndicator from "../../../coreComponents/loader/LoaderIndicator";
import { getLanguage, setEditingStatus } from "../../../utils/LocalStorage";
import InfoBox from "../../../coreComponents/info-box/InfoBox"

export default class FinalPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: getLanguage()
    };
  }
  componentDidMount() {
    const { history } = this.props;
    window.scrollTo(0, 0);
    ExistingJson["isExistingCustomer"] = 1;
    this.props.getOrderData();
    this.props.getLanguageForm();

    window.onpopstate = function() {
      history.go(1);
    };
    // setEditingStatus(false);
  }

  componentDidUpdate() {
    console.log("componentDidUpdate");
    if (this.state.language !== getLanguage()) {
      this.setState({ language: getLanguage() });
      this.props.getLanguageForm();
    }
  }

  render() {
    if (this.props.orderLoading) {
      return <LoaderIndicator />;
    }
    const { orderData, strings } = this.props;
    return (
      <div>
        {strings && (
          <div className="MainContainer nav p15">
            <div className="thank_you">
              <div>
                <Check className="thankyou_check" />
              </div>
              <p className="thank_you_heading">
                {strings.thankYouForm.thankYouTitle}
              </p>

              <div className="sms">
                <span>
                  {strings.thankYouForm.receiveInformation}
                  <br />
                  {strings.thankYouForm.sms}
                </span>
                {orderData.contact && <b> {orderData.contact.mobile}</b>}
              </div>
            </div>
            <BookingDetailsBox
              {...this.props}
              id={orderData && orderData.booking && orderData.booking.id}
              date={orderData && orderData.booking && orderData.booking.date}
              time={orderData && orderData.booking && orderData.booking.time}
            />
            {/* <Note {...this.props} strings={strings} /> */}
            <InfoBox message={strings.thankYouForm.pleaseNote}/>
            {/* <ContactUs strings={strings} /> */}
            <ContactUs {...this.props} />
          </div>
        )}
      </div>
    );
  }
}
