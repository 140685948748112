import React from "react";
import { Fab } from "@material-ui/core";

class Button extends React.Component {
  handleClick() {
    if (this.props.onClick && !this.props.disabled) {
      this.props.onClick();
    }
  }
  render() {
    return (
      // <div
      //   // className={"nav _flex" + " " + this.props.homeButton}
      //   className={ this.props.homeButton}
      //   onClick={() => {
      //     this.handleClick();
      //   }}
      // >
      //   <div className="_flex">
      //     <span className="booking_button _block">{this.props.children}</span>
      //   </div>
      // </div>
      <div style={{ padding: "16px 0" }}>
        <Fab
          variant="extended"
          className=""
          style={{
            width: "100%",
            backgroundColor: "#ffe700",
            color: "#388083",
            fontWeight: "700",
            fontSize: "14px",
            textTransform: "none"
          }}
          onClick={() => this.handleClick()}
        >
          {this.props.children}
        </Fab>
      </div>
    );
  }
}

export default Button;
