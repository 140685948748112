import React from "react";
import { withRouter } from "react-router";
import { Fab, Modal } from "@material-ui/core";
import LocationOn from "@material-ui/icons/LocationOn";
import Clear from "@material-ui/icons/Clear";
import "../styles/MapStyles.css";
import Map from "../../../coreComponents/map/Map";
import { addresses } from "../../../constants/json/AddressJson";
import CustomModal from "../../../coreComponents/modal/Modal";

class MapModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
      updatedLocation: null,
      enLocation: null,
      disableButton: false,
      modalStatus: false
    };
  }

  handleBackClick() {
    this.props.history.push({
      pathname: "/deliveryInfo"
    });
    this.closeWindow();
  }

  handleOpen = () => {
    this.props.onTap && this.props.onTap();
    document.querySelector("body").style.position = "fixed";
    this.setState({ setOpen: true });
    window.onpopstate = this.handleBackClick.bind(this);
  };

  handleClose = () => {
    if (
      !this.props.locationData ||
      !(this.props.locationData && this.props.locationData.addressLine1)
    ) {
      this.setState({ modalStatus: true });
    } else {
      this.closeWindow();
    }
  };

  handleCloseCustomModal() {
    this.setState({ modalStatus: false });
  }

  handleOnOk() {
    this.setState({ modalStatus: false });
    this.closeWindow();
  }

  closeWindow() {
    window.onpopstate = "";
    document.querySelector("body").style.position = "";
    this.setState({ setOpen: false });
  }

  onSelectAddress = () => {
    const regionObj =
      this.props.region &&
      addresses.find(item => item.region === this.props.region);

    const selectedAddress =
      this.props.language == "en"
        ? this.state.updatedLocation
        : this.state.enLocation;

    const staticCitiesMatchedWithGoogleCityObj =
      regionObj &&
      selectedAddress &&
      selectedAddress.city &&
      regionObj.cities.find(item => item.googleName.toUpperCase() === selectedAddress.city.toUpperCase());

      // console.log(staticCitiesMatchedWithGoogleCityObj);
    const matchedCityEn =
      staticCitiesMatchedWithGoogleCityObj &&
      staticCitiesMatchedWithGoogleCityObj.name;

    const matchedCity =
      this.props.language == "en"
        ? matchedCityEn
        : staticCitiesMatchedWithGoogleCityObj &&
          staticCitiesMatchedWithGoogleCityObj.arName;

    const staticDistrictsMatchedWithGoogleDistrictObj =
      staticCitiesMatchedWithGoogleCityObj &&
      selectedAddress &&
      selectedAddress.city &&
      staticCitiesMatchedWithGoogleCityObj.districts.find(
        item => item.googleName.toUpperCase() === selectedAddress.district.toUpperCase()
      );

    const matchedDistrictEn =
      staticDistrictsMatchedWithGoogleDistrictObj &&
      staticDistrictsMatchedWithGoogleDistrictObj.name;

    const matchedDistrict =
      this.props.language == "en"
        ? matchedDistrictEn
        : staticDistrictsMatchedWithGoogleDistrictObj &&
          staticDistrictsMatchedWithGoogleDistrictObj.arName;

    console.log('matching city: ' + matchedCityEn + '   |   City: ' + selectedAddress.city.toUpperCase());
    console.log('matching district: ' + matchedDistrictEn + '   |   District: ' + selectedAddress.district.toUpperCase());

    this.props.onChangeLocation(this.state.updatedLocation);
    this.props.onChangeCity(matchedCity, matchedCityEn);
    this.props.onChangeDistrict(matchedDistrict, matchedDistrictEn);
    this.props.onChangeLocationEn(selectedAddress);
    this.closeWindow();
  };

  renderModal() {
    return (
      <CustomModal
        show={this.state.modalStatus}
        onCloseModal={() => this.handleCloseCustomModal()}
      >
        <div className="custom-sub-ui">
          <p>{this.props.exitMapAlertMesg}</p>
          <button
            className="success_Btn"
            onClick={() => {
              this.handleOnOk();
            }}
          >
            {this.props.alertButton}
          </button>
        </div>
      </CustomModal>
    );
  }

  render() {
    const regionObj =
      this.props.region &&
      addresses.find(item => item.region === this.props.region);

    return (
      <div>
        <Fab
          variant="extended"
          aria-label="add"
          data-type="1"
          className={"PinOnMapStyle" + " " + this.props.btnClass}
          onClick={this.handleOpen}
        >
          <LocationOn />
          {this.props.title}
        </Fab>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.state.setOpen}
          disableBackdropClick={true}
          onBackdropClick={() => console.log("onBackdropClick")}
          onClose={() => this.setState({ setOpen: false })}
        >
          <div className="mapMainContainer">
            <div className="titleContainer">
              <h6 className="maptitle"> {this.props.mapForm.title}</h6>
              <Clear className="mapcloseIcon" onClick={this.handleClose} />
            </div>
            <div className="mapContainer">
              <Map
                mapForm={this.props.mapForm}
                language={this.props.language}
                isWhat3words={this.props.isWhat3words}
                initialCoors={regionObj && regionObj.initialGeocode}
                google={this.props.google}
                location={this.props.locationData}
                polygons={regionObj && regionObj.polygons}
                // zoom={gMapData.mapZoom}
                disableButton={value => this.setState({ disableButton: value })}
                onAddressChange={(location, enLocation) =>
                  this.setState({
                    updatedLocation: location,
                    enLocation: enLocation
                  })
                }
                onChangeW3w={this.props.onChangeW3w}
                alertMesg={this.props.alertMesg}
              />
            </div>
            {this.getAddressFooterView()}
            {this.renderModal()}
          </div>
        </Modal>
      </div>
    );
  }

  getAddressFooterView() {
    const { updatedLocation } = this.state;
    return (
      <div className="addressContainer">
        <h6 className="markedAdressStyle">
          {this.props.mapForm.markedAddress}
        </h6>
        {updatedLocation && (
          <p className="mapModalDescription">{updatedLocation.addressLine1}</p>
        )}
        {this.props.what3words && (
          <p className="mapModalDescription">
            <strong>{this.props.mapForm.what3Words}:</strong>{" "}
            {this.props.what3words}
          </p>
        )}
        <Fab
          variant="extended"
          className="selectAddressButton"
          style={{
            backgroundColor: this.state.disableButton ? "lightgray" : "#0066a5"
          }}
          disabled={this.state.disableButton}
          onClick={this.onSelectAddress}
        >
          {this.props.mapButton}
        </Fab>
      </div>
    );
  }
}

export default withRouter(MapModal);
