import React from "react";
import Timing from "./Timing";
import "../styles/CustomerStyles.css";
import ContactInfo from "./Contact_info";
import DeliveryAddress from "./Delivery_address";
import SiteInfo from "./Site_info";
import ScrollIndicator from "../../../coreComponents/scrollIndicator/ScrollIndicator";
import BookingActionButton from "../../../coreComponents/button/Button";
import { getLanguage } from "../../../utils/LocalStorage";
import { showPopUp, isValidEmail } from "../../../utils//Validation";
import { TIME_LINE_STATUS_EN } from "../../../utils/Constants";
import { gMapData } from "../../../constants/json/ConfigurationJson";
import * as storage from "../../../utils/LocalStorage";

class Customer extends React.Component {
  constructor(props) {
    super(props);
    let customerInfo =
      Object.keys(props.customerAddress).length > 0
        ? props.customerAddress
        : props.orderData;
    this.state = {
      customerName: customerInfo.contact && customerInfo.contact.name,
      mobile: customerInfo.contact && customerInfo.contact.mobile,
      email: customerInfo.contact && customerInfo.contact.email,
      someoneName: customerInfo.contact && customerInfo.contact.someoneName,
      someoneMobile: customerInfo.contact && customerInfo.contact.someoneMobile,
      isSomeOneElseToReceive:
        customerInfo.contact && customerInfo.contact.isSomeOneElseToReceive,
      location: customerInfo.deliveryAddress && {
        addressLine1: customerInfo.deliveryAddress.addressLine1,
        latitude: customerInfo.deliveryAddress.latitude,
        longitude: customerInfo.deliveryAddress.longitude
      },
      city: customerInfo.deliveryAddress && customerInfo.deliveryAddress.city,
      district:
        customerInfo.deliveryAddress && customerInfo.deliveryAddress.district,
      addressEn: {
        addressLine1:
          customerInfo.deliveryAddress &&
          customerInfo.deliveryAddress.addressLine1,
        district:
          customerInfo.deliveryAddress && customerInfo.deliveryAddress.district,
        city: customerInfo.deliveryAddress && customerInfo.deliveryAddress.city
      },
      what3words:
        customerInfo.deliveryAddress && customerInfo.deliveryAddress.what3words,
      what3wordsEn:
        customerInfo.deliveryAddress && customerInfo.deliveryAddress.what3words,
      buildingNumber:
        customerInfo.siteInfo && customerInfo.siteInfo.buildingNumber,
      buildingType: customerInfo.siteInfo && customerInfo.siteInfo.buildingType,
      floor: customerInfo.siteInfo && customerInfo.siteInfo.floor,
      questionnaire: customerInfo.booking
        ? customerInfo.booking.questionnaireAnswers
        : [],
      otherComments:
        customerInfo.siteInfo && customerInfo.siteInfo.otherComments,
      language: getLanguage(),
      errors: {
        customerName: "",
        mobile: "",
        email: "",
        someoneName: "",
        someoneMobile: "",
        buildingNumber: "",
        otherComments: ""
      }
    };
    this.onChangeQuestionnaire = this.onChangeQuestionnaire.bind(this);
  }
  componentDidMount() {
    this.props.getLanguageForm();
    window.scrollTo(0, 0);
    this.props.getOrderData();
    this.getLocalData();

    // storage.setEditingStatus(true);
  }

  componentDidUpdate() {
    if (this.state.language !== getLanguage()) {
      this.setState({ language: getLanguage() });
      this.props.getLanguageForm();
    }
  }
  async getLocalData() {
    let customerInfo = await storage.getContactInfo();
    console.log(customerInfo);
    if (customerInfo) {
      try {
        this.setState({
          customerName: customerInfo.contact && customerInfo.contact.name,
          mobile: customerInfo.contact && customerInfo.contact.mobile,
          email: customerInfo.contact && customerInfo.contact.email,
          someoneName: customerInfo.contact && customerInfo.contact.someoneName,
          someoneMobile:
            customerInfo.contact && customerInfo.contact.someoneMobile,
          isSomeOneElseToReceive:
            customerInfo.contact && customerInfo.contact.isSomeOneElseToReceive,
          location: customerInfo.deliveryAddress && {
            addressLine1: customerInfo.deliveryAddress.addressLine1,
            latitude: customerInfo.deliveryAddress.latitude,
            longitude: customerInfo.deliveryAddress.longitude
          },
          city:
            customerInfo.deliveryAddress && customerInfo.deliveryAddress.city,
          district:
            customerInfo.deliveryAddress &&
            customerInfo.deliveryAddress.district,

          addressEn: {
            addressLine1:
              customerInfo.deliveryAddress &&
              customerInfo.deliveryAddress.addressLine1En,
            district:
              customerInfo.deliveryAddress &&
              customerInfo.deliveryAddress.districtEn,
            city:
              customerInfo.deliveryAddress &&
              customerInfo.deliveryAddress.cityEn
          },

          what3words:
            customerInfo.deliveryAddress &&
            customerInfo.deliveryAddress.what3words,
          what3wordsEn:
            customerInfo.deliveryAddress &&
            customerInfo.deliveryAddress.what3words,
          buildingNumber:
            customerInfo.siteInfo && customerInfo.siteInfo.buildingNumber,
          buildingType:
            customerInfo.siteInfo && customerInfo.siteInfo.buildingType,
          floor: customerInfo.siteInfo && customerInfo.siteInfo.floor,
          questionnaire: customerInfo.siteInfo.questionnaire,
          otherComments:
            customerInfo.siteInfo && customerInfo.siteInfo.otherComments
        });
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  submitData() {
    const {
      customerName,
      mobile,
      email,
      someoneName,
      someoneMobile,
      isSomeOneElseToReceive,
      location,
      city,
      district,
      buildingNumber,
      buildingType,
      floor,
      questionnaire,
      otherComments
    } = this.state;

    const { strings } = this.props;
    if (!customerName) {
      showPopUp(strings.customerForm.validation.PLEASE_ENTER_CUSTOMER_NAME);

      return false;
    } else if (!mobile) {
      showPopUp(strings.customerForm.validation.PLEASE_ENTER_MOBILE_NIMBER);
      return false;
    } else if (!email) {
      showPopUp(strings.customerForm.validation.PLEASE_ENTER_ENTER_ID);
      return false;
    } else if (isSomeOneElseToReceive) {
      if (!someoneName) {
        showPopUp(strings.customerForm.validation.PLEASE_ENTER_RECEIVER_NAME);

        return false;
      } else if (!someoneMobile) {
        showPopUp(strings.customerForm.validation.PLEASE_ENTER_MOBILE_NIMBER);
        return false;
      } else if (customerName.toLowerCase() === someoneName.toLowerCase()) {
        showPopUp(strings.customerForm.validation.RECEIVER_NAME_MUST_NOT_MATCH);
        return false;
      } else if (mobile === someoneMobile) {
        showPopUp(
          strings.customerForm.validation.RECEIVER_NUMBER_MUST_NOT_MATCH
        );
        return false;
      }
    }
    if (!location || !location.addressLine1) {
      showPopUp(strings.customerForm.validation.PLEASE_SELECT_THE_ADDRESS);
      return false;
    }
    if (!city) {
      showPopUp(strings.customerForm.validation.PLEASE_SELECT_THE_CITY);
      return false;
    } else if (!district) {
      showPopUp(strings.customerForm.validation.PLEASE_SELECT_THE_DISTRICT);
      return false;
    }
    //  else if (!buildingNumber) {
    //   showPopUp(
    //     strings.customerForm.validation.PLEASE_ENTER_THE_BUILDING_NEMBER
    //   );
    //   return false;
    // } else if (!buildingType) {
    //   showPopUp(strings.customerForm.validation.PLEASE_ENTER_THE_BUILDING_TYPE);
    //   return false;
    // } else if (!floor) {
    //   showPopUp(strings.customerForm.validation.PLEASE_SELECT_THE_FLOOR);
    //   return false;
    // }
    else if (
      questionnaire &&
      this.props.orderData.questionnaire &&
      questionnaire.length !== this.props.orderData.questionnaire.length
    ) {
      showPopUp(
        strings.customerForm.validation.PLEASE_SELECT_THE_QUESTIONNAIRE
      );
      return false;
    }

    if (this.validateForm(this.state.errors)) {
      this.updateValuesInReduxStore();
      this.props.history.push("/deliveryCalander");
    } else {
      showPopUp(
        strings.customerForm.validation.PLEASE_ENTER_THE_MANDATORY_FIELDS
      );
    }
  }

  updateValuesInReduxStore() {
    const {
      customerName,
      mobile,
      email,
      someoneName,
      someoneMobile,
      isSomeOneElseToReceive,
      location,
      city,
      district,
      addressEn,
      what3words,
      what3wordsEn,
      buildingNumber,
      buildingType,
      floor,
      questionnaire,
      otherComments
    } = this.state;
    const detailsObj = {
      contact: {
        name: customerName,
        mobile: mobile,
        email: email,
        isSomeOneElseToReceive: isSomeOneElseToReceive,
        someoneName: someoneName,
        someoneMobile: someoneMobile
      },
      deliveryAddress: {
        region:
          this.props.orderData.deliveryAddress &&
          this.props.orderData.deliveryAddress.region,
        latitude: location && location.latitude,
        longitude: location && location.longitude,
        city: city,
        addressLine1: location && location.addressLine1,
        district: district,
        cityEn: addressEn.city,
        addressLine1En: addressEn.addressLine1,
        districtEn: addressEn.district,
        country: location && location.country,
        postalCode: location && location.postalCode,
        what3words: what3words,
        what3wordsEn: what3wordsEn
      },
      siteInfo: {
        buildingNumber: buildingNumber,
        buildingType: buildingType,
        floor: floor,
        questionnaire: questionnaire,
        otherComments: otherComments
      }
    };
    this.props.addCustomerAddress(detailsObj);
  }

  validateForm = errors => {
    let valid = true;

    Object.values(errors).forEach(
      // if we have an error string set valid to false
      val => {
        val && val.length > 0 && (valid = false);
      }
    );
    return valid;
  };

  handleChange(value, fieldName) {
    const { strings } = this.props;
    let errors = this.state.errors;
    const mobileCheckData = this.mobileCheckData();

    switch (fieldName) {
      case "customerName":
        errors.customerName =
          value.length < 5 ? strings.customerForm.validation.NAME_MUST_BE : "";
        break;
      case "mobile":
        errors.mobile =
          value.length === mobileCheckData.mobileLength &&
          value.substring(0, 3) == mobileCheckData.mobileCountryCode
            ? ""
            : strings.customerForm.validation.MOBILE_NUMBER_MUST_BE;
        break;
      case "email":
        errors.email =
          !isValidEmail(value) && value.length
            ? strings.customerForm.validation.ENTER_VALID_ENTER_ID
            : "";
        break;
      case "someoneName":
        errors.someoneName =
          value.length < 5 ? strings.customerForm.validation.NAME_MUST_BE : "";
        break;
      case "someoneMobile":
        errors.someoneMobile =
          value.length < mobileCheckData.mobileLength
            ? strings.customerForm.validation.MOBILE_NUMBER_MUST_BE
            : "";
        break;
      // case "buildingNumber":
      //   errors.buildingNumber =
      //     value.length < 3
      //       ? strings.customerForm.validation.BUILDING_NUMBER_MUST_BE
      //       : "";
      //   break;
      // case "otherComments":
      //   errors.otherComments =
      //     value.length > 0 && value.length < 50
      //       ? strings.customerForm.validation.COMMENT_MUST_BE
      //       : "";
      //   break;

      default:
        break;
    }
    this.setState({ errors, [fieldName]: value });
  }

  mobileCheckData = () => {
    const mobileCheckData = gMapData.mobileData.find(mobileCheck => {
      const country =
        this.props.orderData &&
        this.props.orderData.deliveryAddress &&
        this.props.orderData.deliveryAddress.country;
      return mobileCheck.country === country;
    });

    return mobileCheckData;
  };

  render() {
    const {
      customerName,
      mobile,
      email,
      someoneName,
      someoneMobile,
      isSomeOneElseToReceive,
      location,
      city,
      district,
      what3words,
      buildingNumber,
      buildingType,
      floor,
      otherComments,
      questionnaire
    } = this.state;

    const { strings } = this.props;
    return (
      <div>
        <ScrollIndicator />
        {strings && strings.customerForm && (
          <div className="customerAppSection">
            <Timing
              strings={strings}
              value1={{
                title:
                  strings && strings.calenderForm.timeLine.delieveryDetails,
                status: TIME_LINE_STATUS_EN.IN_PROGRESS
              }}
              value2={{
                title: strings && strings.calenderForm.timeLine.delieveryTiming,
                status: TIME_LINE_STATUS_EN.INITIAL
              }}
              value3={{
                title: strings && strings.calenderForm.timeLine.confirmation,
                status: TIME_LINE_STATUS_EN.INITIAL
              }}
            />
            <ContactInfo
              contactInfoForm={strings.customerForm.contactInfo}
              placeholderForm={strings.customerForm.placeholder}
              mobileCheckData={this.mobileCheckData()}
              name={customerName}
              mobile={mobile}
              email={email}
              someoneName={someoneName}
              someoneMobile={someoneMobile}
              isSomeOneElseToReceive={isSomeOneElseToReceive}
              onChangeName={value => this.handleChange(value, "customerName")}
              onChangeMobile={value => this.handleChange(value, "mobile")}
              onChangeEmail={value => this.handleChange(value, "email")}
              onChangeSomeoneName={value =>
                this.handleChange(value, "someoneName")
              }
              onChangeSomeoneMobile={value =>
                this.handleChange(value, "someoneMobile")
              }
              onChangeReceiver={value =>
                this.setState({ isSomeOneElseToReceive: value })
              }
              errors={this.state.errors}
            />
            <DeliveryAddress
              deliveryAddressForm={strings.customerForm.deliveryAddress}
              mapForm={strings.customerForm.mapForm}
              placeholderForm={strings.customerForm.placeholder}
              alertButton={strings.calenderForm.alertButton}
              language={this.state.language}
              city={city}
              region={
                this.props.orderData.deliveryAddress &&
                this.props.orderData.deliveryAddress.region
              }
              location={location}
              district={district}
              what3words={what3words}
              onChangeCity={(city, cityEn) => this.onChangeCity(city, cityEn)}
              onChangeDistrict={(district, districtEn) =>
                this.onChangeDistrict(district, districtEn)
              }
              onChangeLocation={location =>
                this.setState({ location: location })
              }
              onChangeLocationEn={location =>
                this.setState({ addressEn: location })
              }
              onChangeW3w={(w3w, w3wEn) =>
                this.setState({ what3words: w3w, what3wordsEn: w3wEn })
              }
              onTap={() => this.updateValuesInReduxStore()}
            />
            {this.props.orderData &&
              this.props.orderData.questionnaire &&
              this.props.orderData.questionnaire.length > 0 && (
                <SiteInfo
                  siteForm={strings.customerForm.siteInformation}
                  placeholderForm={strings.customerForm.placeholder}
                  questionnaire={this.props.orderData.questionnaire}
                  questionnaireAnswers={questionnaire}
                  buildingNumber={buildingNumber}
                  buildingType={buildingType}
                  floor={floor}
                  otherComments={otherComments}
                  onChangeQuestionnaire={(qn, value) =>
                    this.onChangeQuestionnaire(qn, value)
                  }
                  onChangeBuildingNumber={value =>
                    this.handleChange(value, "buildingNumber")
                  }
                  onChangeBuildingType={value =>
                    this.setState({ buildingType: value })
                  }
                  onChangeFloor={floor => this.setState({ floor })}
                  onChangeComments={otherComments =>
                    this.handleChange(otherComments, "otherComments")
                  }
                  errors={this.state.errors}
                />
              )}

            <BookingActionButton
              homeButton=""
              onClick={() => this.submitData()}
            >
              {strings.customerForm.button}
            </BookingActionButton>
          </div>
        )}
      </div>
    );
  }

  onChangeCity = (city, cityEn) => {
    this.setState(prevState => ({
      addressEn: {
        ...prevState.addressEn,
        city: cityEn
      }
    }));
    this.setState({ city });
  };

  onChangeDistrict = (district, districtEn) => {
    this.setState(prevState => ({
      addressEn: {
        ...prevState.addressEn,
        district: districtEn
      }
    }));
    this.setState({ district });
  };

  onChangeQuestionnaire(qn, val) {
    let indexOfMatchedQuestionnaire =
      this.state.questionnaire &&
      this.state.questionnaire.findIndex(qns => qns.id === qn.id);

    if (indexOfMatchedQuestionnaire >= 0) {
      let items = this.state.questionnaire;
      items[indexOfMatchedQuestionnaire] = { id: qn.id, answer: val };
      this.setState({ questionnaire: items });
    } else {
      this.setState({
        questionnaire: [...this.state.questionnaire, { id: qn.id, answer: val }]
      });
    }
  }

  onChnagelocation(loc) {
    this.setState({ location: loc });
  }
}

export default Customer;
