import React from "react";

export default class OrderList extends React.Component {
  render() {
    const { listForm } = this.props;
    return (
      <div>
        <h1 className="nav order-items-header _flex">
          <span className="order-detail-title">
            {listForm.totalItems}: {this.props.products.length}
          </span>
          <span className="order-detail-time">{listForm.quantity}</span>
        </h1>
        {this.props.products && (
          <div className="order-list-container">
            {this.props.products.map((item, i) => this.orderItem(item, i))}
          </div>
        )}
      </div>
    );
  }

  orderItem = (item, i) => {
    return (
      <div className="nav-card" key={i}>
        <div className="order-store-card _flex nav-card">
          <div className="order-store-card-inner-panel">
            <div className="card_icon">
              <img
                width="40"
                height="40"
                src={`https://media.extra.com/i/aurora/${item.sku}_100_01?w=108&h=108&qlt=100&img404=missing_product&v=false`}
                alt="product"
              />
            </div>
            <div className="card_content">
              <div className="order_panel_value">{item.description}</div>
            </div>
          </div>

          <div className="order-store-card-inner-panel">
            <div className="card_content">
              <div className="order_panel_value">{item.quantity}x</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

OrderList.defaultProps = {
  products: []
};
