import React, { Component } from "react";

export default class ErrorPage extends Component {
  componentDidMount() {
    this.props.getLanguageForm();
  }
  render() {
    const { strings } = this.props;
    return (
      <div className="footer">
        <div className="ft-bg-color">
          <div>
            <a
              href={strings && strings.footerForm.termsAndConditionLink}
              target="_blank"
            >
              {strings && strings.footerForm.termsAndCondition}
            </a>
          </div>
          <div style={{ color: "white" }}>
            {strings && strings.footerForm.copyright}
          </div>
        </div>
      </div>
    );
  }
}
