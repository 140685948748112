export const REQUESTING = "requesting";
export const SUCCESS = "success";
export const ERROR = "error";
export const DATE_RANGE = 7;
export const DATE_NEXT_RANGE = 6;
export const PLEASE_SELECT_TIME_SLOT = "Please select time slot";
export const AVAILABLE = "Available";
export const NOT_AVAILABLE = "Not Available";
export const VALIDATION_MESG = {
  PLEASE_ENTER_CUSTOMER_NAME: "Please enter the customer name",
  PLEASE_ENTER_MOBILE_NIMBER: "Please enter the mobile number",
  PLEASE_ENTER_RECEIVER_NAME: "Please enter the receiver name",
  PLEASE_SELECT_THE_ADDRESS: "Please select the Address",
  PLEASE_SELECT_THE_CITY: "Please select the city",
  PLEASE_SELECT_THE_DISTRICT: "Please select the district",
  PLEASE_ENTER_THE_BUILDING_NEMBER: "Please enter the building number",
  PLEASE_ENTER_THE_BUILDING_TYPE: "Please select the building type",
  PLEASE_SELECT_THE_FLOOR: "Please select the floor",
  PLEASE_SELECT_THE_QUESTIONNAIRE: "Please Answer All the Questions",
  PLEASE_ENTER_THE_MANDATORY_FIELDS: "Please enter the mandatory fields",
  PLEASE_ENTER_ENTER_ID: "Please enter email id",

  NAME_MUST_BE: "Name must be 5 characters long!",
  MOBILE_NUMBER_MUST_BE: "Mobile number must be 12 digits!",
  BUILDING_NUMBER_MUST_BE: "Building number must be 3 digits!",
  COMMENT_MUST_BE: "Comment must be 50 characters long!",
  RECEIVER_NAME_MUST_NOT_MATCH:
    "Please enter receiver name. It should not match customer name",
  RECEIVER_NUMBER_MUST_NOT_MATCH:
    "Please enter receiver number. It should not match customer number",
  ENTER_VALID_ENTER_ID: "Enter valid email id"
};

export const TIME_LINE_STATUS_EN = {
  INITIAL: "Not Started",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  FAILED: "Failed",
  PICKED: "Picked"
};

export const TIME_LINE_STATUS_AR = {
  INITIAL: "Not Started",
  IN_PROGRESS: "قيد التنفيذ",
  COMPLETED: "اكتمل",
  FAILED: "Failed",
  PICKED: "Picked"
};

export const YOU_CAN_UPDATE_BOOKING_MESG =
  "You can update your booking details for free as long as the shipment status is still under process.Please contact us if you  need help or for any further details";
export const UNABLE_TO_DELIVER_MESG =
  "Unable to deliver due to inaccurate address/wrong contact info. Please note: customer will be charged 100 SR for new delivery booking. Please contact us.";
export const KINDLY_CHECK_THE_URL = "Kindly check the URL";
export const GET_ORDER_DATA_SUCCESS = "GET_ORDER_DATA_SUCCESS";
export const GET_ORDER_DATA_FAILURE = "GET_ORDER_DATA_FAILURE";
export const GET_ORDER_DATA_REQUESTING = "GET_ORDER_DATA_REQUESTING";
