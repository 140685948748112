import React, { Component } from "react";
import { Route, withRouter, Switch } from "react-router";
import FooterContainer from "./modules/home/containers/FooterContainer";
import Header from "./coreComponents/header/Header";
import HomeContainer from "./modules/home/containers/HomeContainer";
import DeliveryInfoContainer from "./modules/customer/containers/DeliveryInfoContainer";
import ConfirmationDetailsContainer from "./modules/confirmation/containers/ConfirmationDetailsContainer";
import ThankYouPage from "./modules/confirmation/containers/FinalPageContainer";
import ExistingCustomer from "./modules/return/containers/UpdateBookingContainer";
import NewBooking from "./modules/return/components/NewBooking";
import TrackShipment from "./modules/return/components/TrackShipment";
import PathNames from "./utils/route";
import DeliveryCalanderContainer from "./modules/customer/containers/DeliveryCalanderContainer";
import ErrorContainer from "./modules/confirmation/containers/ErrorContainer";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      urlParams: ""
    };
  }

  render() {
    let footerVisible = true;
    if (
      this.props.location.pathname === PathNames.DELIVERY_INFO ||
      this.props.location.pathname === PathNames.CONFIRMATION ||
      this.props.location.pathname === PathNames.DELIVERY_CALANDER ||
      this.props.location.pathname === "/error"
    ) {
      footerVisible = false;
    }
    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <Header />
          <Switch>
            {this.props.orderData.booking ? (
              <Route exact path="/" component={ExistingCustomer} />
            ) : (
              <Route exact path="/" component={HomeContainer} />
            )}
            <Route
              path={PathNames.DELIVERY_CALANDER}
              component={DeliveryCalanderContainer}
            />
            <Route
              path={PathNames.DELIVERY_INFO}
              component={DeliveryInfoContainer}
            />
            <Route
              path={PathNames.CONFIRMATION}
              component={ConfirmationDetailsContainer}
            />
            <Route path={PathNames.CONFIRM_BOOKING} component={ThankYouPage} />
            <Route
              path={PathNames.EXISTING_CUSTOMER}
              component={ExistingCustomer}
            />
            <Route path={PathNames.NEW_BOOKING} component={NewBooking} />
            <Route path={PathNames.TRACK_SHIPMENT} component={TrackShipment} />
            <Route exact path={PathNames.ERROR} component={ErrorContainer} />
          </Switch>
          {footerVisible ? <FooterContainer /> : null}
        </MuiThemeProvider>
      </div>
    );
  }
}

export default withRouter(App);

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#3f50b5"
    },
    secondary: {
      main: "#f44336"
    }
  }
});
