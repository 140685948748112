export const addresses = 
[
  {
  region: "J11",
  initialGeocode: { lat:16.89279, lang:42.514117} ,
   polygons: [
  [
   { lat:16.89279, lng:42.514117},
   { lat:16.755423, lng:42.663805},
   { lat:16.392801, lng:42.701913},
   { lat:16.498828, lng:42.932283},
   { lat:16.729122, lng:43.14995},
   { lat:16.965705, lng:42.936404},
   { lat:17.039904, lng:42.875292},
   { lat:17.034652, lng:42.760622},
   { lat:17.00248, lng:42.538149}]],
   cities: [
   {
   name: "Jazan",
   arName: "جازان",
   googleName: "",
   districts: [
   {
   name: "Zuhur",
   arName: "زهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jabal",
   arName: "جبل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shati",
   arName: "شاطئ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial area",
   arName: "منطقة صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Airport",
   arName: "مطار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Arak",
   arName: "اراك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bashair",
   arName: "بشائر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qalah",
   arName: "قلعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mizhirah",
   arName: "مزهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suways",
   arName: "سويس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ahad",
   arName: "أحد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shamiyah",
   arName: "شامية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Damad",
   arName: "ضمد",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Sabya",
   arName: "صبيا",
   googleName: "",
   districts: [
   {
   name: "Muutarid",
   arName: "معترض",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hullat al ahwass",
   arName: "حلة الأحوس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fardus",
   arName: "فردوس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muruj",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahah",
   arName: "واحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hushaibriyah",
   arName: "حشيبرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawnah",
   arName: "رونة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dhabyah",
   arName: "ظبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hussaini",
   arName: "حسيني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Saadah",
   arName: "سعادة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jukhairah",
   arName: "جخيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gharraa",
   arName: "غراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Falah",
   arName: "فالح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Butishah",
   arName: "بوتيشة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bahir",
   arName: "باحر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Riha",
   arName: "رحاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Arish",
   arName: "عريش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Jizan",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Al ahad",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abou Arish",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rayan",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Damad",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sabia",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zabia",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mozherih",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jizan",
   arName: "",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Abu Arish",
   arName: "ابو عريش",
   googleName: "",
   districts: [
   {
   name: "Khaldiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zuhur",
   arName: "زهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kamirah",
   arName: "قامرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quds",
   arName: "قدس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhdaj",
   arName: "مهدج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sadiliyah",
   arName: "سادلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quwaiyyah",
   arName: "قويعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hakimat abi arish",
   arName: "حاكمة ابي عريش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shakah",
   arName: "شاقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uqdah",
   arName: "عقدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muraikhiyah",
   arName: "مريخية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wurud",
   arName: "ورود",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Ahad Al Masarihah",
   arName: "احد المسارحة",
   googleName: "",
   districts: [
   {
   name: "Wasit",
   arName: "واسط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baladiah",
   arName: "بلدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hasamah",
   arName: "حصامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amariyah",
   arName: "عمارية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mizab",
   arName: "ميزاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dagharir",
   arName: "دغارير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iz",
   arName: "عز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalab",
   arName: "خلب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hakimiat al iz",
   arName: "حكامية العز",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "Abha & Khamis",
  initialGeocode: { lat:18.3454013, lang:42.77732849} ,
   polygons: [
  [
   { lat:18.3454013, lng:42.77732849},
   { lat:18.3454013, lng:42.74917603},
   { lat:18.33692837, lng:42.72102356},
   { lat:18.33171406, lng:42.68257141},
   { lat:18.31737388, lng:42.65785217},
   { lat:18.30564012, lng:42.63999939},
   { lat:18.29455751, lng:42.61734009},
   { lat:18.28673406, lng:42.59605408},
   { lat:18.28021425, lng:42.56721497},
   { lat:18.26586981, lng:42.5315094},
   { lat:18.25543675, lng:42.50679016},
   { lat:18.2436988, lng:42.48687744},
   { lat:18.23261223, lng:42.48138428},
   { lat:18.21108923, lng:42.48001099},
   { lat:18.20130516, lng:42.48962402},
   { lat:18.18434482, lng:42.49717712},
   { lat:18.18369247, lng:42.52670288},
   { lat:18.20065287, lng:42.55485535},
   { lat:18.21761163, lng:42.61734009},
   { lat:18.2195683, lng:42.65235901},
   { lat:18.23196006, lng:42.68669128},
   { lat:18.24761153, lng:42.73269653},
   { lat:18.26978205, lng:42.75810242},
   { lat:18.28412617, lng:42.78694153},
   { lat:18.28282221, lng:42.82608032},
   { lat:18.30107678, lng:42.83981323},
   { lat:18.32649959, lng:42.83363342},
   { lat:18.3440978, lng:42.80136108}]],
   cities: [
   {
   name: "Ahad Rafidah",
   arName: "احد رفيده",
   googleName: "",
   districts: [
   {
   name: "Military city",
   arName: "مدينة العسكرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Runah",
   arName: "رونه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safwan",
   arName: "صفوان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salamah",
   arName: "سلامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Down Town",
   arName: "وسط المدينة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalij",
   arName: "خليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salhiyah",
   arName: "صالحية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wurud",
   arName: "ورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zaytun",
   arName: "زيتون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masif",
   arName: "مصيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zuhur",
   arName: "زهور",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Abha",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Shamaasan",
   arName: "شمسان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Green Mountain",
   arName: "جبل الاخضر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shifa",
   arName: "شفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kahlidiayh",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharafiyah",
   arName: "شرفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muftaha",
   arName: "مفتاحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abha al jadidah",
   arName: "ابها الجديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wardatain",
   arName: "وردتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qura",
   arName: "قرى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dhurah",
   arName: "ذرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sarawat",
   arName: "سروات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muruj",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qabil",
   arName: "قابل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manhal",
   arName: "منهل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ad Dabab",
   arName: "ضباب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mansak",
   arName: "منسك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Arin",
   arName: "عرين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Irq Al janubi",
   arName: "عرق الجنوبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Down Town",
   arName: "وسط المدينة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sadd",
   arName: "سد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasb",
   arName: "نصب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khasha",
   arName: "خشع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wasaif",
   arName: "وصايف",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Khamis Mushait",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "60 Streeet",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Balid",
   arName: "بليد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al deaifai",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al hemaila",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al khaldei",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Raky",
   arName: "راقي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al snayeya al kdemai",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alazizia",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gawazat",
   arName: "جوازات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gharawy",
   arName: "غراوي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AllHmela",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "almozafeain",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "alrona",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "alshrifei",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tahlia",
   arName: "تحلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "altrasyai",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Waidean",
   arName: "ويدان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bahes",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kambar",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khamis Musheit",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "om srair",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "tandaha",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "zlala",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Iraq Al shamali",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jeddah",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shukr",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Suqur",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Otod",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almthnah",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ibn Ar Rida",
   arName: "ابن الرضا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "30 Street",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Naseem",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "almdena alaskaryi",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ard Almarad",
   arName: "ارض المراد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "wadei ban hashbal",
   arName: "وديع بان حشبال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bagdad",
   arName: "بغداد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Qafilah",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ahd refaidi",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hosam",
   arName: "حسام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al snayeya algdedi",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "almosa",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alrsras",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "alwahai",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ben Gar Allah",
   arName: "بن جار الله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "teab al asma",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "shbaha",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mossa",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "14th brigade housing",
   arName: "سكن مجموعة اللواء الرابع عشر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sad",
   arName: "سد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jazirah",
   arName: "جزيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dhalalah",
   arName: "ذلالة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Damak",
   arName: "ضمك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zuhur",
   arName: "زهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm surur",
   arName: "ام سرور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharafiyah",
   arName: "شرفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qafilah",
   arName: "قافلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mamurah",
   arName: "معمورة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khazan",
   arName: "خزان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Irq ash shamali",
   arName: "عرق الشمالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Harir",
   arName: "هر ير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Military city housing",
   arName: "سكن المدينة العسكرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Irq al janubi",
   arName: "عرق الجنوبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dawhah",
   arName: "دوحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tayib al ism",
   arName: "طيب الاسم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial area",
   arName: "منطقة صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahah",
   arName: "واحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shubaah",
   arName: "شباعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Runah",
   arName: "رونه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mizab",
   arName: "معزاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fath",
   arName: "فتح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qambar",
   arName: "قمبر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Atud",
   arName: "عتود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suqur",
   arName: "صقور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shakar",
   arName: "شكر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rasras",
   arName: "رصراص",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bawadi",
   arName: "بوادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Musa subdivision",
   arName: "مخطط الموسى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mathnah",
   arName: "مثناة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Humaylah",
   arName: "ال هميلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Airforce housing",
   arName: "سكن القاعدة الجوية",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "Mecca",
  initialGeocode: { lat:21.660264, lang:39.715598} ,
   polygons: [
  [
   { lat:21.660264, lng:39.715598},
   { lat:21.66154, lng:39.677146},
   { lat:21.659307, lng:39.660323},
   { lat:21.635054, lng:39.64968},
   { lat:21.603776, lng:39.670966},
   { lat:21.577598, lng:39.633887},
   { lat:21.493807, lng:39.517157},
   { lat:21.459821, lng:39.423773},
   { lat:21.370368, lng:39.409354},
   { lat:21.287898, lng:39.64968},
   { lat:21.27886, lng:39.822714},
   { lat:21.269823, lng:40.003989},
   { lat:21.479631, lng:40.051625},
   { lat:21.516765, lng:40.045015},
   { lat:21.547664, lng:39.918244},
   { lat:21.626757, lng:39.822371},
   { lat:21.631544, lng:39.805549},
   { lat:21.631544, lng:39.805549}]],
   cities: [
   {
   name: "Al-Jumum",
   arName: "الجموم",
   googleName: "",
   districts: [
   {
   name: "Salamah",
   arName: "سلامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shuhada'",
   arName: "شهداء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naqabah",
   arName: "نقابة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu shuayb",
   arName: "أبو شعيب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ayn shams",
   arName: "عين شمس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu urwah",
   arName: "أبو عروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quraiyat",
   arName: "قريات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa'a",
   arName: "صفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sedr",
   arName: "سدر",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Mecca",
   arName: "مكة",
   googleName: "",
   districts: [
   {
   name: "Hejrah",
   arName: "هجره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kahledyah",
   arName: "خالديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sabhanyah",
   arName: "سبهاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shaib amir and shaib ali",
   arName: "شعب عامر وشعب علي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shuokyah",
   arName: "شوقيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "UmmElljood",
   arName: "ام الجود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahir",
   arName: "زاهر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Adl",
   arName: "عدل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aoualy",
   arName: "عوالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamraa",
   arName: "حمرا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuoryah",
   arName: "نوريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ouon Al Shams",
   arName: "عيون الشمس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uommrah",
   arName: "عمره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zayeedy",
   arName: "زايدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu Maraq",
   arName: "ابو مرق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ajyad",
   arName: "اجياد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hafayed",
   arName: "حفايد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Juaranah",
   arName: "جعرانه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jummayzah",
   arName: "جميزة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khansa",
   arName: "خنساء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Maabdah",
   arName: "معابده",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muaesem",
   arName: "معيصم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Raowdah",
   arName: "روضه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ray zakhir",
   arName: "ريع زاخر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shuhadaa",
   arName: "شهداء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taysir",
   arName: "تيسير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ukayshiyah",
   arName: "عكيشية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Waly al ahd",
   arName: "ولي العهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahrat Kuday",
   arName: "زهرة كدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Eskhan",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra umm al jud",
   arName: "حمراء ام الجود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Harat al bab and ash shamiyah",
   arName: "حارة الباب والشامية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jarool",
   arName: "جروول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Messfalah",
   arName: "مسفله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadyah",
   arName: "محمديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mursalat",
   arName: "مرسلات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahat Al Esslam",
   arName: "ساحة الاسلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharyaa",
   arName: "شارئع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharyaa Al Khadraa",
   arName: "شارئع الخضرا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulimaniyah",
   arName: "سليمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umrah al jadidah",
   arName: "عمرة الجديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahra",
   arName: "زهراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bepaan",
   arName: "بيبان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hendawiah",
   arName: "هنداويه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jabal Al Nuor",
   arName: "جبل النور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "KaaKyah",
   arName: "كعكيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mansur",
   arName: "منصور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nekhasah",
   arName: "نخاسه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Outaybiah",
   arName: "عتيبيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Randawiah",
   arName: "رضوانه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rashedyah",
   arName: "راشديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Seteen Streat",
   arName: "شارع الستين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shafee",
   arName: "شفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharai al mujahidin",
   arName: "شرائع المجاهدين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sheshah",
   arName: "شيشه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahrat Al Uommrah",
   arName: "زهرة العمره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Asharai",
   arName: "شرائع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bathaa Quraysh",
   arName: "بطحاء قريش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Buhayrat",
   arName: "بحيرات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Deaifah",
   arName: "ضيافه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ErrSayffah",
   arName: "رصيفه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hosaynyah",
   arName: "حسينيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khadra",
   arName: "خضراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mashair",
   arName: "مشاعر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nozlah",
   arName: "نزله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shumaysey",
   arName: "شميسي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tandabawi",
   arName: "طندباوي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Usaylah",
   arName: "عسيلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Azayzeah",
   arName: "عزيزيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hujun",
   arName: "حجون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiah",
   arName: "جامعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mujahdeen",
   arName: "مجاهدين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naseem",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tanim",
   arName: "تنعيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wade Julal",
   arName: "وادي جليل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Asseel",
   arName: "اصيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bahrah",
   arName: "بحرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hajj Straet",
   arName: "شارع الحج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Haram",
   arName: "حرم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kudy",
   arName: "كدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salamah",
   arName: "سلامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sotrat Al Hayany",
   arName: "سترة اللحياني",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Mekkah",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Al Shuhadaa",
   arName: "J14",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Uommrah",
   arName: "العمره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu Maraq",
   arName: "ابو مرق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuoryah",
   arName: "النوريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ouon Al Shams",
   arName: "عيون الشمس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Buhayrat",
   arName: "البحيرات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Outaybiah",
   arName: "العتيبيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jarool",
   arName: "جروول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bepaan",
   arName: "بيبان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahat Al Esslam",
   arName: "ساحة الاسلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Deaifah",
   arName: "الضيافه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hendawiah",
   arName: "الهنداويه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nozlah",
   arName: "النزله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hafayed",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mansuor Streat",
   arName: "شارع المنصور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ErrSayffah",
   arName: "الرصيفه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Seteen Streat",
   arName: "شارع الستين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Eskhan",
   arName: "الاسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hamraa",
   arName: "الحمرا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "UmmElljood",
   arName: "ام الجود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Zayeedy",
   arName: "الزايدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shumaysey",
   arName: "الشميسي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bahrah",
   arName: "بحره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Muhammadyah",
   arName: "المحمديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shafee",
   arName: "الشفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shuokyah",
   arName: "الشوقيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nekhasah",
   arName: "النخاسه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mujahdeen",
   arName: "المجاهدين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Muaesem",
   arName: "المعيصم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Asseel",
   arName: "الاصيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jabal Al Nuor",
   arName: "جبل النور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khansaa",
   arName: "الخنسى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wade Julal",
   arName: "وادي جاليل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hajj Straet",
   arName: "شارع الحج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sharyaa",
   arName: "الشارئع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharyaa Al Khadraa",
   arName: "الشارئع الخضرا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sheshah",
   arName: "الشيشه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Raowdah",
   arName: "الروضه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Maabdah",
   arName: "المعابده",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Aoualy",
   arName: "العوالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hejrah",
   arName: "الهجره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Azayzeah",
   arName: "العزيزيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sotrat Al Hayany",
   arName: "سترة اللحياني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahrat Kuday",
   arName: "زهرة كدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jamoom",
   arName: "الجموم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Randawiah",
   arName: "الرضوانه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sabhanyah",
   arName: "السبهاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rashedyah",
   arName: "الراشديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hosaynyah",
   arName: "الحسينيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bathaa Quraysh",
   arName: "بطحاء قريش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahrat Al Uommrah",
   arName: "زهرة العمره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khashlah",
   arName: "الغساله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Messfalah",
   arName: "المسفله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Kahledyah",
   arName: "الخالديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al KaaKyah",
   arName: "الكعكيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Juaranah",
   arName: "جعرانه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Maksalah",
   arName: "المغسله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Adll",
   arName: "العدل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Naseem",
   arName: "النسيم",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "Western",
  initialGeocode: { lat:21.378122, lang:39.176397} ,
   polygons: [
  [
   { lat:21.378122, lng:39.176397},
   { lat:21.515876, lng:39.150045},
   { lat:21.525631, lng:39.121763},
   { lat:21.544966, lng:39.113394},
   { lat:21.591957, lng:39.105837},
   { lat:21.704311, lng:39.084463},
   { lat:21.750251, lng:39.048799},
   { lat:21.808289, lng:39.013135},
   { lat:21.909067, lng:38.934791},
   { lat:22.021644, lng:38.926363},
   { lat:22.200264, lng:39.031918},
   { lat:22.336608, lng:39.100206},
   { lat:22.326268, lng:39.167652},
   { lat:22.317887, lng:39.163141},
   { lat:22.309506, lng:39.158288},
   { lat:22.297861, lng:39.152633},
   { lat:22.286216, lng:39.146634},
   { lat:22.255665, lng:39.129735},
   { lat:22.23194, lng:39.124509},
   { lat:22.212689, lng:39.124529},
   { lat:22.172613, lng:39.126951},
   { lat:22.078774, lng:39.137921},
   { lat:21.981689, lng:39.21275},
   { lat:21.970527, lng:39.285002},
   { lat:21.959215, lng:39.361641},
   { lat:21.95929, lng:39.391806},
   { lat:21.938986, lng:39.408238},
   { lat:21.86696, lng:39.420737},
   { lat:21.858663, lng:39.375374},
   { lat:21.902624, lng:39.309262},
   { lat:21.886056, lng:39.272429},
   { lat:21.866301, lng:39.276844},
   { lat:21.857697, lng:39.270125},
   { lat:21.841206, lng:39.279812},
   { lat:21.820809, lng:39.263455},
   { lat:21.780246, lng:39.246927},
   { lat:21.689628, lng:39.245374},
   { lat:21.514128, lng:39.306213},
   { lat:21.473352, lng:39.313801},
   { lat:21.461962, lng:39.41666},
   { lat:21.402912, lng:39.410538},
   { lat:21.388201, lng:39.379095},
   { lat:21.353417, lng:39.318526},
   { lat:21.256471, lng:39.310169},
   { lat:21.26481, lng:39.270043},
   { lat:21.26995, lng:39.231978},
   { lat:21.26775, lng:39.153442},
   { lat:21.309293, lng:39.132212},
   { lat:21.319527, lng:39.123629},
   { lat:21.322604, lng:39.121346},
   { lat:21.327571, lng:39.122277},
   { lat:21.335416, lng:39.126641},
   { lat:21.338304, lng:39.132036},
   { lat:21.355736, lng:39.160568}]],
   cities: [
   {
   name: "DUMMY",
   arName: "",
   googleName: "",
   districts: [
  ]
   },
   {
   name: "Jeddah",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Al Baghdadiyah",
   arName: "البغدادية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mina",
   arName: "الميناء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Murjan",
   arName: "المرجان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abhur al janubiyah",
   arName: "ابحر الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abhur ash shamaliyah",
   arName: "ابحرالشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Salamah",
   arName: "السلامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Aziziyah",
   arName: "العزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Salaymaniah",
   arName: "السليمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Naeem",
   arName: "النعيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Tawfeeq",
   arName: "التوفيق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Buraiman",
   arName: "بريمان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Safa",
   arName: "الصفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bani Malik",
   arName: "بنى مالك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mushrifah",
   arName: "مشرفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nahda",
   arName: "النهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Ajwad",
   arName: "الاجواد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hamdaniah",
   arName: "الحمدانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Marwah",
   arName: "المروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rawdah",
   arName: "الروضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hindawiah",
   arName: "الهنداوية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Faisaliyah",
   arName: "الفيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nakheel",
   arName: "النخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Warood",
   arName: "الورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mahmmediah",
   arName: "المحمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rabwah",
   arName: "الربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Manar",
   arName: "المنار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Zahraa",
   arName: "الزهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hamrah",
   arName: "الحمرا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mahjar",
   arName: "المحجر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sabeel",
   arName: "السبيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shaty",
   arName: "الشاطى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khalidiyah",
   arName: "الخالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rihab",
   arName: "الرحاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Andalus",
   arName: "الاندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nuzlah",
   arName: "النزلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Samer",
   arName: "السامر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alkhomra",
   arName: "الخمرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alrawaby",
   arName: "الروابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Farooq",
   arName: "الفاروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Falah",
   arName: "فالح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almotnzehat",
   arName: "المتنزهات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almhameed",
   arName: "المحاميد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alsabeel",
   arName: "السبيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jamiah",
   arName: "الجامعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abruq Ar Rughamah",
   arName: "أبرق الرغامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mada’en AlFahaf",
   arName: "مدائن الفهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alwaha",
   arName: "الواحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qoizh",
   arName: "قويزة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Amir Fawaz",
   arName: "الأمير فواز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alyaqout",
   arName: "الياقوت",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ajaweed",
   arName: "الأجاويد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jawharah",
   arName: "جوهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alamir Abdulmajeed",
   arName: "الأمير عبد المجيد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Adel",
   arName: "العدل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Muntazahat",
   arName: "المتنزهات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al-Manar",
   arName: "المحجر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayaan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Altayseer",
   arName: "التيسير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alharazat",
   arName: "الحرازات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi Mariekh",
   arName: "وادي مريخ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alreyadh",
   arName: "الرياض",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Albohirat",
   arName: "البجيرات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bin Laden",
   arName: "بن لادن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alwafaa",
   arName: "الوفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alrehaly",
   arName: "الرحيلي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "um Alsalam",
   arName: "أم السلم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghelil",
   arName: "غليل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Teyba",
   arName: "طيبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Althaghr",
   arName: "الثغر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alasalah",
   arName: "الأصالة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alamarya",
   arName: "العمارية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rowis",
   arName: "الرويس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Bawadi",
   arName: "البوادى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Balad",
   arName: "البلد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nuzhah",
   arName: "النزهه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sharfiyah",
   arName: "الشرفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Basateen",
   arName: "البسانين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naim",
   arName: "نعيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Um asalam",
   arName: "ام السلم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khomrah",
   arName: "خمرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rahmanyah",
   arName: "رحمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahil",
   arName: "ساحل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shati",
   arName: "شاطئ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Loaloa",
   arName: "لؤلؤ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahjar",
   arName: "محجر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Naseem",
   arName: "النسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Fayha",
   arName: "الفيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Wazireya",
   arName: "الوزيرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Kandarah",
   arName: "الكندرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdul aziz international airport",
   arName: "مطار الملك عبدالعزيز الدولي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Moulysaa",
   arName: "مليساء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Asalah",
   arName: "أصالة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bahrah",
   arName: "بحرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thaghr",
   arName: "ثغر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdul aziz university",
   arName: "جامعة الملك عبدالعزيز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rahmah",
   arName: "رحمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sheraa",
   arName: "شراع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shrouk",
   arName: "شروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Adel",
   arName: "عدل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghulail",
   arName: "غليل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mutanazahat",
   arName: "متنزهات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mraykh",
   arName: "مريخ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amir abdoulmajed",
   arName: "امير عبدالمجيد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Petromin",
   arName: "بترومين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thaalibah",
   arName: "ثعالبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rehab",
   arName: "رحاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawabi",
   arName: "روابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zomorod",
   arName: "زمرد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Samir",
   arName: "سامر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sarawat",
   arName: "سروات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ferdous",
   arName: "فردوس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mishrifah",
   arName: "مشرفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hindawiyah",
   arName: "هنداوية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Waziriyah",
   arName: "وزيريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Basatin",
   arName: "بساتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baghdadiyah al gharbiyah",
   arName: "بغدادية الغربية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiah",
   arName: "جامعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamadaniyah",
   arName: "حمدانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahra",
   arName: "زهراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salamah",
   arName: "سلامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulaymaniyah",
   arName: "سليمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharafiyah",
   arName: "شرفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahifah",
   arName: "صحيفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dahiah",
   arName: "ضاحية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Frosyah",
   arName: "فروسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Karamah",
   arName: "كرامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masarah",
   arName: "مسرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manar",
   arName: "منار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Montazah",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nazlah ash sharqiyah",
   arName: "نزلة الشرقية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ajwad",
   arName: "اجواد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Um hableen",
   arName: "ام حبلين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Balad",
   arName: "بلد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tadamon",
   arName: "تضامن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ruwais",
   arName: "رويس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Swaryee",
   arName: "صوارى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fadel",
   arName: "فضل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madain al fahd",
   arName: "مدائن الفهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahah",
   arName: "واحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yaqoot",
   arName: "ياقوت",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baghdadiyah ash sharqiyah",
   arName: "بغدادية الشرقية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sanabel",
   arName: "سنابل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taibah",
   arName: "طيبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Farouk",
   arName: "فاروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kawthar",
   arName: "كوثر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Murjan",
   arName: "مرجان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi",
   arName: "وادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial area",
   arName: "منطقة صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nazlah al yamaniyah",
   arName: "نزلة اليمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wurud",
   arName: "ورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amwaj",
   arName: "امواج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amir fawaz al janouby",
   arName: "امير فواز الجنوبى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amir fawaz ash shamaly",
   arName: "امير فواز الشمالى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bryman",
   arName: "بريمان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bashaer",
   arName: "بشائر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bawadi",
   arName: "بوادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shefaa",
   arName: "شفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fadeylah",
   arName: "فضيلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King faisal naval base",
   arName: "قاعدة الملك فيصل البحرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quraiyat",
   arName: "قريات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qouzeen",
   arName: "قوزين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marwah",
   arName: "مروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jeddah Islamic seaport",
   arName: "ميناء جدة الاسلامى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hada",
   arName: "هدا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Barakah",
   arName: "بركة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taawon",
   arName: "تعاون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra",
   arName: "حمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sabil",
   arName: "سبيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sororyah",
   arName: "سرورية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qryniah",
   arName: "قرينية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kandarah",
   arName: "كندرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah",
   arName: "محمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdul aziz medical city",
   arName: "مدينة الملك عبدالعزيز الطبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abruq ar rughamah",
   arName: "ابرق الرغامة",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "Eastern",
  initialGeocode: { lat:26.278013, lang:50.164961} ,
   polygons: [
  [
   { lat:26.278013, lng:50.164961},
   { lat:26.033793, lng:50.140757},
   { lat:26.128074, lng:50.16951},
   { lat:26.141559, lng:50.187964},
   { lat:26.162823, lng:50.199294},
   { lat:26.185008, lng:50.22058},
   { lat:26.250305, lng:50.23088},
   { lat:26.31495, lng:50.237059},
   { lat:26.391245, lng:50.235686},
   { lat:26.442285, lng:50.201353},
   { lat:26.467029, lng:50.212511},
   { lat:26.495992, lng:50.221524},
   { lat:26.518804, lng:50.190024},
   { lat:26.482395, lng:50.182299},
   { lat:26.454965, lng:50.173631},
   { lat:26.423225, lng:50.160155},
   { lat:26.433524, lng:50.13226},
   { lat:26.493611, lng:50.132518},
   { lat:26.496261, lng:50.121338},
   { lat:26.480474, lng:50.118399},
   { lat:26.456886, lng:50.111146},
   { lat:26.479442, lng:50.094041},
   { lat:26.477411, lng:50.072817},
   { lat:26.514095, lng:50.04404},
   { lat:26.558232, lng:50.023471},
   { lat:26.547767, lng:50.036525},
   { lat:26.544672, lng:50.052326},
   { lat:26.547082, lng:50.08118},
   { lat:26.572503, lng:50.087483},
   { lat:26.597919, lng:50.089667},
   { lat:26.598773, lng:50.062668},
   { lat:26.593487, lng:50.042535},
   { lat:26.589429, lng:50.020342},
   { lat:26.653082, lng:50.013709},
   { lat:26.660396, lng:49.98806},
   { lat:26.673846, lng:49.989877},
   { lat:26.695832, lng:49.988018},
   { lat:26.742244, lng:49.995286},
   { lat:26.671808, lng:50.033152},
   { lat:26.681728, lng:50.122515},
   { lat:26.771475, lng:50.034827},
   { lat:26.810909, lng:50.004129},
   { lat:26.828886, lng:49.98579},
   { lat:26.797565, lng:49.93244},
   { lat:26.700848, lng:49.901665},
   { lat:26.689885, lng:49.894443},
   { lat:26.680072, lng:49.889882},
   { lat:26.65676, lng:49.861706},
   { lat:26.613037, lng:49.839771},
   { lat:26.503443, lng:49.78353},
   { lat:26.397395, lng:49.809622},
   { lat:26.303254, lng:49.838633},
   { lat:26.195791, lng:49.904723},
   { lat:25.918516, lng:49.984889},
   { lat:25.911491, lng:50.044777},
   { lat:25.930211, lng:50.127593},
   { lat:25.986488, lng:50.111323},
   { lat:26.010297, lng:50.04551},
   { lat:25.986407, lng:50.00505},
   { lat:26.036172, lng:50.003359},
   { lat:26.082216, lng:50.011282},
   { lat:26.114049, lng:49.988086},
   { lat:26.153386, lng:49.99743},
   { lat:26.210576, lng:50.032181},
   { lat:26.039653, lng:50.139255},
   { lat:26.275088, lng:50.162901},
   { lat:26.270061, lng:50.15262},
   { lat:26.264573, lng:50.13427},
   { lat:26.266988, lng:50.11388},
   { lat:26.272789, lng:50.101902},
   { lat:26.285824, lng:50.09267},
   { lat:26.296808, lng:50.083475},
   { lat:26.314717, lng:50.082864},
   { lat:26.332641, lng:50.09518},
   { lat:26.338449, lng:50.111295},
   { lat:26.340814, lng:50.11652},
   { lat:26.337949, lng:50.126723},
   { lat:26.331007, lng:50.13787},
   { lat:26.322526, lng:50.147129},
   { lat:26.302946, lng:50.162901},
   { lat:26.29048, lng:50.16599}]],
   cities: [
   {
   name: "Safwa",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Abuman",
   arName: "ابومان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "5th defence area",
   arName: "منطقة الدفاع 5",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nabiyah",
   arName: "نابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm As Sahik",
   arName: "ام الساهك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safwa",
   arName: "صفوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Awjam",
   arName: "عوجم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu main",
   arName: "ابو معن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ruwaihah",
   arName: "رويحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Budur",
   arName: "بدور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shura",
   arName: "شورى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safaa",
   arName: "صفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masif",
   arName: "مصيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aujam",
   arName: "اوجام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Raihan",
   arName: "ريحان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Urubah",
   arName: "عروبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khatrashiyah",
   arName: "خترشية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marwah",
   arName: "مروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Talal",
   arName: "تلال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazm",
   arName: "حزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Duraydi",
   arName: "دريدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amal",
   arName: "أمل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Riha",
   arName: "رحاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawabi",
   arName: "روابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manar",
   arName: "منار",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Dhahran",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Ajyal",
   arName: "اجيال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "K.F.U.P.M",
   arName: "جامعة الملك فهد للبترول والمعادن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "National Guard",
   arName: "الحرس الوطني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwa",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bandariyah Dahran",
   arName: "بندريه - الظهران",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dana South",
   arName: "الدانة الجونبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Doha north",
   arName: "الدوحة الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Doha south",
   arName: "الدوحة الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gulf Palace",
   arName: "قصر الخليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Saudi Aramco",
   arName: "سعودية ارامكو",
   googleName: "",
   postalCode: "",
   },
   {
   name: "KFUPM",
   arName: "جامعة الملك فهد للبترول والمعادن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qusur",
   arName: "قصور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Air defense",
   arName: "الدفاع الجوي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Qusur",
   arName: "القصر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dana North",
   arName: "الدانة الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiah",
   arName: "جامعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Danah al janubiyah",
   arName: "دانة الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dawhah ash shamaliyah",
   arName: "دوحة الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Danah ash shamaliyah",
   arName: "دانة الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Haras al watani",
   arName: "حرس الوطني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dawhah al janubiyah",
   arName: "دوحة الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hajr",
   arName: "هجر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gharb adh dhahran",
   arName: "غرب الظهران",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Khobar",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Al Khalidiyah Al Janubiya",
   arName: "الخالديه الجنوبيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khobar North",
   arName: "خبر الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khobar South",
   arName: "خبر الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madinat Al Umal",
   arName: "مدينة العمال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jawharah Khobar",
   arName: "جوهرة الخبر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khalidiyah Al Janubiyah",
   arName: "الخليج الجبيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rakha Al Shamaliyah",
   arName: "الراكة الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alamwaj",
   arName: "الامواج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hada",
   arName: "الهدا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "HalfMoon",
   arName: "هاف مون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Bustan",
   arName: "البستان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rawabi",
   arName: "الروابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khobar Cornish",
   arName: "الخبر الكورنيش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Ulaya",
   arName: "العليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rakha South",
   arName: "الراكة الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabiyah",
   arName: "رابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rakah",
   arName: "راكة الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurtoba",
   arName: "قرطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Iskan",
   arName: "الاسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jisr",
   arName: "الجسر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thuqba industrial",
   arName: "الثقبة الصناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Golden Belt",
   arName: "الحزام الذهبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alsheraa",
   arName: "الشراع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Bandariyah",
   arName: "البندرية الخبر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khozama",
   arName: "الخزامى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Air Base",
   arName: "قاعدة الجوية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Thuqba",
   arName: "الثقبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Allulu",
   arName: "اللؤلؤ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rakah al janubiyah",
   arName: "راكة الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ishbiliya",
   arName: "اشبيليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Subeqha",
   arName: "صبيخة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Andalus",
   arName: "الأندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Aqrabiyah",
   arName: "العقربية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Taawun",
   arName: "التعاون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Tahliyah",
   arName: "التحلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nawras",
   arName: "النورس  العزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Safa",
   arName: "الصفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ibn Sina",
   arName: "ابن سينا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah al thuqbah",
   arName: "صناعية الثقبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thuqbah",
   arName: "ثقبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bandariyah",
   arName: "بندرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hizam adh dhahabi",
   arName: "حزام الذهبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hizam al akhdar",
   arName: "حزام الاخضر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amwaj",
   arName: "امواج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aqrabiyah",
   arName: "عقربية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jisr",
   arName: "جسر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sheraa",
   arName: "شراع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmok",
   arName: "يرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taawn",
   arName: "تعاون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tahliyah",
   arName: "تحلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra",
   arName: "حمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sporting city",
   arName: "مدينة الرياضية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Lulu",
   arName: "لؤلؤ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kurnaish",
   arName: "كورنيش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawabi",
   arName: "روابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ulaya",
   arName: "عليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuzama",
   arName: "خزامي",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Qatif",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Buhayrah",
   arName: "بحيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm Al Hamam",
   arName: "ام الحمام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tarout",
   arName: "تاروت",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nassirah",
   arName: "الناصرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qatif Iskan",
   arName: "اسكان القطيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sadafah",
   arName: "صدفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Muhammadiyah",
   arName: "المحمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Tubi",
   arName: "الطوبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Darin",
   arName: "دارين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jarodiah",
   arName: "الجارودية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Saihat",
   arName: "سيهات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rabiyah",
   arName: "الربيعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Malahah",
   arName: "ملاحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ank",
   arName: "عنك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadiah",
   arName: "وديعه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yaqut",
   arName: "ياقوت",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bab as sab",
   arName: "باب الساب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badiah",
   arName: "بديعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuzama",
   arName: "خزامي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Narjis",
   arName: "نرجس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rif",
   arName: "ريف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shahba",
   arName: "شهباء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadih",
   arName: "قديح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wurud",
   arName: "ورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zomorod",
   arName: "زمرد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bawadi",
   arName: "بوادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bustan",
   arName: "بستان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiymah",
   arName: "جميمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jawahir",
   arName: "جواهر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mashtal",
   arName: "مشتل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tawbi",
   arName: "توبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badiah al basri",
   arName: "بديعة البصري",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madani",
   arName: "مدني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabiah",
   arName: "رابعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm al jazm",
   arName: "ام الجزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bab madinah ash shamal",
   arName: "باب الشمال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badia",
   arName: "بديع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fath",
   arName: "فتح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Halat mahish",
   arName: "حلة محيش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Husain",
   arName: "حسين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jarrari",
   arName: "جراري",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shweikah",
   arName: "شويكة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bahari",
   arName: "بحاري",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jazirah",
   arName: "جزيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kuwaikib",
   arName: "كويكب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasirah",
   arName: "ناصرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahah",
   arName: "واحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bahr",
   arName: "بحر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dababiyyah",
   arName: "دبابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madaris",
   arName: "مدارس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masudiyah",
   arName: "مسعودية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mayyas",
   arName: "مياس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rida",
   arName: "رضا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shamasiyyah",
   arName: "شماسيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shariah",
   arName: "شريعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thuraiya",
   arName: "ثريا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Warehouse area",
   arName: "منطقة المستودعات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wasadah",
   arName: "وسادة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahra",
   arName: "زهراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zarah",
   arName: "زارة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Doij",
   arName: "دويج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khamisah",
   arName: "خامسة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuwaildiyah",
   arName: "خويلدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muruj",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shati",
   arName: "شاطئ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jarudiyah",
   arName: "جارودية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Majidiyah",
   arName: "مجيدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qalah",
   arName: "قلعة",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Dammam",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Al Dawasir",
   arName: "الدواسر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khalij",
   arName: "الخليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abudllah Fuad",
   arName: "عبدالله فؤاد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nuzhah",
   arName: "النزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Faysaliyah",
   arName: "الفيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taybha",
   arName: "طيبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Jawharah",
   arName: "الجوهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fakhriyah",
   arName: "فاخرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial Area 2",
   arName: "الصناعية الثانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALNAWRAS-Petromin",
   arName: "النورس بيترومين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khalidiyah Al Shamaliyah",
   arName: "الخالدية الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Souq",
   arName: "سوق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Zuhur",
   arName: "الزهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Muraikabat",
   arName: "المركبات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rawdha",
   arName: "الروضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial Area 1",
   arName: "الصناعية الأولى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nada",
   arName: "الندا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uhud",
   arName: "أحد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hamra Dammam",
   arName: "الحمراء الدمام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Iskan Dammam",
   arName: "أسكان الدمام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Itisalat",
   arName: "اتصالات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Anwar",
   arName: "الأنوار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Dabab",
   arName: "الضباب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dammam 91",
   arName: "دمام 91",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Danah",
   arName: "الدانة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shati Al Gharbi",
   arName: "الشاطئ الغربي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jamlyin",
   arName: "الجاملين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nakhil",
   arName: "النخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghirnatah",
   arName: "غرناطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madinat Alummal Dammam",
   arName: "مدينة العمال الدمام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Faiha",
   arName: "الفيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Jalawiyah",
   arName: "الجلوية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Kuthriah",
   arName: "الخضرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Basatin",
   arName: "البساتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Muntazah",
   arName: "المنتزة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Wahah",
   arName: "الواحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Noor",
   arName: "النور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hagr",
   arName: "هاجر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Fursan",
   arName: "الفرسان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dahiyat Al Malik Fahd",
   arName: "ضاحية الملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badiyah",
   arName: "بادية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah al janubiyah",
   arName: "خالدية الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Anud",
   arName: "عنود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdul aziz seaport",
   arName: "ميناء الملك عبدالعزيز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasriyah",
   arName: "ناصرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadabah",
   arName: "هضبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Anwar",
   arName: "أنوار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zuhur",
   arName: "زهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial area no1",
   arName: "صناعية الاولى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qazaz",
   arName: "قزاز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Amamrah",
   arName: "الامارة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shati Ash Sarqi",
   arName: "الشاطئ الشرقي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Anud",
   arName: "العنود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Athir",
   arName: "الأثير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Aziziyah Dammam",
   arName: "العزيزية الدمام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Firdaws",
   arName: "الفردوس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rayyan",
   arName: "الريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Amal",
   arName: "الامل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Manar",
   arName: "المنار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badr",
   arName: "بدر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mazruiyah",
   arName: "المزروعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amir muhammed ibn saud",
   arName: "امير محمد بن سعود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Athir",
   arName: "أثير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiyin",
   arName: "جامعيين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra",
   arName: "حمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabiyah",
   arName: "رابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayyan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shulah",
   arName: "شعلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tubayshi",
   arName: "طبيشي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nawras",
   arName: "نورس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jalawiyah",
   arName: "جلوية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kuthriah",
   arName: "خضرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shati al gharbi",
   arName: "شاطئ الغربي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sadafah",
   arName: "صدفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taybah",
   arName: "طيبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fursan",
   arName: "فرسان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madinat al umal",
   arName: "مدينة العمال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muraikabat",
   arName: "مريكبات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jawharah",
   arName: "جوهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah ash shamaliyah",
   arName: "خالدية الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalij",
   arName: "خليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dawasir",
   arName: "دواسر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rakah ash shamaliyah",
   arName: "راكة الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Saif",
   arName: "سيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shati ash sharqi",
   arName: "شاطئ الشرقي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qasr al khalij",
   arName: "قصر الخليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial city 2",
   arName: " صناعية الثانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mazruiyah",
   arName: "مزروعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manar",
   arName: "منار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nur",
   arName: "نور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amal",
   arName: "أمل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badi",
   arName: "بديع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Danah",
   arName: "دانة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Adamah",
   arName: "عدامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah",
   arName: "محمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahah",
   arName: "واحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ibn khaldun",
   arName: "ابن خلدون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Firdaws",
   arName: "فردوس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadisiyah",
   arName: "قادسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Buhayrah",
   arName: "بحيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dabab",
   arName: "ضباب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amamrah",
   arName: "عمامرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nada",
   arName: "ندى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Saihat",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "SAIHAT",
   arName: "سيهات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghadir",
   arName: "غدير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Firdaws",
   arName: "فردوس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalij",
   arName: "خليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zuhur",
   arName: "زهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gharb an nabiyah",
   arName: "غرب النابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tif",
   arName: "طف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurtubah",
   arName: "قرطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kawthar",
   arName: "كوثر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dirah",
   arName: "ديرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghirnatah",
   arName: "غرناطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nur",
   arName: "نور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khisab",
   arName: "خصاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nabiyah",
   arName: "نابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naqa",
   arName: "نقاء",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Ras Tanura",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Ras Tanura",
   arName: "راس تنورة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zuhur",
   arName: "زهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naim",
   arName: "نعيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faihaa",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurtubah",
   arName: "قرطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Danah",
   arName: "دانة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Montazah",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Anak",
   arName: "عنك",
   googleName: "",
   districts: [
   {
   name: "Bustan",
   arName: "بستان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rimal",
   arName: "رمال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahar",
   arName: "محار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badr",
   arName: "بدر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Anwar",
   arName: "انوار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Anak",
   arName: "عنك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mallahah",
   arName: "ملاحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm al hamam",
   arName: "ام الحمام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jish",
   arName: "جش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayah",
   arName: "راية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Tarout",
   arName: "تاروت",
   googleName: "",
   districts: [
   {
   name: "Sadafah",
   arName: "صدفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jabal",
   arName: "جبل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sanabis",
   arName: "سنابس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dirah",
   arName: "ديرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manakh",
   arName: "مناخ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiyin",
   arName: "جامعيين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sawari",
   arName: "صواري",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabeiyah",
   arName: "ربيعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Lulu",
   arName: "لؤلؤ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Narjis",
   arName: "نرجس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jawharah",
   arName: "جوهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yamamah",
   arName: "يمامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zour",
   arName: "زور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zomorod",
   arName: "زمرد",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "J15",
  initialGeocode: { lat:19.105598, lang:41.097098} ,
   polygons: [
  [
   { lat:19.105598, lng:41.097098},
   { lat:19.105923, lng:41.119757},
   { lat:19.133495, lng:41.110487},
   { lat:19.167225, lng:41.106024},
   { lat:19.191221, lng:41.071005},
   { lat:19.174684, lng:41.044569},
   { lat:19.139334, lng:41.058302},
   { lat:19.110789, lng:41.079245},
   { lat:19.11014, lng:41.079932}]],
   cities: [
   {
   name: "Al-Qunfudhah",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "east",
   arName: "الشرق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "North",
   arName: "شمال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "South",
   arName: "جنوب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Konfouda",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gharbi",
   arName: "غربي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ratibah",
   arName: "رتبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharqiyyah",
   arName: "شرقية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Azhar",
   arName: "ازهار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shamiyah",
   arName: "شامية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naimyyah",
   arName: "ناعمية",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "J17",
  initialGeocode: { lat:20.11139198, lang:42.75054932} ,
   polygons: [
  [
   { lat:20.11139198, lng:42.75054932},
   { lat:20.10429927, lng:42.68600464},
   { lat:20.1365363, lng:42.65579224},
   { lat:20.12880002, lng:42.60978699},
   { lat:20.11461584, lng:42.57133484},
   { lat:20.08624363, lng:42.5617218},
   { lat:20.0527062, lng:42.5466156},
   { lat:20.01787129, lng:42.53425598},
   { lat:19.97915678, lng:42.54180908},
   { lat:19.94753288, lng:42.54592896},
   { lat:19.93010475, lng:42.56103516},
   { lat:19.90686426, lng:42.58575439},
   { lat:19.89330573, lng:42.61253357},
   { lat:19.89201438, lng:42.64274597},
   { lat:19.89459707, lng:42.67501831},
   { lat:19.90363615, lng:42.69836426},
   { lat:19.94688743, lng:42.71484375},
   { lat:19.98625516, lng:42.72926331},
   { lat:20.02109707, lng:42.74024963},
   { lat:20.05077113, lng:42.74642944},
   { lat:20.07914977, lng:42.75123596},
   { lat:20.10172002, lng:42.75398254},
   { lat:20.11139198, lng:42.75054932}]],
   cities: [
   {
   name: "Bisha",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "mokhattat ben hammad",
   arName: "مخططة بن حماد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Namran",
   arName: "نمران",
   googleName: "",
   postalCode: "",
   },
   {
   name: "qaryet almahdi",
   arName: "قرية المهدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "shedayk",
   arName: "صديق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ganob almadena",
   arName: "جنوب المدينة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marea",
   arName: "ماريا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Daylami",
   arName: "ديلمي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "elrafaia",
   arName: "رفاعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "elshakika",
   arName: "شاكيكا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Goz Naaman",
   arName: "جوز نعمان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamma",
   arName: "حمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "hay alkhalig",
   arName: "هاي الخليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "hay almatar",
   arName: "هاي المطر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "khalf almared",
   arName: "خلف المارد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "mokhattat almousaa",
   arName: "مقطع الموسى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thinya",
   arName: "ثينيا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sour",
   arName: "سور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naqie",
   arName: "نقيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Horia",
   arName: "حرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazimi",
   arName: "حازمي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Harf",
   arName: "حرف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dahw",
   arName: "دهو",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baqira",
   arName: "بقيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "aramana",
   arName: "عارمان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "moher",
   arName: "مهر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bisha",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "mokhattat ben kadassa",
   arName: "محطة بن كداسة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gamaya",
   arName: "جامايا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "hay almokhattat",
   arName: "هاي المخات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hifa",
   arName: "حيفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "sharia alssttin",
   arName: "شريعة الستتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "elhamima",
   arName: "حميمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "gnayea",
   arName: "غنايا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "hay alkhaldya",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bashouk",
   arName: "باشوك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rqitae",
   arName: "رقطي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Genina",
   arName: "جنينة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ataf",
   arName: "عطاف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amir sultan",
   arName: "امير سلطان ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayyan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khazzan",
   arName: "خزان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muruj",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fahd",
   arName: "فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sawaer",
   arName: "صواير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi",
   arName: "وادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Airport dist",
   arName: "مطار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuzama",
   arName: "خزامي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bawadi",
   arName: "بوادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdullah",
   arName: "ملك عبدالله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zuhur",
   arName: "زهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rimal",
   arName: "رمال",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "J12",
  initialGeocode: { lat:17.49424, lang:44.106002} ,
   polygons: [
  [
   { lat:17.49424, lng:44.106002},
   { lat:17.471645, lng:44.095359},
   { lat:17.434963, lng:44.103255},
   { lat:17.46051, lng:44.232344},
   { lat:17.483434, lng:44.309592},
   { lat:17.489656, lng:44.37139},
   { lat:17.530583, lng:44.3683},
   { lat:17.6111, lng:44.37654},
   { lat:17.630733, lng:44.264273},
   { lat:17.576082, lng:44.155097},
   { lat:17.547933, lng:44.140677}]],
   cities: [
   {
   name: "Najran",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "MONGM",
   arName: "منجم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AMIR MESHAAL",
   arName: "أمير مشعل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziba",
   arName: "عذيبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "DYAFA",
   arName: "ضيافة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ESKAN",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "KHALDYA",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "MAKHAYAM",
   arName: "مخيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALQABEL",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "DAHDA",
   arName: "داهدا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ELFAHD ALSHAMALY",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "MARADA",
   arName: "مارادا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "REGLA",
   arName: "ريجلا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "SHARQ ALMTAR",
   arName: "شرق المطار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mussem",
   arName: "موسم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aswarmh",
   arName: "اصوارمه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badwy",
   arName: "بدوي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "NAJRAN",
   arName: "نجران",
   googleName: "",
   postalCode: "",
   },
   {
   name: "GORBA",
   arName: "غربة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ELBALAD",
   arName: "بلد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Arysa",
   arName: "عريسة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "FYSLIA",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "MOFGA",
   arName: "موفجا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ELFAHD ALGANOBY",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zoor alharth",
   arName: "زور الحارث",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadan",
   arName: "حدان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shorfa",
   arName: "شرفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Elghwela",
   arName: "غويلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zoor wadaa",
   arName: "زور وداع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jurbah",
   arName: "جربة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharq ad dubat",
   arName: "شرق الضباط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahufah residential subdivision",
   arName: "مخطط نهوفة السكني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Najran regional airport",
   arName: "مطار نجران الاقليمي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rajla ash shamaliyah agricultural subdivision",
   arName: "مخطط رجلا الشمالية الزراعي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Twal",
   arName: "طوال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Najran",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aba lasaud",
   arName: "ابا لسعود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Athaybah ash shamaliyah  agricultural subdivision",
   arName: "مخطط الاثايبة الشمالية الزراعي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahban subdivision",
   arName: "مخطط الصهبان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Araysah al janubi subdivision",
   arName: "مخطط العريسة الجنوبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ibn jarullah residential subdivision",
   arName: "مخطط ابن جارالله السكني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jarbah nahiqah",
   arName: "جربة نهيقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rajla al janubiyah agricultural subdivision",
   arName: "مخطط رجلا الجنوبية الزراعي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dahdah",
   arName: "دحضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shamal ad dubat",
   arName: "شمال الضباط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghuwayla agricutural subdivision",
   arName: "مخططات الغويلا الزراعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Husayn agricultural subdivision",
   arName: "مخطط الحصين الزراعي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Araysah ash shamali subdivision",
   arName: "مخطط العريسة الشمالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dubat",
   arName: "ضباط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadn",
   arName: "حضن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qabil",
   arName: "قابل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amlah",
   arName: "املاح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shaib rir residential subdivision",
   arName: "مخطط شعب رير السكني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shamal al fahd",
   arName: "شمال الفهد",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "Taif Region",
  initialGeocode: { lat:21.38307337, lang:40.46848297} ,
   polygons: [
  [
   { lat:21.38307337, lng:40.46848297},
   { lat:21.38371274, lng:40.44204712},
   { lat:21.37667943, lng:40.42488098},
   { lat:21.36740773, lng:40.41355133},
   { lat:21.35461822, lng:40.40222168},
   { lat:21.3274368, lng:40.41252136},
   { lat:21.31368428, lng:40.41526794},
   { lat:21.30504832, lng:40.40496826},
   { lat:21.29993048, lng:40.39260864},
   { lat:21.29673173, lng:40.3768158},
   { lat:21.28937436, lng:40.3678894},
   { lat:21.27945731, lng:40.36582947},
   { lat:21.23978244, lng:40.37269592},
   { lat:21.21193967, lng:40.40153503},
   { lat:21.21802072, lng:40.44822693},
   { lat:21.23626238, lng:40.46951294},
   { lat:21.26985953, lng:40.47122955},
   { lat:21.30376888, lng:40.45166016},
   { lat:21.32775661, lng:40.43655396},
   { lat:21.35685647, lng:40.44616699},
   { lat:21.36932607, lng:40.46539307},
   { lat:21.38307337, lng:40.46848297}]],
   cities: [
   {
   name: "Taif",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "ARAAFA",
   arName: "عرافة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALASKAN",
   arName: "الاسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "BALAD",
   arName: "بلد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "BASATEEN",
   arName: "بساتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "BAYE",
   arName: "باي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALBUKHARIA",
   arName: "البخاريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALFASALIA",
   arName: "الفيصليه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALGAAL",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALKHALDIA",
   arName: "الخالديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "OSAM",
   arName: "أسام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALQUMRIA",
   arName: "القمريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALRADEEF",
   arName: "الرديف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALRAHAAB",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALRAMEDA",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "RAWA",
   arName: "روا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALRIYAAN",
   arName: "الريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ROUDA ALSHARQIA",
   arName: "روضه الشرقيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALSAAL ALSAGEER",
   arName: "السيل الصغير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "SAFA",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "SAHLY",
   arName: "سهلى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALSANAIYA",
   arName: "الصناعيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALSHARQIA",
   arName: "الشرقيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALSHOHADA ALGUNUBIA",
   arName: "الشهداء الجنوبيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "SHUTBA",
   arName: "شطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "WASAT",
   arName: "وسط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALWASSAM",
   arName: "الوسام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALYAMINIA",
   arName: "اليمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AOODA",
   arName: "عودا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "HALAGA KARBIA",
   arName: "هلجا كربيا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "HALAGA SARGIA",
   arName: "هالاجا سارجيا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "HUSSAN",
   arName: "حسن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "MISRA",
   arName: "ميسرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "MUKHATAT MALIK FAHA",
   arName: "مخطط مالك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "OKAZ",
   arName: "عكاظ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "RAHAAB",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taif",
   arName: "الطائف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "TAREEK ALJANOOB",
   arName: "طريق الجنوب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "WADI WAG",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "sherqia",
   arName: "شرقية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzha",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "hawaya",
   arName: "حوايا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "masarra",
   arName: "مسره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "khaldia",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hada",
   arName: "هدا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "shubra",
   arName: "شبرا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "masshi",
   arName: "معشي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Riyan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "shohada junubia",
   arName: "شهداء الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Washha",
   arName: "وشحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "shahar",
   arName: "شهار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "nakhab",
   arName: "نخب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "alsadad",
   arName: "السداد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "UMM ALARAAD",
   arName: "ام العراد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ouda",
   arName: "عودة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salama",
   arName: "سلامه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziya",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mathna",
   arName: "مثناه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "QURA",
   arName: "قرى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "hawiya",
   arName: "حوية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharafiya",
   arName: "شرفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "sale sagheer",
   arName: "سيل الصغير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntaza",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bughariya",
   arName: "بخاريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bin sawalam",
   arName: "بن سويليم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "alshtba",
   arName: "الشطبه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Halqa Sharqia",
   arName: "حلقة الشرقية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Halaqa alghbiya",
   arName: "حلقة الغربيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "UM ALSABAA",
   arName: "ام السباع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "algame",
   arName: "القيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wasit",
   arName: "واسط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "mukhatat albaa",
   arName: "مخطط البيعه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shafa",
   arName: "شفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahat alwaheet",
   arName: "وهط والوهيط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Riha",
   arName: "رحاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yamaniya",
   arName: "يمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "wadi namal",
   arName: "وادي النمل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qalt",
   arName: "قلت",
   googleName: "",
   postalCode: "",
   },
   {
   name: "mukhatat king fahad",
   arName: "مخطط الملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "qaida aljawiya",
   arName: "قاعدة الجوية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "sukan alharas",
   arName: "اسكان الحرس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "wadi alarj",
   arName: "وادي العرج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "liah",
   arName: "ليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "alsr",
   arName: "السر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "sale alkaber",
   arName: "سيل الكبير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "alkaeda aljwiah",
   arName: "القاعدة الجوية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "BIN SULALAM",
   arName: "بن سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "SAHAAR",
   arName: "صحراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "shohada sumalia",
   arName: "شهداء الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahaly",
   arName: "سحيلي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wasiliya",
   arName: "واصليه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dahas",
   arName: "دهاس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ABU BAKAR",
   arName: "أبو بكر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ANOOD",
   arName: "عنود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "GHAZALY",
   arName: "غزالى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "RAHMANIYA",
   arName: "رحمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALSADDAD",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "SETEEN",
   arName: "ستين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "WASHA",
   arName: "وشا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ARTH ALQAMAR",
   arName: "ارث القمر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "KABEERY ALSUNAIYA",
   arName: "كابيري السنعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "SOHADA SHUMALIA",
   arName: "شحادة شمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "UMM ALSABAA",
   arName: "ام السباع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AGEEK",
   arName: "عقيق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisilia",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qutbia",
   arName: "قطبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qumriya",
   arName: "قمرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "jabra",
   arName: "جبرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wesam",
   arName: "وسام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Urj al aala",
   arName: "عرج الأعلى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Akhbab",
   arName: "اخباب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C13",
   arName: "ج13",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C20",
   arName: "ج20",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C23",
   arName: "ج23",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C30",
   arName: "ج30",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C32",
   arName: "ج32",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C40",
   arName: "ج40",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C8",
   arName: "ج8",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Markazia",
   arName: "مركزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masarah 1",
   arName: "مسرة 1",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mu'tarid",
   arName: "معترض",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qayam al aala",
   arName: "قيم الأعلى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "The new international airport",
   arName: "مطار الدولي الجديد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm Alsabaa",
   arName: "أم السباع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "RADEEF",
   arName: "رديف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jaal",
   arName: "جال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naseem",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sanaiya",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ramida",
   arName: "رميده",
   googleName: "",
   postalCode: "",
   },
   {
   name: "alhars alwatni",
   arName: "الحرس الوظني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sr",
   arName: "سر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm Alarad",
   arName: "أم العراد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C14",
   arName: "ج14",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C19",
   arName: "ج19",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C33",
   arName: "ج33",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C41",
   arName: "ج41",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Extension area Dhahiat al Iskan",
   arName: "إمتداد ضاحية الإسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadaek",
   arName: "حدائق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qrahin",
   arName: "قراحين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Riha",
   arName: "ريحه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C22",
   arName: "ج22",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C3",
   arName: "ج3",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C37",
   arName: "ج37",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C4",
   arName: "ج4",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C7",
   arName: "ج7",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hars Watani",
   arName: "حرس الوطني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jafijif",
   arName: "جفيجف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ruddaf",
   arName: "ردف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amriah",
   arName: "عارمية 2",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C12",
   arName: "ج12",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C16",
   arName: "ج16",
   googleName: "",
   postalCode: "",
   },
   {
   name: "liah",
   arName: "ليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ma'arid",
   arName: "معارض",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C1",
   arName: "ج1",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C15",
   arName: "ج15",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C18",
   arName: "ج18",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C26",
   arName: "ج26",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C28",
   arName: "ج28",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C29",
   arName: "ج29",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dhabt",
   arName: "ضبط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Huwaya Al Janoubiyah",
   arName: "حوايا الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Huwaya Ash Shamaliyyah",
   arName: "حوايا الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khadera",
   arName: "خضيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Siana",
   arName: "صيانة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Urj al asfal",
   arName: "رج الأسفل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amriah 1",
   arName: "عارمية 1",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C21",
   arName: "ج21",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C24",
   arName: "ج24",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C25",
   arName: "ج25",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C34",
   arName: "ج34",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dhahiat al Iskan",
   arName: "ضاحية الاسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masarah 2",
   arName: "مسرة 2",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mudhbah",
   arName: "مضباع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suq Ukadh",
   arName: "سوق عكاظ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C11",
   arName: "ج11",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C27",
   arName: "ج27",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C38",
   arName: "ج38",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C39",
   arName: "ج39",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C44",
   arName: "ج44",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C5",
   arName: "ج5",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghumayr",
   arName: "غمير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masmla",
   arName: "مثمله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadirah",
   arName: "قديرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qami",
   arName: "قميع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qayam al asfal",
   arName: "قيم الأسفل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sarur",
   arName: "سرور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sawaiqa",
   arName: "سويقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Air Defense Forces",
   arName: "قوات الدفاع الجوي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C10",
   arName: "ج10",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C17",
   arName: "ج17",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C31",
   arName: "ج31",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C35",
   arName: "ج35",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C36",
   arName: "ج36",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C43",
   arName: "ج43",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C6",
   arName: "ج6",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C9",
   arName: "ج9",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jawharah",
   arName: "جوهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "New Taif University",
   arName: "جامعة الطائف الجديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sadad",
   arName: "سداد",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "Madina Region",
  initialGeocode: { lat:24.58459277, lang:39.71076965} ,
   polygons: [
  [
   { lat:24.58459277, lng:39.71076965},
   { lat:24.58147073, lng:39.69017029},
   { lat:24.57834861, lng:39.65858459},
   { lat:24.57085522, lng:39.61738586},
   { lat:24.5577407, lng:39.57481384},
   { lat:24.53962792, lng:39.55490112},
   { lat:24.52900887, lng:39.52194214},
   { lat:24.50901763, lng:39.51713562},
   { lat:24.48714856, lng:39.49516296},
   { lat:24.46715066, lng:39.47593689},
   { lat:24.44027349, lng:39.46769714},
   { lat:24.42026815, lng:39.48074341},
   { lat:24.39775835, lng:39.49584961},
   { lat:24.37461909, lng:39.50958252},
   { lat:24.36336063, lng:39.52537537},
   { lat:24.34647105, lng:39.559021},
   { lat:24.34709663, lng:39.63317871},
   { lat:24.36398613, lng:39.67094421},
   { lat:24.38650194, lng:39.69703674},
   { lat:24.45527541, lng:39.70802307},
   { lat:24.49714632, lng:39.72518921},
   { lat:24.53338153, lng:39.72587585},
   { lat:24.56398588, lng:39.72175598},
   { lat:24.58459277, lng:39.71076965}]],
   cities: [
   {
   name: "Madina",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Qiblathain",
   arName: "قبلتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shouran",
   arName: "شوران",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Azhari",
   arName: "ازهري",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasr",
   arName: "نصر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khaldiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Seeh",
   arName: "سيح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Daeetha",
   arName: "دعيثه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thalal",
   arName: "تلال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shebiyah",
   arName: "شيبيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madaris",
   arName: "مدارس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Asfarain",
   arName: "اصفرين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suhman",
   arName: "سحمان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurdi",
   arName: "كردي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hijra",
   arName: "هجرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Juboor",
   arName: "جبور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badrani",
   arName: "بدراني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umara",
   arName: "امراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jama Allail",
   arName: "جمال الليل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Beer Uthman",
   arName: "بير عثمان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jurf",
   arName: "جرف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlShuhadah",
   arName: "الشهداء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mathar",
   arName: "مطار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bahr",
   arName: "بحر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manarath",
   arName: "منارات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Duwaima",
   arName: "دويمه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abyar Ali",
   arName: "ابيار على",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quba",
   arName: "قباء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "alaqol",
   arName: "العاقول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Askan",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ardh Al Kuwaithiya",
   arName: "ارض الكويتيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gharbiyah",
   arName: "غربيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Awali",
   arName: "عوالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurban",
   arName: "قربان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bagedo",
   arName: "مخطط باجدو",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Anabis",
   arName: "عنابس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Athaa",
   arName: "عطاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamara Alasad",
   arName: "حمراء الاسد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bani abdul ashhal",
   arName: "بني عبدالاشهل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial area",
   arName: "منطقة صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jabirah",
   arName: "جابرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shuraybat",
   arName: "شريبات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Urwah",
   arName: "عروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wabra",
   arName: "وبرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bani bayadah",
   arName: "بني بياضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gharra",
   arName: "غراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadra",
   arName: "هدراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masani",
   arName: "مصانع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mughaisilah",
   arName: "مغيسلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naqa",
   arName: "نقاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qaswa",
   arName: "قصواء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rumanah",
   arName: "رمانة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taibah",
   arName: "طيبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Warqan",
   arName: "ورقان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aqool",
   arName: "عقول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madina",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bani zafar",
   arName: "بني ظفر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dhu al hulayfah",
   arName: "ذو الحليفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Difa",
   arName: "دفاع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghabah",
   arName: "غابة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Haram",
   arName: "حرم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khakh",
   arName: "خاخ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahzur",
   arName: "مهزور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sadiqiyah",
   arName: "صادقية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Duwaikhilah",
   arName: "دويخلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hathm",
   arName: "حثم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jumah",
   arName: "جمعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu burayqa",
   arName: "ابو بريقاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiah",
   arName: "جامعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mabuth",
   arName: "مبعوث",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manakhah",
   arName: "مناخة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mudhainib",
   arName: "مذينب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nubala",
   arName: "نبلاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sayed ash shuhada",
   arName: "سيد الشهداء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wairah",
   arName: "وعيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ranuna",
   arName: "رانوناء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahirah",
   arName: "ظاهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ariyd",
   arName: "عريض",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badaah",
   arName: "بضاعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bani khidrah",
   arName: "بني خدرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bani muawiyah",
   arName: "بني معاوية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Barakah",
   arName: "بركة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fath",
   arName: "فتح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hafya",
   arName: "حفيا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ihn",
   arName: "عهن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jabal uhud",
   arName: "جبل احد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamawat",
   arName: "جماوات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qalah",
   arName: "قلعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qalat makhit",
   arName: "قلعة مخيط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Raht",
   arName: "رهط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sad",
   arName: "سد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sakb",
   arName: "سكب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Usbah",
   arName: "عصبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ayn al khif",
   arName: "عين الخيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Balqa",
   arName: "بلقاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bani harithah",
   arName: "بني حارثة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dar",
   arName: "درع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jassah",
   arName: "جصة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khatim",
   arName: "خاتم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masjid ad dar",
   arName: "مسجد الدرع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayah",
   arName: "راية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shahba",
   arName: "شهباء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shizat",
   arName: "شظاة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suqya",
   arName: "سقيا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu kabir",
   arName: "ابو كبير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aqol",
   arName: "عاقول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kittanah",
   arName: "كتانة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naqs",
   arName: "نقس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shafiyah",
   arName: "شافية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sikkah al hadid",
   arName: "سكة الحديد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm khalid",
   arName: "ام خالد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uyun",
   arName: "عيون",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "JS8",
  initialGeocode: { lat:24.147387, lang:37.939807} ,
   polygons: [
  [
   { lat:24.147387, lng:37.939807},
   { lat:24.172446, lng:37.962122},
   { lat:24.18748, lng:37.998171},
   { lat:24.210654, lng:38.040057},
   { lat:24.18936, lng:38.079195},
   { lat:24.159918, lng:38.132068},
   { lat:24.147387, lng:38.15816},
   { lat:24.128589, lng:38.18288},
   { lat:24.10352, lng:38.202106},
   { lat:24.066535, lng:38.249484},
   { lat:24.027657, lng:38.29137},
   { lat:23.998178, lng:38.335315},
   { lat:23.975594, lng:38.359347},
   { lat:23.923823, lng:38.329822},
   { lat:23.900283, lng:38.311969},
   { lat:23.934179, lng:38.292743},
   { lat:23.947986, lng:38.257037},
   { lat:23.937317, lng:38.25017},
   { lat:23.947986, lng:38.228198},
   { lat:23.953633, lng:38.213778},
   { lat:23.976849, lng:38.185626},
   { lat:24.016682, lng:38.145114},
   { lat:24.049607, lng:38.107349},
   { lat:24.060501, lng:38.083745},
   { lat:24.059482, lng:38.062201},
   { lat:24.069905, lng:38.039456},
   { lat:24.081111, lng:38.019973},
   { lat:24.118562, lng:37.975513}]],
   cities: [
   {
   name: "Yanbu",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Semery Hayaa",
   arName: "سميري الهيئه الملكيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharm Yan",
   arName: "شرم / المنح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlSharbatly Yan",
   arName: "الشربتلي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisal Yan",
   arName: "فيصل الهيئه الملكيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "MASHHD",
   arName: "مشهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naseem",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Radwa",
   arName: "رضوي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bathnah",
   arName: "البثنة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bohaira",
   arName: "بحيره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jabriyah",
   arName: "جابريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hawra",
   arName: "حوراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalid",
   arName: "خالد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dasimah",
   arName: "الداسمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zuhur",
   arName: "زهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suwayq",
   arName: "سويق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shatee Yan",
   arName: "شاطئ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shuruq",
   arName: "شروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alsareef",
   arName: "الصريف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Saharij",
   arName: "صهاريج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah Yun",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nawah",
   arName: "نواة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiah al arabiyah",
   arName: "جامعه العربيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Samery Balad",
   arName: "سميري بلد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sobh",
   arName: "صبح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sarif",
   arName: "صريف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Azizya Yun",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Oyoon",
   arName: "عيون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Majd",
   arName: "مجد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah Yun",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharbatli",
   arName: "شربتلى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Emara",
   arName: "اماره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL-shati",
   arName: "الشاطئ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mashrafa",
   arName: "مشرفه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakheel",
   arName: "نخيل ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khurik",
   arName: "الخريق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bandar",
   arName: "بندر ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Waha",
   arName: "واحه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taawn",
   arName: "تعاون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mubarak",
   arName: "مباركية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yaqut",
   arName: "ياقوت",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sur",
   arName: "سور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Haras al watani",
   arName: "حرس الوطني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayyan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fahd",
   arName: "فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Lulu",
   arName: "لؤلؤ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mushayrif",
   arName: "مشيريف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Light industrial park",
   arName: "منطقة الصناعات الخفيفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Asalah",
   arName: "اصالة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sawari",
   arName: "صواري",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Falah",
   arName: "فالح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Murjan",
   arName: "مرجان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yanbu domestic airport",
   arName: "مطار ينبع الاقليمي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Adwa",
   arName: "اضواء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bahjah",
   arName: "بهجة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shajwa",
   arName: "شجوى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Maharah",
   arName: "محارة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadaiq",
   arName: "حدائق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial area",
   arName: "منطقة صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Diyar",
   arName: "ديار",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "KS4",
  initialGeocode: { lat:28.451364, lang:36.67398} ,
   polygons: [
  [
   { lat:28.451364, lng:36.67398},
   { lat:28.390975, lng:36.692519},
   { lat:28.3653, lng:36.68222},
   { lat:28.342639, lng:36.658531},
   { lat:28.329343, lng:36.607719},
   { lat:28.314837, lng:36.56446},
   { lat:28.303653, lng:36.488242},
   { lat:28.321184, lng:36.453223},
   { lat:28.338711, lng:36.424385},
   { lat:28.365602, lng:36.402412},
   { lat:28.401546, lng:36.384559},
   { lat:28.451666, lng:36.416831},
   { lat:28.469474, lng:36.439491},
   { lat:28.486977, lng:36.46215},
   { lat:28.50327, lng:36.491332},
   { lat:28.517148, lng:36.526352},
   { lat:28.524087, lng:36.553474}]],
   cities: [
   {
   name: "Tabuk",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Shefa Tub",
   arName: "شفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa Tub",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Universty",
   arName: "جامعه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan Tub",
   arName: " اسكان قوي الامن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Arjaan Tub",
   arName: "عرجان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Old Azizya Tub",
   arName: "عزيزيه القديمه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "SALHEYA 2",
   arName: "صالحيه 2",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mroug2",
   arName: "المروج 2",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ashrafeya",
   arName: "اشرفيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial Tub",
   arName: "صناعيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "WORUOD 2",
   arName: "ورود 2",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salehya Tub",
   arName: "صالحيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bawady",
   arName: "بوادى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ulaya",
   arName: "عليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Merooj Tub",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Worood",
   arName: "ورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Karim",
   arName: "كريم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmook Tub",
   arName: "يرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Saada Tub",
   arName: "سعاده",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamraa Tub",
   arName: "حمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Raqee Tub",
   arName: "راقي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Military City Tub",
   arName: "مدينه العسكريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu sabaa",
   arName: "ابو سبعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Basateen",
   arName: "بساتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khaledya",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawda Tub",
   arName: "روضه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam Tub",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulimaniyah",
   arName: "سليمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shaikh",
   arName: "شيخ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faysalia Alganobya",
   arName: "فيصليه الجنوبيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faysalia Alshmalya",
   arName: "فيصليه الشماليه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alqadesya 1",
   arName: "القادسيه 1",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alqadesya 2",
   arName: "القادسيه 2",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Maseef",
   arName: "مصيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah Tub",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahrajan",
   arName: "مهرجان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah Tub",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hudaybah",
   arName: "هضيبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Petromin",
   arName: "بترومين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rahil",
   arName: "رحيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayyan Tub",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sultanah",
   arName: "سلطانة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rajihi subdivision",
   arName: "مخطط الراجحي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mroug al amir",
   arName: "مروج الامير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Air Base Tub",
   arName: "قاعده الجويه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ruwayiyat",
   arName: "رويعيات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah al qadimah",
   arName: "عزيزية القديمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salman",
   arName: "سلمان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Munshiyah al jadidah",
   arName: "منشية الجديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Munshiyah al qadimah",
   arName: "منشية القديمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muruj b",
   arName: "مروج ب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Awayshah",
   arName: "عوايشة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah al jadidah",
   arName: "عزيزية الجديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabiyah",
   arName: "رابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "General security force housing",
   arName: "اسكان قوى الامن العام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadisiyah",
   arName: "قادسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Agricultura area",
   arName: "منطقة الزراعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Airport",
   arName: "مطار",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "KS5",
  initialGeocode: { lat:27.04506, lang:49.270934} ,
   polygons: [
  [
   { lat:27.04506, lng:49.270934},
   { lat:26.995972, lng:49.328784},
   { lat:26.936226, lng:49.403027},
   { lat:26.916138, lng:49.425042},
   { lat:26.801338, lng:49.385582},
   { lat:26.784234, lng:49.405516},
   { lat:26.779023, lng:49.431608},
   { lat:26.839847, lng:49.48036},
   { lat:26.766685, lng:49.561728},
   { lat:26.786685, lng:49.59297},
   { lat:26.79496, lng:49.626357},
   { lat:26.76339, lng:49.672105},
   { lat:26.704518, lng:49.746435},
   { lat:26.857153, lng:49.878957},
   { lat:26.90803, lng:49.773063},
   { lat:26.997436, lng:49.684336},
   { lat:27.030557, lng:49.697425},
   { lat:27.035627, lng:49.691331},
   { lat:27.034146, lng:49.676396},
   { lat:27.034853, lng:49.66026},
   { lat:27.057061, lng:49.625929},
   { lat:27.071507, lng:49.690474},
   { lat:27.072963, lng:49.707517},
   { lat:27.099638, lng:49.70396},
   { lat:27.099494, lng:49.686891},
   { lat:27.072309, lng:49.633526},
   { lat:27.076627, lng:49.615716},
   { lat:27.154323, lng:49.5722},
   { lat:27.171657, lng:49.592713},
   { lat:27.18124, lng:49.58546},
   { lat:27.185019, lng:49.577178},
   { lat:27.165167, lng:49.554603},
   { lat:27.18395, lng:49.54366},
   { lat:27.18647, lng:49.537158},
   { lat:27.189104, lng:49.52481},
   { lat:27.173929, lng:49.505074},
   { lat:27.159821, lng:49.490659},
   { lat:27.148747, lng:49.441092},
   { lat:27.123618, lng:49.386718},
   { lat:27.134923, lng:49.262694},
   { lat:27.090612, lng:49.218062}]],
   cities: [
   {
   name: "Jubail",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Jubil Industry",
   arName: "الجبيل الصناعيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra",
   arName: "حمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alkhaledia",
   arName: "الخالديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jubile Elbalad",
   arName: "جبيل البلد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rastanura",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Altahlia",
   arName: "التحليه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jalmudah",
   arName: "جلمودة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sudayr",
   arName: "سدير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Firdaws",
   arName: "فردوس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Lualu",
   arName: "لؤلؤ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Najd",
   arName: "نجد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ahsa",
   arName: "احساء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial area",
   arName: "منطقة صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quds",
   arName: "قدس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gharb al mazari subdivision",
   arName: "مخطط غرب المزارع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yanbu",
   arName: "ينبع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hijaz",
   arName: "حجاز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Darin",
   arName: "دارين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abdul latif jameel subdivision",
   arName: "مخطط عبداللطيف جميل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Makkah",
   arName: "مكة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Second industrial area",
   arName: "منطقة الصناعية الثانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Urayfi area",
   arName: "عريفي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faruq",
   arName: "فاروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dakhal al mahdud subdivision",
   arName: "مخطط الدخل المحدود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Huwaylat",
   arName: "حويلات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalij",
   arName: "خليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "First industrial area",
   arName: "منطقة الصناعية الاولى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Third industrial area",
   arName: "منطقة الصناعية الثالثة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shati",
   arName: "شاطئ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sarawat subdivision",
   arName: "مخطط السروات",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "KS7",
  initialGeocode: { lat:28.47418, lang:46.042662} ,
   polygons: [
  [
   { lat:28.47418, lng:46.042662},
   { lat:28.503148, lng:45.989789},
   { lat:28.4947, lng:45.934171},
   { lat:28.4609, lng:45.895719},
   { lat:28.43977, lng:45.884045},
   { lat:28.41018, lng:45.87924},
   { lat:28.381791, lng:45.876493},
   { lat:28.353394, lng:45.897093},
   { lat:28.336473, lng:45.906019},
   { lat:28.322874, lng:45.931768},
   { lat:28.315318, lng:45.957517},
   { lat:28.302623, lng:45.991162},
   { lat:28.264529, lng:46.142911},
   { lat:28.317131, lng:46.172437},
   { lat:28.357019, lng:46.197843},
   { lat:28.436147, lng:46.09004},
   { lat:28.456674, lng:46.063261}]],
   cities: [
   {
   name: "Hafr Al Batin",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Batin",
   arName: "الباطن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alzipeya",
   arName: "الذيبيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayan",
   arName: "الريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "الصناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlMuhammadiyah",
   arName: "المحمديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah Hafr",
   arName: "النهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abo Mousa Alashary",
   arName: "أبوموسى الأشعرى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "الاسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Albaladeya",
   arName: "البلديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Talal",
   arName: "التلال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiah",
   arName: "الجامعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah",
   arName: "الخالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "rabiyah",
   arName: "الرابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Raid",
   arName: "الرائد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alrabowa",
   arName: "الربوه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "الربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alrawda Hfr",
   arName: "الروضه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alsolaymanya",
   arName: "السليمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shifa",
   arName: "الشفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alsafa",
   arName: "الصفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alazizya Hfr",
   arName: "العزيزيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fulaij",
   arName: "فليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "الفيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alfaysliya",
   arName: "الفيصليه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alqausomah",
   arName: "القيصومه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almrog",
   arName: "المروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almasef",
   arName: "المصيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manar",
   arName: "المنار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlNayfiyah",
   arName: "النايفيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "النخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah Hfr",
   arName: "النزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahah",
   arName: "الواحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi",
   arName: "الوادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wurud",
   arName: "الورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmuk",
   arName: "اليرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghirnatah",
   arName: "غرناطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurtubah",
   arName: "قرطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King Khaled Milierty",
   arName: "مدينه الملك خالد العسكرية",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Hafar Al Batin",
   arName: "حفر الباطن",
   googleName: "",
   districts: [
   {
   name: "Batin",
   arName: "باطن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulimaniyah",
   arName: "سليمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shifa",
   arName: "شفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safaa",
   arName: "صفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qausomah",
   arName: "قيصومه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmuk",
   arName: "يرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahah",
   arName: "واحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu musa al ashari",
   arName: "أبوموسى الأشعرى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baladiyah",
   arName: "بلديه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Raid",
   arName: "رائد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghirnatah",
   arName: "غرناطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masif",
   arName: "مصيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fulaij",
   arName: "فليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurtubah",
   arName: "قرطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zipeya",
   arName: "ذيبيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manar",
   arName: "منار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wurud",
   arName: "ورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nayfiyah",
   arName: "نايفيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "rabiyah",
   arName: "رابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi",
   arName: "وادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Talal",
   arName: "تلال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiah",
   arName: "جامعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah",
   arName: "محمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muruj",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "KS8",
  initialGeocode: { lat:30.240851, lang:40.331711} ,
   polygons: [
  [
   { lat:30.240851, lng:40.331711},
   { lat:30.208516, lng:40.260643},
   { lat:30.140549, lng:40.196442},
   { lat:30.114418, lng:40.168977},
   { lat:30.069265, lng:40.133957},
   { lat:30.001499, lng:40.061173},
   { lat:29.925223, lng:39.980149},
   { lat:29.811495, lng:39.823593},
   { lat:29.762629, lng:39.861359},
   { lat:29.754879, lng:39.921097},
   { lat:29.77455, lng:40.055679},
   { lat:29.815069, lng:40.166916},
   { lat:29.853191, lng:40.241073},
   { lat:29.87241, lng:40.28502},
   { lat:29.897415, lng:40.326561},
   { lat:29.943834, lng:40.369476},
   { lat:29.995584, lng:40.419258},
   { lat:30.074645, lng:40.441573},
   { lat:30.144127, lng:40.426811}]],
   cities: [
   {
   name: "Skaka",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Shalhub",
   arName: "الشلهوب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tuwair",
   arName: "الطوير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah Skaka",
   arName: "الربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawabi",
   arName: "الروابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suq",
   arName: "السوق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shuaib",
   arName: "الشعيب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dil",
   arName: "الضلع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah Skaka",
   arName: "العزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Laqait",
   arName: "اللقائط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah a",
   arName: "المحمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Matr",
   arName: "المطر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muaqilah",
   arName: "المعاقلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Southwestern qara",
   arName: "جنوب غرب قارا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "North qara",
   arName: "شمال قارا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alradefa",
   arName: "الرديفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Allakaet",
   arName: "اللقائط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dumah al jandal",
   arName: "دومة الجندل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zaloom",
   arName: "زلوم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sweer",
   arName: "صوير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadeep",
   arName: "هديب",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Dawmat Al Jandal",
   arName: "دومة الجندل",
   googleName: "",
   districts: [
   {
   name: "Buhayrat",
   arName: "بحيرات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zirae",
   arName: "زراعي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safah al shimali",
   arName: "صفاه الشمالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghadir al janubi",
   arName: "غدير الجنوبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khazma",
   arName: "خذماء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Edari",
   arName: "اداري",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Husiba",
   arName: "حصيباء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd al shimali",
   arName: "ملك فهد الشمالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi",
   arName: "وادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tahkim",
   arName: "تحكيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suq al qadim",
   arName: "سوق القديم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faruq",
   arName: "فاروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jaraawi",
   arName: "جرعاوي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Azizyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khaldiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safah al janubi",
   arName: "صفاه الجنوبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qaratin",
   arName: "قراطين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd al janubi",
   arName: "ملك فهد الجنوبي",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Sakaka",
   arName: "سكاكا",
   googleName: "",
   districts: [
   {
   name: "Qara",
   arName: "قارا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Radefa",
   arName: "رديفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Laqait",
   arName: "لقائط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "North of al laqait cemetery",
   arName: "شمال مقبرة اللقائط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulaimaniyyah",
   arName: "مخطط السليمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Minah al khassah",
   arName: "منح الخاصة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah subdivision",
   arName: "مخطط الصناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tuwair",
   arName: "طوير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Urubah subdivision",
   arName: "مخطط العروبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dil",
   arName: "ضلع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kassab al muwayshir",
   arName: "مخطط كساب المويشير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah b",
   arName: "محمدية ب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah d",
   arName: "محمدية د",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah e",
   arName: "محمدية ه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Siddiq subdivsion",
   arName: "مخطط الصديق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Southwestern qara",
   arName: "جنوب غرب قارا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suq",
   arName: "سوق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sweer",
   arName: "صوير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amal subdivsion",
   arName: "مخطط الامل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah g",
   arName: "محمدية م",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muruj",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zaloom",
   arName: "زلوم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyyah subdivision",
   arName: "مخطط الفيصلي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Minah",
   arName: "مخطط المنح الخاصة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah f",
   arName: "محمدية و",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah h",
   arName: "محمدية ن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah Skaka",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawabi",
   arName: "روابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zuhur subdivision",
   arName: "مخطط الزهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadeep",
   arName: "هديب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Karim durzi subdivsion",
   arName: "مخطط كريم الدرزي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muaqilah",
   arName: "معاقلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "North qara",
   arName: "شمال قارا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shuaib",
   arName: "شعيب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "West regional road subdivision",
   arName: "مخطط غرب الاقليمي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Matar",
   arName: "مطار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah a",
   arName: "محمدية ا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nayf durzi",
   arName: "مخطط نيف الدرزي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Old subdivision",
   arName: "مخطط القديم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shalhub",
   arName: "شلهوب",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "R10",
  initialGeocode: { lat:27.731445, lang:41.854162} ,
   polygons: [
  [
   { lat:27.731445, lng:41.854162},
   { lat:27.890566, lng:41.834936},
   { lat:27.894208, lng:41.764899},
   { lat:27.885711, lng:41.703101},
   { lat:27.752108, lng:41.641303},
   { lat:27.5587, lng:41.560278},
   { lat:27.49781, lng:41.543799},
   { lat:27.422259, lng:41.549293},
   { lat:27.352755, lng:41.617957},
   { lat:27.383245, lng:41.74018},
   { lat:27.43201, lng:41.781378},
   { lat:27.467353, lng:41.793739},
   { lat:27.634158, lng:41.850043}]],
   cities: [
   {
   name: "Hail",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Qaed",
   arName: "قاعد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Soufn",
   arName: "سفن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qana",
   arName: "قنا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naqben",
   arName: "نقبين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aja",
   arName: "اجا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Buhayrah",
   arName: "بحيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuraymi",
   arName: "خريمي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuzama Hail",
   arName: "خزامى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khamashiyah",
   arName: "الخماشية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zabara",
   arName: "زبارة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahra",
   arName: "زهراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Samra",
   arName: "سمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suwayfilah",
   arName: "سويفلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "el-azizeia",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahattah",
   arName: "محطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muzaabar",
   arName: "مزعبر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masif Hail",
   arName: "مصيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Airport",
   arName: "مطار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah ash sharqi",
   arName: "منتزه الشرقي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah al gharbi",
   arName: "منتزه الغربي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial area",
   arName: "منطقة صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al-noqra",
   arName: "النقرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Waseta",
   arName: "وسيطاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Barzan",
   arName: "برزان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadri al bilad",
   arName: "حدري البلاد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Samah",
   arName: "سماح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sababah",
   arName: "صبابة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Labdah",
   arName: "لبدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mughaydah",
   arName: "مغيضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badnah",
   arName: "بدنة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marafq",
   arName: "مريفق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hail regional airport",
   arName: "مطار حائل الاقليمي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasiyah",
   arName: "نسية",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "Qassim Region",
  initialGeocode: { lat:26.53816574, lang:43.63357544} ,
   polygons: [
  [
   { lat:26.53816574, lng:43.63357544},
   { lat:26.48778221, lng:43.52645874},
   { lat:26.39679021, lng:43.45367432},
   { lat:26.28725881, lng:43.42346191},
   { lat:26.10858718, lng:43.3795166},
   { lat:25.94199188, lng:43.3644104},
   { lat:25.80854567, lng:43.38638306},
   { lat:25.75908293, lng:43.48388672},
   { lat:25.74918791, lng:43.64456177},
   { lat:25.78999956, lng:43.85742188},
   { lat:25.80360033, lng:44.07714844},
   { lat:25.83079717, lng:44.18701172},
   { lat:25.84933689, lng:44.27352905},
   { lat:25.91605582, lng:44.36141968},
   { lat:26.03087214, lng:44.39712524},
   { lat:26.15173991, lng:44.30236816},
   { lat:26.2269108, lng:44.27490234},
   { lat:26.34265281, lng:44.2048645},
   { lat:26.41155055, lng:44.10461426},
   { lat:26.49638584, lng:43.96865845},
   { lat:26.52710783, lng:43.79425049},
   { lat:26.53816574, lng:43.63357544}]],
   cities: [
   {
   name: "Buraydah",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Al-Bosor",
   arName: "البصر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almerooj Bur",
   arName: "المروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alkhobaraa",
   arName: "الخبراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alsada",
   arName: "الساده",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industry 2 Bur",
   arName: "صناعيه الثانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almontazah South - Alyarmook",
   arName: "المنتزه الجنوبى - اليرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Albekrya",
   arName: "البيكريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al-Shamasya",
   arName: "الشماسيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Oyoon Algawaa",
   arName: "عيون الجواء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Albadaa",
   arName: "البدائع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Riyad Alkhobaraa",
   arName: "رياض الخبراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almanar Bur",
   arName: "المنار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almontazah",
   arName: "المنتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alzarqaa",
   arName: "الزرقاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alhomr",
   arName: "الحمر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alrawda Bur",
   arName: "الروضه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aliskan",
   arName: "الإسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alnahda",
   arName: "النهضه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alofooq",
   arName: "الأفق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alakhdar",
   arName: "الحى الأخضر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlKhozama Bur",
   arName: "الخزامى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alfayziya",
   arName: "الفايزيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alsafraa",
   arName: "الصفراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alshamas",
   arName: "الشماس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alqadesya",
   arName: "القادسيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alnaizya - Alnoor",
   arName: "النازيه - النور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alrabwa Bur",
   arName: "الربوه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alnaka",
   arName: "النقع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alrabya",
   arName: "الرابيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alfakhrya Bur",
   arName: "الفاخريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alsalehya Bur",
   arName: "الصالحيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al-Ras",
   arName: "الرس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khub ath thanyan",
   arName: "خب الثنيان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalij",
   arName: "خليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabya",
   arName: "رابيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawabi",
   arName: "روابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salmiya",
   arName: "سالمية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shafaq",
   arName: "شفق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dahi",
   arName: "ضاحي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Montazah",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wusayta",
   arName: "وسيطا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Basatin",
   arName: "بساتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jardah",
   arName: "جردة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Homr",
   arName: "حمر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khozama Bur",
   arName: "خزامى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khudar",
   arName: "خضر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rafiah",
   arName: "رفيعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawaq",
   arName: "رواق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zarqaa",
   arName: "زرقاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shorouq",
   arName: "شروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safraa",
   arName: "صفراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qaa al barid",
   arName: "قاع البارد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Lusayb",
   arName: "لسيب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marqab",
   arName: "مرقب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naka",
   arName: "نقع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hilal",
   arName: "هلال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Akhdar",
   arName: "أخضر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawda Bur",
   arName: "روضه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ujaybah",
   arName: "عجيبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qusayah",
   arName: "قصيعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wurud",
   arName: "ورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "إسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taalim",
   arName: "تعليم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khabib",
   arName: "خبيب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sada",
   arName: "ساده",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shamas",
   arName: "شماس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadesya",
   arName: "قادسيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mawta",
   arName: "موطا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naizya - Alnoor",
   arName: "نازيه - النور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bosor",
   arName: "بصر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khub al buraydi",
   arName: "خب البريدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uraymdi",
   arName: "عريمضي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marwah",
   arName: "مروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahda",
   arName: "نهضه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayyan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shiqah",
   arName: "شقة ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qhadir",
   arName: "غدير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faruq",
   arName: "فاروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fayziya",
   arName: "فايزيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manar Bur",
   arName: "منار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazm",
   arName: "حزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fakhrya Bur",
   arName: "فاخريه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nafl",
   arName: "نفل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nur",
   arName: "نور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ufuq",
   arName: "افق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jazirah",
   arName: "جزيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Janub",
   arName: "جنوب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwa Bur",
   arName: "ربوه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Directions",
   arName: "رحاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rimal",
   arName: "رمال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salehya Bur",
   arName: "صالحيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Merooj Bur",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muraydisiyyah",
   arName: "مريديسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmouk",
   arName: "يرموك",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Al Badayea",
   arName: "البدائع",
   googleName: "",
   districts: [
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Basatin",
   arName: "بساتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ramat",
   arName: "رامات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shayrami",
   arName: "شيرمي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wusta",
   arName: "وسطى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ulayya",
   arName: "عليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Asfar",
   arName: "اصفر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rimmah",
   arName: "رمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taalim",
   arName: "تعليم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ubaylah",
   arName: "عبيلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mustashfa",
   arName: "مستشفى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm thalaah",
   arName: "ام ثلعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uthmaniyah",
   arName: "عثمانية",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Al Mithnab",
   arName: "المذنب",
   googleName: "",
   districts: [
   {
   name: "Jadidah",
   arName: "جديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safra",
   arName: "صفراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm ushayrah",
   arName: "ام عشيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuzami",
   arName: "خزامي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salhiyah",
   arName: "صالحية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wurud",
   arName: "ورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bustan",
   arName: "بستان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qifayfah",
   arName: "قفيفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurtubah",
   arName: "قرطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sina'iyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazm",
   arName: "حزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khaldiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadisiyah",
   arName: "قادسية",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Ar Rass",
   arName: "الرس",
   googleName: "",
   districts: [
   {
   name: "Saadah",
   arName: "سعادة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi ar rimma",
   arName: "وادي الرمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmuk",
   arName: "يرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hutah",
   arName: "حوطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jandal",
   arName: "جندل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King faisal",
   arName: "ملك فيصل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Talim",
   arName: "تعليم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdulaziz",
   arName: "ملك عبدالعزيز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadisiyah",
   arName: "قادسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ihtifalat",
   arName: "احتفالات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qasr ibn aqil",
   arName: "قصر بن عقيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazm",
   arName: "حزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Airport",
   arName: "مطار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King khalid",
   arName: "ملك خالد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shifa",
   arName: "شفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahrah",
   arName: "زهرة",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Bukayriyah",
   arName: "البكيرية",
   googleName: "",
   districts: [
   {
   name: "Balad al qadim",
   arName: "بلد القديم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baida",
   arName: "بيدا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadisiyah",
   arName: "قادسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fahd",
   arName: "فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ibn khaldun",
   arName: "ابن خلدون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khaldiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah planning",
   arName: "مخطط المحمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Halaliyah",
   arName: "هلالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahrah",
   arName: "زهرة",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Riyadh Al Khabra",
   arName: "رياض الخبراء",
   googleName: "",
   districts: [
   {
   name: "Khabra",
   arName: "خبراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sina'iyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Afrah",
   arName: "افراح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khazzan",
   arName: "خزان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quds",
   arName: "قدس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rafiah",
   arName: "رفيعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayyan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taawun",
   arName: "تعاون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazm",
   arName: "حزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khabra al qadeema",
   arName: "خبراء القديمة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahabin al janubi",
   arName: "سحابين الجنوبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zubaiydah",
   arName: "زبيدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Silah",
   arName: "سلاح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Majd",
   arName: "مجد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadisiyah",
   arName: "قادسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabii",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahabin ash shamali",
   arName: "سحابين الشمالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yasmin",
   arName: "ياسمين",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Shimasiyah",
   arName: "الشماسية",
   googleName: "",
   districts: [
   {
   name: "Faruq",
   arName: "فاروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Midan",
   arName: "ميدان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadisiyah",
   arName: "قادسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Barzah",
   arName: "برزة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bilad",
   arName: "بلاد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bada",
   arName: "بدع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jal",
   arName: "جال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mathaf",
   arName: "متحف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shifa",
   arName: "شفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wasit",
   arName: "واسط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mutawakil",
   arName: "متوكل",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Unayzah",
   arName: "عنيزة",
   googleName: "",
   districts: [
   {
   name: "Merooj Unz",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah",
   arName: "منتزه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qusor",
   arName: "قصور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawda Unz",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharqya",
   arName: "شرقية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulemaniya",
   arName: "سليمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thaqafah",
   arName: "ثقافة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yamama",
   arName: "يمامه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dahbi",
   arName: "ذهبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Damshiah",
   arName: "دمشية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jaldah",
   arName: "جلده",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khaleeg",
   arName: "خليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nozha",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rahmaniyah",
   arName: "رحمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rimal",
   arName: "رمال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tahliyah",
   arName: "تحلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahir",
   arName: "زاهر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadaa",
   arName: "هداء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra",
   arName: "حمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industry 1",
   arName: "منطقه الصناعيه الأولى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah",
   arName: "محمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mushraf",
   arName: "مشرف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qirawan",
   arName: "قيروان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawghani",
   arName: "روغاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamai",
   arName: "جامعي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marwah",
   arName: "مروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shbiliyah",
   arName: "اشبيلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahra",
   arName: "زهراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ashrafia",
   arName: "اشرافيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badr",
   arName: "بدر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hteen",
   arName: "حطين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kadesya",
   arName: "قادسيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khozama Unz",
   arName: "خزامى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Narjis",
   arName: "نرجس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurtubah",
   arName: "قرطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Riyadi",
   arName: "رياضى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ulia",
   arName: "عليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bustan",
   arName: "بستان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghirnatah",
   arName: "غرناطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "اسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nada",
   arName: "ندى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi",
   arName: "وادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Warehouse",
   arName: "مستودعات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dawhah",
   arName: "دوحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Down town",
   arName: "وسط المدينة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ebn Eid",
   arName: "ابن عيد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faida",
   arName: "فيضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fakamah",
   arName: "فخامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Malik Fahad",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badiea",
   arName: "بديعه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hadiqah",
   arName: "حديقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jazirah",
   arName: "جزيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawabi",
   arName: "روابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wafaa",
   arName: "وفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmuk",
   arName: "يرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hafira",
   arName: "حفيره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazm",
   arName: "حزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industry 2 Unz",
   arName: "منطقه الصناعيه الثانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jawharah",
   arName: "جوهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khldia",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Malik Khalid",
   arName: "ملك خالد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safraa",
   arName: "صفراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salehya Unz",
   arName: "صالحيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shifa",
   arName: "شفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Waha",
   arName: "واحه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fakhariya",
   arName: "فاخرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manar Unz",
   arName: "منار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masif",
   arName: "مصيف",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Uyun Al Jawa",
   arName: "عيون الجواء",
   googleName: "",
   districts: [
   {
   name: "Talim",
   arName: "تعليم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzha",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jardah",
   arName: "جردة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Montazah",
   arName: "منتزة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khazzan",
   arName: "خزان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marqab",
   arName: "مرقب",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "J16",
  initialGeocode: { lat:20.024215, lang:41.486723} ,
   polygons: [
  [
   { lat:20.024215, lng:41.486723},
   { lat:20.038246, lng:41.466124},
   { lat:20.049776, lng:41.443893},
   { lat:20.015505, lng:41.423036},
   { lat:19.991148, lng:41.43265},
   { lat:19.970014, lng:41.454622},
   { lat:19.816263, lng:41.55337},
   { lat:19.831686, lng:41.641475},
   { lat:19.959042, lng:41.595212}]],
   cities: [
   {
   name: "Bahah",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Al gawfae",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al ghashmara",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al makatem",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al hamda",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al mahamed",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al hamda Al allia",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al marwa",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al rahwa",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "qarn zaby",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "rghdan",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rouboe al safah",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "shabrqaa",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "wady sadr",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al sawad",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Makhwah",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "aAl khwatem",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al atarda",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bany kabier",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bany farwaa",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baljurashi",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bany mashhour",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "dar al gabal",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bidan",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "gadraa",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al saghra",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al mousa",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al qura",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al soror",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al srawien",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alasra",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alqara",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Alaqiq",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "baida",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "balalla",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bahra",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baha",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al halah",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al rona",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "zey ain",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al asalh",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bany zobian",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al robian",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al zafier",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al hasn",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al ghashamraa",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al qadaha",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "qamada",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "shahba",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "mashina",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bany hassan",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bany sar",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "khadah",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "gafn",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "al shkran",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Almndaq",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Atawlah",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "balkhazmr",
   arName: "",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Baha",
   arName: "الباحة",
   googleName: "",
   districts: [
   {
   name: "Atawlah",
   arName: "أتولة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baljurashi",
   arName: "بلجرشي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bany mashhour",
   arName: "بني مشهور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "baida",
   arName: "بيدا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "dar al gabal",
   arName: "دار الجبل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Srawien",
   arName: "سرويين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shifa",
   arName: "شفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qora",
   arName: "قرى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mondaq",
   arName: "مندق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C2",
   arName: "ج2",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C5",
   arName: "ج5",
   googleName: "",
   postalCode: "",
   },
   {
   name: "gadraa",
   arName: "جدارا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "zey ain",
   arName: "زي عين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "shabrqaa",
   arName: "شبرقع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shahbah",
   arName: "شهبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Soghra",
   arName: "صغرى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tafeer",
   arName: "طفير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "balalla",
   arName: "بلالا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "balkhazmr",
   arName: "بلخزمر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bidan",
   arName: "بيدان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C1",
   arName: "ج1",
   googleName: "",
   postalCode: "",
   },
   {
   name: "C4",
   arName: "ج4",
   googleName: "",
   postalCode: "",
   },
   {
   name: "khadah",
   arName: "خدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aqiq",
   arName: "عقيق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "gafn",
   arName: "غافن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadaha",
   arName: "قضاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "mashina",
   arName: "مشينا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Azhar",
   arName: "ازهر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bany farwaa",
   arName: "بني فروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gawfae",
   arName: "جوفى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Halah",
   arName: "حلاه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "rghdan",
   arName: "رغدان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dabab",
   arName: "ضباب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghashmara",
   arName: "غشمره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muratafaah",
   arName: "مرتفعات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mousa",
   arName: "موسى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Baher",
   arName: "باهر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamda",
   arName: "حمدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamda Al allia",
   arName: "حمدة العالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khawatem",
   arName: "خواتيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Robian",
   arName: "روبيان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rona",
   arName: "رونا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shorouk",
   arName: "شروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shkran",
   arName: "شكران",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marwa",
   arName: "مروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Makatem",
   arName: "مكاتم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naseem",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Asalah",
   arName: "اصالة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Buraydah",
   arName: "بريدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bany zobian",
   arName: "بني زوبيان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hijaz",
   arName: "حجاز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Soror",
   arName: "سرور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Atarda",
   arName: "عطاردة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qura",
   arName: "قرى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "qarn zaby",
   arName: "قرن ظبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "wady sadr",
   arName: "وادي الصدر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Asraa",
   arName: "اسراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bany sar",
   arName: "بني سار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "bany kabier",
   arName: "بني كابير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hasan",
   arName: "حسن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rahwa",
   arName: "رهوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rouboe al safah",
   arName: "روب الصفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdha",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salamah",
   arName: "سلامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sawad",
   arName: "سواد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahamed",
   arName: "محمد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Makhwah",
   arName: "مخواة",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "RS9",
  initialGeocode: { lat:24.189364, lang:47.428187} ,
   polygons: [
  [
   { lat:24.189364, lng:47.428187},
   { lat:24.23727, lng:47.401407},
   { lat:24.281092, lng:47.358836},
   { lat:24.313948, lng:47.289484},
   { lat:24.271389, lng:47.183742},
   { lat:24.213163, lng:47.133616},
   { lat:24.140185, lng:47.130182},
   { lat:24.080644, lng:47.140482},
   { lat:24.059016, lng:47.199878},
   { lat:24.046476, lng:47.248973},
   { lat:24.042398, lng:47.273006},
   { lat:24.047729, lng:47.303561},
   { lat:24.073435, lng:47.340297},
   { lat:24.09663, lng:47.365803},
   { lat:24.108539, lng:47.378849},
   { lat:24.131099, lng:47.412838},
   { lat:24.144885, lng:47.425196},
   { lat:24.157728, lng:47.431377}]],
   cities: [
   {
   name: "Kharj",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Bin Turky",
   arName: "بن تركى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Gharnatah",
   arName: "غرناطه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlSahna Khr",
   arName: "الصحنة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlHayathem Khr",
   arName: "الهياثم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Na'jan",
   arName: "نعجان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlSalmya Khr",
   arName: "السلمية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlHarede Khr",
   arName: "الحريدى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Barakah",
   arName: "البركة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayyan Kharj",
   arName: "الريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah Kharj",
   arName: "العزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah Kharj",
   arName: "النزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al-Esmaeliah",
   arName: "الاسماعيليه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlBedaa Khr",
   arName: "البدع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AlZogheeb Khr",
   arName: "الرغيب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "الاندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badiah",
   arName: "البديعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Burj",
   arName: "البرج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyyah",
   arName: "الخالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuzama Kharj",
   arName: "الخزامى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rashidiyyah",
   arName: "الراشدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rafiah",
   arName: "الرفيعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahir",
   arName: "الزاهر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam Kharj",
   arName: "السلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulaymaniyah",
   arName: "السليمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "الصفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "New industrial area",
   arName: "الصناعية الجديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aliyah",
   arName: "العالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al-Faisaliah",
   arName: "الفيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mishrif",
   arName: "مشرف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah Kharj",
   arName: "المنتزة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mansurah",
   arName: "المنصورة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Munyfiyah",
   arName: "المنيفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasifah",
   arName: "الناصفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah Kharj",
   arName: "النهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yamamah",
   arName: "اليمامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al-Dhubai'ah",
   arName: "الضبيعة",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Ad Dilam",
   arName: "الدلم",
   googleName: "",
   districts: [
   {
   name: "Saydan",
   arName: "سعيدان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sudayr",
   arName: "سدير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Samari",
   arName: "سماري",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dirah",
   arName: "ديرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Adhra",
   arName: "عذراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uwaymiriyah",
   arName: "عويمرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shifa",
   arName: "شفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasariyah",
   arName: "ناصرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahnah",
   arName: "صحنة",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Al-Kharj",
   arName: "الخرج",
   googleName: "",
   districts: [
   {
   name: "Gharnatah",
   arName: "غرناطه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rashidiyyah",
   arName: "راشدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulaymaniyah",
   arName: "سليمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bin Turky",
   arName: "بن تركى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Industrial area",
   arName: "منطقة صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Burj",
   arName: "برج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Na'jan",
   arName: "نعجان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasifah",
   arName: "ناصفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badiah",
   arName: "بديعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Esmaeliah",
   arName: "اسماعيليه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faysaliyyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muntazah",
   arName: "منتزة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yamamah",
   arName: "يمامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aliyah",
   arName: "عالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Munyfiyah",
   arName: "منيفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mansurah",
   arName: "منصورة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rafiah",
   arName: "رفيعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Barakah",
   arName: "بركة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dhubai'ah",
   arName: "ضبيعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuzama",
   arName: "خزامى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayyan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahir",
   arName: "زاهر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mishrif",
   arName: "مشرف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "New industrial area",
   arName: "صناعية الجديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفاء",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "Hassa",
  initialGeocode: { lat:25.655134, lang:49.55454} ,
   polygons: [
  [
   { lat:25.655134, lng:49.55454},
   { lat:25.521055, lng:49.417211},
   { lat:25.357051, lng:49.37583},
   { lat:25.264252, lng:49.492044},
   { lat:25.271083, lng:49.635553},
   { lat:25.28676, lng:49.778719},
   { lat:25.470543, lng:49.790746},
   { lat:25.533757, lng:49.734441}]],
   cities: [
   {
   name: "Hassa",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Al Nasim",
   arName: "النسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mualmin Ash shardyyah",
   arName: "المعلمين الشرقية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rawdah",
   arName: "الروضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Salihiyyah",
   arName: "الصالحية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Iskan Al Aam",
   arName: "الأسكان العام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Kut",
   arName: "الكوت",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shahidin",
   arName: "الشهادين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Bitaliyah",
   arName: "البطالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Killabiyyah",
   arName: "الكلابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Jarn",
   arName: "الجرن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Bandariyah",
   arName: "البندرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Muthalth",
   arName: "المثلث",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sayasib",
   arName: "السياسب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shurufiyyah",
   arName: "الشرفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rashidiyyah",
   arName: "الراشيدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Mizawi",
   arName: "المزاوي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sibat",
   arName: "السباط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Tawthir",
   arName: "التوثير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Uwaymiriyyah",
   arName: "العويمرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rifah Ash shamaliyah",
   arName: "الرفعة الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Tuhaymiyyah",
   arName: "التهيمية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Quryan",
   arName: "القرين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Ruqayqah",
   arName: "الرقيقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Wahah",
   arName: "الواحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rifah Al Janubiyyah",
   arName: "الرفعة الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Khars & Ash shihab",
   arName: "مخطط أحمد الخرس و أحمد الشهاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ar Rajhi",
   arName: "الراجحي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Markaz",
   arName: "المركز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shubah -Village",
   arName: "قرية الشعبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shuqayaq",
   arName: "الشقيق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Zahrah",
   arName: "الزهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Madinah As Sinaiyyah",
   arName: "المدينة الصناعية ( العيون )",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Busayrah",
   arName: "البصيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahasin Baladiyyah",
   arName: "محاسن البلدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Qadisiyyah",
   arName: "القادسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "BaniMan",
   arName: "بني معن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Umran & Umran Area",
   arName: "العمران وقرها",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hulaylah",
   arName: "الحليلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Janub Al Hafuf",
   arName: "هجرة الغويبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Janub Mansubi",
   arName: "جنوب منسوبي التعليم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Salmaniyyah south",
   arName: "السلمانية الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Mazruiyyah",
   arName: "المزروعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al sayhad",
   arName: "الصهيد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jafr",
   arName: "الجفر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shihabiyyah",
   arName: "الشهابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Muhammadiayah",
   arName: "المحمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al yahya",
   arName: "اليحيا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Utban",
   arName: "العتبان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nuzhah",
   arName: "النزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dahiyat Hajr",
   arName: "المحمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hay Al malik Fahd",
   arName: "حي الملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jubayl",
   arName: "الجبيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Dalwah",
   arName: "الدالوه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Miqdam",
   arName: "المقدام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Mutayrfi",
   arName: "المطرف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Qarah",
   arName: "القارة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Hazm",
   arName: "الحزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Umara",
   arName: "الأمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Fadiliyah",
   arName: "الفضول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Aqar",
   arName: "العقير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mualmin Al gharbiyah",
   arName: "المعلمين الغربية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Awadiyyah",
   arName: "العوضية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al NayfiyyH",
   arName: "النايفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khars",
   arName: "الخرس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jamlyin",
   arName: "الجامعيين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AL Mnayzilah",
   arName: "المنيزلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mazru",
   arName: "المزروع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Qudaymat",
   arName: "القديمات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Udhalya",
   arName: "Udhalya",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Andalus",
   arName: "الأندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jashah",
   arName: "الجش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Aziziyyah",
   arName: "العزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khalidiyyah",
   arName: "الخالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Salmaniyyah North",
   arName: "السلمانية الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Naathil",
   arName: "النعاثل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahasin Aramco",
   arName: "محاسن ارامكو",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shubah",
   arName: "حي الشعبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan Al haras Al watani",
   arName: "إسكان الحرس الوطني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Busahbal",
   arName: "بوسحبل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Tarf",
   arName: "الطرف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mansurah",
   arName: "المنصورة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jwatha",
   arName: "جواثا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Wazzaiyyah",
   arName: "الوازية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Julayjilah",
   arName: "جليجلة",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Abqiq",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Abqiq",
   arName: "ابقيق",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Al Oyun",
   arName: "العيون",
   googleName: "",
   districts: [
   {
   name: "Oyun",
   arName: "عيون",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Buqayq",
   arName: "أبقيق",
   googleName: "",
   districts: [
   {
   name: "Nuzha",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghirnatah",
   arName: "غرناطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Matar",
   arName: "مطار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Basatin",
   arName: "بساتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fardus",
   arName: "فردوس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madinah",
   arName: "مدينة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurtubah",
   arName: "قرطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ishbiliyah",
   arName: "أشبيلية",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Hofuf",
   arName: "الهفوف",
   googleName: "",
   districts: [
   {
   name: "Awadiyyah",
   arName: "عوضية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah 1st",
   arName: "فيصلية الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra'a 3rd",
   arName: "حمراء الثالث",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan Al haras Al watani",
   arName: "إسكان الحرس الوطني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salmaniyyah south",
   arName: "سلمانية الجنوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shahidin",
   arName: "شهادين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mansubi al taleem 2nd",
   arName: "منسوبي التعليم الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mansurah",
   arName: "منصورة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marbidia",
   arName: "مربدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mirqab",
   arName: "مرقاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mnayzilah",
   arName: "منيزلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muallimeen",
   arName: "معلمين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasriyah",
   arName: "ناصرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "NayfiyyH",
   arName: "نايفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rafiah",
   arName: "رفيعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah 1st",
   arName: "عزيزية الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Government departments complex 2nd",
   arName: "مجمع الدوائر الحكومية الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hulaylah",
   arName: "حليلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan 1st",
   arName: "إسكان الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jafr",
   arName: "جفر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jawharah",
   arName: "جوهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Killabiyyah",
   arName: "كلابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shubah",
   arName: "حي الشعبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shubah -Village",
   arName: "قرية الشعبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahah",
   arName: "واحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wazzaiyyah",
   arName: "وازية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yahya",
   arName: "يحيا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salihiyyah",
   arName: "صالحية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sibat",
   arName: "سباط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taawun",
   arName: "تعاون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Utban",
   arName: "عتبان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wasitah",
   arName: "وسيطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mansubi al taleem 1st",
   arName: "منسوبي التعليم الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mubarakiyah",
   arName: "مباركية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Munifah",
   arName: "منيفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muthalth",
   arName: "مثلث",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ayn musa",
   arName: "عين موسى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bandariyah",
   arName: "بندرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Busayrah",
   arName: "بصيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fadiliyah",
   arName: "فضول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra'a 2nd",
   arName: "حمراء الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Historical center hafuf",
   arName: "وسط الهفوف التاريخي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamlyin",
   arName: "جامعيين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jubayl",
   arName: "جبيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jwatha",
   arName: "جواثا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Miqdam",
   arName: "مقدام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qudaymat",
   arName: "قديمات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabiyah",
   arName: "رابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mizawi",
   arName: "مزاوي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ruwaida",
   arName: "رويضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Saheed",
   arName: "صهيد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aqar",
   arName: "عقير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "BaniMan",
   arName: "بني معن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hizam al akhdar",
   arName: "حزام الأخضر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King faisal university 2nd",
   arName: "جامعة الملك فيصل الثالث",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shihabiyyah",
   arName: "شهابية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sunaidah",
   arName: "سنيدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suwaidirah",
   arName: "صويدرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thulaythiyah",
   arName: "ثليثية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uwaymiriyyah",
   arName: "عويمرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mazrooa 1st",
   arName: "مزروع الاول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiayah",
   arName: "محمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qarah",
   arName: "قارة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ar Rajhi",
   arName: "راجحي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghawbia agricultural region",
   arName: "منطقة الغويبة الزراعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hada",
   arName: "هدا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hawra",
   arName: "حوراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Janub Al Hafuf",
   arName: "هجرة الغويبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Juba",
   arName: "جوبا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Kut",
   arName: "كوت",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulimaniyah 2nd",
   arName: "سلمانية الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umara",
   arName: "أمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mazrooa 2nd",
   arName: "مزروع الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhandisin",
   arName: "مهندسين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Naathil",
   arName: "نعاثل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Olaya",
   arName: "عليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khars",
   arName: "خرس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King faisal university 3rd",
   arName: "جامعة الملك فيصل الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salmaniyyah North",
   arName: "سلمانية الشمالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulimaniyah 1st",
   arName: "سلمانية الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tawthir",
   arName: "توثير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sports city",
   arName: "مدينة الرياضية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulimaniyah 3rd",
   arName: "سلمانية الثالث",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulimaniyah 4th",
   arName: "سلمانية الرابع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Udhalya",
   arName: "عضليه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Markaz",
   arName: "مركز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ruqayqah",
   arName: "رقيقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ahsa airport",
   arName: "مطار الأحساء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bitaliyah",
   arName: "بطالية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Doha",
   arName: "دوحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah 2nd",
   arName: "فيصلية الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hufayrah",
   arName: "حفيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Julayjilah",
   arName: "جليجلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khudud",
   arName: "خدود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King faisal university 1st",
   arName: "جامعة الملك فيصل الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahra",
   arName: "زهراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sayasib",
   arName: "سياسب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shuqayaq",
   arName: "شقيق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tarf",
   arName: "طرف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tuhaymiyyah",
   arName: "تهيمية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umran & Umran Area",
   arName: "عمران وقراها",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mazruiyyah",
   arName: "مزروعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim",
   arName: "نسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quryan",
   arName: "قرين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah 2nd",
   arName: "عزيزية الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dalwah",
   arName: "دالوه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra'a 1st",
   arName: "حمراء الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jarn",
   arName: "جرن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jashah",
   arName: "جش",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khars & Ash shihab",
   arName: "مخطط أحمد الخرس و أحمد الشهاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ladhah",
   arName: "لذه",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Mubarraz",
   arName: "مبرز",
   googleName: "",
   districts: [
   {
   name: "Abu sahbal 1st",
   arName: "بو سحبل الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "أندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahasin aramco 4th",
   arName: "محاسن ارامكو الرابع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm sab'ah",
   arName: "أم سبعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muqabil",
   arName: "مقابل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mutayrifi al garbi",
   arName: "مطيرفي الغربي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hizam adh dhahabi",
   arName: "حزام الذهبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahasin aramco 2nd",
   arName: "محاسن ارامكو الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shara al junubi",
   arName: "شراع الجنوبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rashidyah 1st",
   arName: "راشدية الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Basatin",
   arName: "بساتين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bustan",
   arName: "بستان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Government departments complex 1st",
   arName: "مجمع الدوائر الحكومية الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yamamah",
   arName: "يمامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmuk",
   arName: "يرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rashidyah 2nd",
   arName: "راشدية الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Abu sahbal 3rd",
   arName: "بو سحبل الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ayn najm",
   arName: "عين نجم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam 1st",
   arName: "سلام الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shara al shimali",
   arName: "شراع الشمالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ayn marjan",
   arName: "عين مرجان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazm al junubi",
   arName: "حزم الجنوبي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Itsalat",
   arName: "إتصالات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahasin 2nd",
   arName: "محاسن الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sanaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shurofiyah",
   arName: "شروفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masudi",
   arName: "مسعودي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rashidyah 3rd",
   arName: "راشدية الثالث",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fath",
   arName: "فتح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahairis",
   arName: "محيرس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahasin aramco 1st",
   arName: "محاسن ارامكو الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahasin aramco 5th",
   arName: "محاسن ارامكو الخامس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suhaimiyah",
   arName: "سحيمية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadisiyah",
   arName: "قادسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazm al shimali",
   arName: "حزم الشمالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Historical center al mubarraz",
   arName: "وسط المبرز التاريخي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahasin 1st",
   arName: "محاسن الأول",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mahasin aramco 3rd",
   arName: "محاسن ارامكو الثالث",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam 2nd",
   arName: "سلام الثاني",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mishrifah",
   arName: "مشرفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisal",
   arName: "فيصل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdullah military city",
   arName: "مدينة الملك عبدالله العسكرية",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "Central",
  initialGeocode: { lat:24.579457, lang:46.507645} ,
   polygons: [
  [
   { lat:24.579457, lng:46.507645},
   { lat:24.516745, lng:46.331542},
   { lat:24.436499, lng:46.166425},
   { lat:24.448874, lng:46.273724},
   { lat:24.453811, lng:46.301968},
   { lat:24.495651, lng:46.342869},
   { lat:24.516862, lng:46.382396},
   { lat:24.513924, lng:46.427897},
   { lat:24.547436, lng:46.503176},
   { lat:24.541705, lng:46.6239},
   { lat:24.491436, lng:46.592852},
   { lat:24.468873, lng:46.582134},
   { lat:24.461619, lng:46.627979},
   { lat:24.491296, lng:46.683939},
   { lat:24.442852, lng:46.744792},
   { lat:24.467213, lng:46.937908},
   { lat:24.469244, lng:46.961254},
   { lat:24.481275, lng:46.97224},
   { lat:24.660607, lng:46.94127},
   { lat:24.677476, lng:46.938084},
   { lat:24.814669, lng:46.91046},
   { lat:24.95949, lng:46.858017},
   { lat:24.967971, lng:46.854584},
   { lat:25.008862, lng:46.799449},
   { lat:25.020062, lng:46.766319},
   { lat:25.015707, lng:46.748466},
   { lat:24.989178, lng:46.672916},
   { lat:24.982643, lng:46.661415},
   { lat:24.88256, lng:46.545926},
   { lat:24.872437, lng:46.537342},
   { lat:24.742171, lng:46.496659},
   { lat:24.643757, lng:46.474514},
   { lat:24.611768, lng:46.474514}]],
   cities: [
   {
   name: "Riyadh",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Al Qadiseyah",
   arName: "القادسيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ishibilyah",
   arName: "إشبيلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rabwah",
   arName: "الربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mohammadiyah",
   arName: "المحمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Zahrah",
   arName: "الزهراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sultanah",
   arName: "سلطانة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Marqab",
   arName: "المرقاب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rafiah",
   arName: "الرفاعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Iskan",
   arName: "الإسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Safa",
   arName: "الصفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Duraihimiyah",
   arName: "الدريهمية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dirab",
   arName: "ديراب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Okaz",
   arName: "عكاظ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Falah",
   arName: "الفلاح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Munsiyah",
   arName: "المنسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Wadi",
   arName: "الوادي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Malqa",
   arName: "الملقى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hittin",
   arName: "حطين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Izdihar",
   arName: "الإزدهار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Malaz",
   arName: "الملز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Warood",
   arName: "الورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khuzama",
   arName: "الخزامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rahmaniya",
   arName: "الرحمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dharat Namar",
   arName: "ظهرة نمار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi Laban",
   arName: "وادي لبن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Futah",
   arName: "الفوطه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AARED",
   arName: "عارض",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALYASMEEN",
   arName: "الياسمين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "BAYAN",
   arName: "بيان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AMANA",
   arName: "أمانه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALREMAL",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "QUSOR",
   arName: "قصور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "AJYAL",
   arName: "اجيال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Princess NOORA university",
   arName: "جامعه الأميره نورا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Driyah",
   arName: "الدرعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Ghadir",
   arName: "الغدير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghurnatah",
   arName: "غرناطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shubra",
   arName: "شبرا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rayyan",
   arName: "الريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Salam",
   arName: "السلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sanaya",
   arName: "الصناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hazm",
   arName: "الحزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uhud",
   arName: "أحد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Muruj",
   arName: "المروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Yarmuk",
   arName: "اليرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Andalus",
   arName: "الأندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Safarat",
   arName: "السفارات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Fakhiryah",
   arName: "الفاخرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rawabi",
   arName: "الروابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sulay",
   arName: "السلي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Faisaliyah",
   arName: "الفيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fawwaz",
   arName: "فواز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shifa",
   arName: "الشفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nahdah",
   arName: "النهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Quds",
   arName: "القدس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dubbat",
   arName: "حي الضباط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dharat Al Badiah",
   arName: "ظهرة البديعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Amal",
   arName: "الأمل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nasriyah",
   arName: "الناصرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mughrizat",
   arName: "المغرزات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Waha",
   arName: "الواحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King Abdullah",
   arName: "ملك عبدالله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salahuddin",
   arName: "صلاح الدين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Zahra",
   arName: "الزهراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nakil",
   arName: "النخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Uraija",
   arName: "العريجه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tuwaiq",
   arName: "طويق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Manar",
   arName: "المنار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nasim",
   arName: "النسيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Manakh",
   arName: "المناخ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Ateqah",
   arName: "العتيقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Faruq",
   arName: "الفاروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Namar",
   arName: "نمار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm Salim",
   arName: "أم سليم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Namudhajiyah",
   arName: "النموذجية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hamra",
   arName: "الحمرا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Madinah As Sanaiyah",
   arName: "المدينة الصناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rabi",
   arName: "الرابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Saadah",
   arName: "السعادة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qurtubah",
   arName: "قرطبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nargis",
   arName: "النرجس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Washim",
   arName: "الوشم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Marwah",
   arName: "المروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King Fahad",
   arName: "الملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Murabba",
   arName: "المربع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rawdah",
   arName: "الروضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iriqah",
   arName: "عريقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nathem",
   arName: "النظيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Suwaidi",
   arName: "السويدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Noor",
   arName: "النور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nada",
   arName: "الندى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sahafah",
   arName: "الصحافة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Raid",
   arName: "الرائد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King saud university",
   arName: "جامعة الملك سعود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jaradiyah",
   arName: "جرادية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khuzama",
   arName: "خزامي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalij",
   arName: "خليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dirah",
   arName: "ديرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahrah",
   arName: "زهرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulai",
   arName: "سلي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suwaidi",
   arName: "سويدي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Suwaidi al gharbi",
   arName: "سويدي الغربي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Irqah",
   arName: "عرقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ilaishah",
   arName: "عليشة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ud",
   arName: "عود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Falah",
   arName: "فالح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quds",
   arName: "قدس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qura",
   arName: "قرى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manfuhah al jadidah",
   arName: "منفوحة الجديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim al gharbi",
   arName: "نسيم الغربي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wahah",
   arName: "واحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmuk",
   arName: "يرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm al hamam ash sharqi",
   arName: "ام الحمام الشرقي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Andalus",
   arName: "أندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badiah",
   arName: "بديعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Duraihimiyah",
   arName: "دريهمية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rimal",
   arName: "رمال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rimayah",
   arName: "رماية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawabi",
   arName: "روابي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safarat",
   arName: "سفارات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shohada",
   arName: "شهداء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Utaiqah",
   arName: "عتيقة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "ALQAIRAWAN",
   arName: "",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aqiq",
   arName: "عقيق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Taawun",
   arName: "التعاون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nuzhah",
   arName: "النزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Janadriyah",
   arName: "الجنادرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khaleej",
   arName: "الخليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Maizaliah",
   arName: "الميزلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Hada",
   arName: "الهدا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm Al Hammam",
   arName: "أم الحمام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Badiah",
   arName: "البادية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Dirah",
   arName: "الديره",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Rimayah",
   arName: "الرمايه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mansourah",
   arName: "المنصورة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Yamamah",
   arName: "اليمامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Nafal",
   arName: "النفل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Masif",
   arName: "المصيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Mursalat",
   arName: "المرسلات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King Faisal",
   arName: "ملك فيصل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jarir",
   arName: "جرير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Sulaimaniyah",
   arName: "السليمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Shimasi",
   arName: "الشميسي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jazirah",
   arName: "الجزيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Jaradiyah",
   arName: "الجرادية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Al Khalidyah",
   arName: "الخالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghubairah",
   arName: "غبيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manfuhah",
   arName: "منفوحة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badr",
   arName: "بدر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dubiyah",
   arName: "دوبية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Saadah",
   arName: "سعادة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sahafah",
   arName: "صحافة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Siyah",
   arName: "صياح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dhahrat laban",
   arName: "ظهرة لبن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qadisiyah",
   arName: "قادسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muruj",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King khalid airport",
   arName: "مطار الملك خالد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madhar ash shamali",
   arName: "معذر الشمالي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Maizilah",
   arName: "معيزلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Malqa",
   arName: "ملقا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdul aziz",
   arName: "ملك عبدالعزيز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mutamarat",
   arName: "مؤتمرات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nakhil",
   arName: "نخيل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Narjis",
   arName: "نرجس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nafl",
   arName: "نفل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Namudhajiyah",
   arName: "نموذجية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nur",
   arName: "نور",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yamamah",
   arName: "يمامة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Janadriyah",
   arName: "جنادرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hamra",
   arName: "حمراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rafiah",
   arName: "رفيعة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Skirinah",
   arName: "سكيرينة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sulimaniyah",
   arName: "سليمانيه",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sharafiyah",
   arName: "شرفية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shimaisi",
   arName: "شميسي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uraija al gharbiyah",
   arName: "عريجاء الغربية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghadir",
   arName: "غدير",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mursalat",
   arName: "مرسلات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marqab",
   arName: "مرقب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Malaz",
   arName: "ملز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Munsiyah",
   arName: "مونسية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Thulaim",
   arName: "ثليم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jabrah",
   arName: "جبرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabi",
   arName: "ربيع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dahiat namar",
   arName: "ضاحية نمار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dhubbat",
   arName: "ضباط",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taibah",
   arName: "طيبة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Amal",
   arName: "أمل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Qirawan",
   arName: "قيروان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Second industrial city",
   arName: "مدينة الصناعية الثانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masif",
   arName: "مصيف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Madhar",
   arName: "معذر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mikal",
   arName: "معكال",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wazarat",
   arName: "وزارات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yasmin",
   arName: "ياسمين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ghirnatah",
   arName: "غرناطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Murabba",
   arName: "مربع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mansurah",
   arName: "منصورة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nada",
   arName: "ندى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hada",
   arName: "هدا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jazirah",
   arName: "جزيرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Raid",
   arName: "رائد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rahmaniyah",
   arName: "رحمانية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salhiyah",
   arName: "صالحية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sinaiyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uqaz",
   arName: "عكاظ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Ulaya",
   arName: "عليا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Fakhriyah",
   arName: "فاخرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Masani",
   arName: "مصانع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wisham",
   arName: "وشام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Umm al hamam al gharbi",
   arName: "ام الحمام الغربي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Izdihar",
   arName: "إزدهار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Iskan",
   arName: "إسكان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Batha",
   arName: "بطحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Taawun",
   arName: "تعاون",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Imam muhammed ibn saud islamic university",
   arName: "جامعة الامام محمد بن سعود الاسلامية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hazm",
   arName: "حزم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Difa",
   arName: "دفاع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Zahra",
   arName: "زهراء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Shifa",
   arName: "شفاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faruq",
   arName: "فاروق",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mishal",
   arName: "مشاعل",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Misfat",
   arName: "مصفاة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mughrizat",
   arName: "مغرزات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manar",
   arName: "منار",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasriyah",
   arName: "ناصرية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzhah",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wurud",
   arName: "ورود",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wusayta",
   arName: "وسيطاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dar al baida",
   arName: "دار البيضاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Safa",
   arName: "صفا",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uraija",
   arName: "عريجاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Uraija al wusta",
   arName: "عريجاء الوسطى",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Futah",
   arName: "فوطة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Laban",
   arName: "لبن",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muhammadiyah",
   arName: "محمدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marwah",
   arName: "مروة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Manakh",
   arName: "مناخ",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nasim ash sharqi",
   arName: "نسيم الشرقي",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nazim",
   arName: "نظيم",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Wadi",
   arName: "وادي",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "Diriyah",
   arName: "",
   googleName: "",
   districts: [
   {
   name: "Diriyah",
   arName: "درعية",
   googleName: "",
   postalCode: "",
   }]
   },
   {
   name: "AD Diriyah",
   arName: "الدرعية",
   googleName: "",
   districts: [
   {
   name: "Ruqiyah",
   arName: "روقية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Diriyah al jadidah",
   arName: "درعية الجديدة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Samhan",
   arName: "سمحان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Bujayri",
   arName: "بجيري",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tarif al jadid",
   arName: "طريف الجديد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Tarif",
   arName: "طريف",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marih",
   arName: "مريح",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faisaliyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "R14",
  initialGeocode: { lat:25.96545292, lang:45.41404724} ,
   polygons: [
  [
   { lat:25.96545292, lng:45.41404724},
   { lat:25.96915687, lng:45.38040161},
   { lat:25.96236621, lng:45.34538269},
   { lat:25.94446168, lng:45.31036377},
   { lat:25.9277895, lng:45.28770447},
   { lat:25.9123502, lng:45.27397156},
   { lat:25.89505578, lng:45.28770447},
   { lat:25.87775882, lng:45.29251099},
   { lat:25.86725586, lng:45.31242371},
   { lat:25.86478444, lng:45.33027649},
   { lat:25.86478444, lng:45.35293579},
   { lat:25.86416658, lng:45.38108826},
   { lat:25.86293084, lng:45.39962769},
   { lat:25.86787371, lng:45.4209137},
   { lat:25.89011405, lng:45.42640686},
   { lat:25.91049734, lng:45.43190002},
   { lat:25.93519965, lng:45.43121338},
   { lat:25.94878371, lng:45.4209137},
   { lat:25.96545292, lng:45.41404724}]],
   cities: [
   {
   name: "Al Majma'ah",
   arName: "المجمعة",
   googleName: "",
   districts: [
   {
   name: "Andalus",
   arName: "اندلس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Marqab",
   arName: "مرقب",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdul aziz",
   arName: "ملك عبدالعزيز",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Jamiyin",
   arName: "جامعيين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King abdullah",
   arName: "ملك عبدالله",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Muruj",
   arName: "مروج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Yarmuk",
   arName: "يرموك",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King salman",
   arName: "ملك سلمان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faiha",
   arName: "فيحاء",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Airport",
   arName: "مطار",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  {
  region: "R15",
  initialGeocode: { lat:24.57210415, lang:44.45720673} ,
   polygons: [
  [
   { lat:24.57210415, lng:44.45720673},
   { lat:24.57585086, lng:44.44656372},
   { lat:24.58022189, lng:44.42493439},
   { lat:24.56960628, lng:44.41051483},
   { lat:24.55805297, lng:44.40124512},
   { lat:24.54493711, lng:44.38957214},
   { lat:24.55617936, lng:44.36828613},
   { lat:24.55493027, lng:44.35283661},
   { lat:24.5461863, lng:44.3466568},
   { lat:24.51932601, lng:44.34597015},
   { lat:24.50401932, lng:44.3421936},
   { lat:24.48839833, lng:44.34425354},
   { lat:24.47465025, lng:44.34768677},
   { lat:24.46090067, lng:44.35386658},
   { lat:24.45527541, lng:44.36210632},
   { lat:24.46027565, lng:44.37618256},
   { lat:24.49027295, lng:44.3995285},
   { lat:24.4968339, lng:44.42184448},
   { lat:24.50370692, lng:44.43042755},
   { lat:24.53088288, lng:44.44690704},
   { lat:24.542751, lng:44.45995331},
   { lat:24.55742843, lng:44.46338654},
   { lat:24.57210415, lng:44.45720673}]],
   cities: [
   {
   name: "Al Duwadimi",
   arName: "الدوادمي",
   googleName: "",
   districts: [
   {
   name: "Dirah",
   arName: "ديرة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Musaddah",
   arName: "مصده",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rayan",
   arName: "ريان",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Salam",
   arName: "سلام",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Badr",
   arName: "بدر",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalidiyah",
   arName: "خالدية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mishrifah",
   arName: "مشرفة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sanaiyyah",
   arName: "صناعية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Hatin",
   arName: "حطين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Sunbulah",
   arName: "سنبلة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Aziziyah",
   arName: "عزيزية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Mantiqat al istirahat",
   arName: "منطقة الاستراحات",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Quds",
   arName: "قدس",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rabwah",
   arName: "ربوة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Dar",
   arName: "درع",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Khalij",
   arName: "خليج",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Rawdah",
   arName: "روضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Harmin",
   arName: "حرمين",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nahdah",
   arName: "نهضة",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Faysaliyyah",
   arName: "فيصلية",
   googleName: "",
   postalCode: "",
   },
   {
   name: "King fahd",
   arName: "ملك فهد",
   googleName: "",
   postalCode: "",
   },
   {
   name: "Nuzaha",
   arName: "نزهة",
   googleName: "",
   postalCode: "",
   }]
   }]
  },
  ]
  
;
