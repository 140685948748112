import { connect } from "react-redux";
import App from "./App";
import { getOrderEnquiryData } from "./modules/return/actions/return.action";

const mapStateToProps = state => {
  return {
    orderLoading: state.orderInquiry.OrderInquiryDataLoading,
    orderData: state.orderInquiry.OrderInquiryData,
    orderStatus: state.orderInquiry.OrderInquiryDataStatus,
    orderError: state.orderInquiry.OrderInquiryDataError
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderData: () => dispatch(getOrderEnquiryData())
  };
};

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
