import { combineReducers } from "redux";
import home from "../modules/home/reducers/home.reducer";
import customer from "../modules/customer/reducers/customer.reducer";
import orderInquiry from "../modules/return/reducers/return.reducers";
import languageForm from "../modules/global/reducers/global.reducer";
import book from "../modules/confirmation/reducers/confirmation.reducer";

export default combineReducers({
  home,
  customer,
  languageForm,
  orderInquiry,
  book
});
