import React from "react";
import { Card, CardContent, Typography, Divider } from "@material-ui/core";

class CardPanel extends React.Component {
  render() {
    return (
      <Card className="CustomercardContain">
        <CardContent>
          <Typography className="customerCardTitle">
            {this.props.title}
          </Typography>
          <Divider className="customerDevider"/>
          {this.props.children}
        </CardContent>
      </Card>
    );
  }
}

export default CardPanel;
