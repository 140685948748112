import React from "react";
import styles from "../styles/DeliveryCalender.module.css";
import Timing from "./Timing";
import ScrollIndicator from "../../../coreComponents/scrollIndicator/ScrollIndicator";
import BookingActionButton from "../../../coreComponents/button/Button";
import EventItem from "./EventItem";
import LoaderIndicator from "../../../coreComponents/loader/LoaderIndicator";
import { isInArray, showPopUp, formatTime } from "../../../utils/Validation";
import PathNames from "../../../utils/route/index";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Check from "@material-ui/icons/Check";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Clear from "@material-ui/icons/Clear";
import { TIME_LINE_STATUS_EN } from "../../../utils/Constants";
import { withRouter } from "react-router";
import { getLanguage } from "../../../utils/LocalStorage";
import moment from "moment/min/moment-with-locales";
import { DATE_RANGE, DATE_NEXT_RANGE } from "../../../utils/Constants";
import * as storage from "../../../utils/LocalStorage";

class DeliveryCalender extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      calanderData: this.props.CalanderData,
      fromDate:
        this.props.CalanderData &&
        this.props.CalanderData.dates &&
        this.props.CalanderData.dates[0].date,
      toDate:
        this.props.CalanderData &&
        this.props.CalanderData.dates &&
        this.props.CalanderData.dates[0].date,
      peginationFromDate: moment(
        this.props.CalanderData &&
          this.props.CalanderData.dates &&
          this.props.CalanderData.dates[0].date
      ).format("D"),
      peginationToDate: moment(
        this.props.CalanderData &&
          this.props.CalanderData.dates &&
          this.props.CalanderData.dates[0].date
      ).format("D"),
      selected: null,
      fromIndex: 0,
      toIndex: 6,
      language: getLanguage(),
      selectedTimeslot: {
        fromTime:
          this.props.orderData.booking && this.props.orderData.booking.fromTime,
        toTime:
          this.props.orderData.booking && this.props.orderData.booking.toTime,
        code:
          this.props.orderData.booking && this.props.orderData.booking.window
      },
      selectedDate: moment(
        this.props.orderData.booking && this.props.orderData.booking.date
      ).format("YYYY-MM-DD")
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.CalanderData !== prevState.calanderData) {
      return {
        calanderData: nextProps.CalanderData,
        fromDate:
          nextProps.CalanderData &&
          nextProps.CalanderData.dates &&
          nextProps.CalanderData.dates.length &&
          nextProps.CalanderData.dates[0].date,
        toDate: moment(
          nextProps.CalanderData &&
            nextProps.CalanderData.dates &&
            nextProps.CalanderData.dates.length &&
            nextProps.CalanderData.dates[0].date
        )
          .add(DATE_NEXT_RANGE, "days")
          .format("YYYYMMDD"),
        peginationFromDate: moment(
          nextProps.CalanderData &&
            nextProps.CalanderData.dates &&
            nextProps.CalanderData.dates.length &&
            nextProps.CalanderData.dates[0].date
        ).format("D"),
        peginationToDate: moment(
          nextProps.CalanderData &&
            nextProps.CalanderData.dates &&
            nextProps.CalanderData.dates.length &&
            nextProps.CalanderData.dates[0].date
        )
          .add(DATE_NEXT_RANGE, "days")
          .format("D"),
        fromIndex: prevState.fromIndex,
        toIndex: prevState.toIndex
      };
    }
  }

  async componentDidMount() {
    this.props.getLanguageForm();
    this.props.getCalanderData(this.state.fromIndex, this.state.toIndex);
    this.setState({
      peginationFromDate: moment(this.state.fromDate).format("D"),
      peginationToDate: moment(this.state.toDate)
        .add(DATE_NEXT_RANGE, "days")
        .format("D"),
      toDate: moment(
        this.state.calanderData &&
          this.state.calanderData.dates &&
          this.state.calanderData.dates[0].date
      )
        .add(DATE_NEXT_RANGE, "days")
        .format("YYYYMMDD")
    });

    var selectedWindow = await storage.getTimeSlot();
    selectedWindow &&
      this.setState({
        selectedDate: selectedWindow.date,
        selectedTimeslot: selectedWindow.time
      });
    storage.setTimeSlot({
      date: this.state.selectedDate,
      time: this.state.selectedTimeslot
    });
  }

  componentDidUpdate() {
    if (this.state.language !== getLanguage()) {
      this.setState({ language: getLanguage() });
      this.props.getLanguageForm();
    }
  }
  navigatToNext() {
    const { strings } = this.props;
    if (!this.state.selectedDate) {
      showPopUp(strings.customerForm.validation.PLEASE_SELECT_TIME_SLOT);
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <button className="close_Btn" onClick={onClose}>
                <Clear style={{ fontSize: "30px" }} />
              </button>
              <div className="custom-sub-ui">
                <h1>{strings.calenderForm.alertHeader}</h1>
                <p>
                  {`${strings.calenderForm.alertTitle} ${formatTime(
                    this.state.selectedTimeslot.fromTime
                  )} - ${formatTime(this.state.selectedTimeslot.toTime)} ${
                    strings.calenderForm.slot
                  }
              ${moment(this.state.selectedDate).format("Do MMM YYYY")}?`}
                </p>
                <button
                  className="success_Btn"
                  onClick={() => {
                    this.props.history.push("/confirmation");
                    onClose();
                  }}
                >
                  {strings.calenderForm.alertButton}
                </button>
              </div>
            </div>
          );
        }
      });
    }
  }
  DeliveryDetailStatus = event => {
    this.props.history.push(PathNames.DELIVERY_INFO);
  };
  DeliveryTimeStatus = event => {
    event.preventDefault();
  };
  DeliveryConfirmStatus = event => {
    event.preventDefault();
  };
  handleEventListener = (
    clickedVal,
    windowIndex,
    preSetIndex,
    calendarData
  ) => {
    storage.setTimeSlot({ date: calendarData.date, time: clickedVal });
    console.log("clickedVal", clickedVal);
    this.setState({
      slectedRowIndex: windowIndex,
      slectedColumnIndex: preSetIndex,
      selectedDate: calendarData.date,
      selectedTimeslot: clickedVal
    });
  };
  renderItem(preSubSetItem, preSubSetIndex, preSetIndex, calendarData) {
    const { strings } = this.props;
    let availability;
    if (calendarData) {
      var getWindowArray = Object.values(calendarData)[1].map(item => {
        return item.code;
      });
      if (isInArray(preSubSetItem[preSetIndex].code, getWindowArray)) {
        if (Object.values(calendarData)[1][preSetIndex].isAvailable) {
          availability = strings && strings.calenderForm.available;
        } else {
          availability = strings && strings.calenderForm.notAvailable;
        }
      }
    }

    // var is_selected =
    //   this.state.slectedRowIndex === preSubSetIndex &&
    //   this.state.slectedColumnIndex === preSetIndex;

    var renderingWindow = Object.values(calendarData)[1][preSetIndex];

    var is_selected =
      this.state.selectedDate === calendarData.date &&
      this.state.selectedTimeslot.code === renderingWindow.code;

    return (
      <EventItem
        strings={strings}
        status={availability}
        onClick={() =>
          this.handleEventListener(
            preSubSetItem[preSetIndex],
            preSubSetIndex,
            preSetIndex,
            calendarData
          )
        }
        selected={is_selected}
      />
    );
  }

  eventItem = (preSetItem, preSetIndex, calendarData) => {
    return (
      <div>
        {calendarData &&
          calendarData.map((preSubSetItem, i) =>
            this.renderItem(preSetItem, i, preSetIndex, preSubSetItem)
          )}
      </div>
    );
  };

  windowItem = (windowItem, i) => {
    let day = moment(windowItem.date).format("ddd");
    let dayDate = moment(windowItem.date).format("DD");
    let month = moment(windowItem.date).format("MMM");

    return (
      <div className={styles.itemContainerdays}>
        <div className={styles.itemHolder} key={i}>
          <div>{`${dayDate} ${month}`}</div>
          <div>{`${day}`}</div>
        </div>
      </div>
    );
  };

  handleForwardPegination = calendarResponse => {
    if (calendarResponse && calendarResponse.length > 6) {
      this.setState(
        {
          peginationFromDate: moment(this.state.fromDate)
            .add(DATE_RANGE, "days")
            .format("D"),
          peginationToDate: moment(this.state.toDate)
            .add(DATE_RANGE, "days")
            .format("D"),
          fromIndex: this.state.fromIndex + DATE_RANGE,
          toIndex: this.state.toIndex + DATE_RANGE,
          slectedRowIndex: "",
          slectedColumnIndex: ""
          // selectedDate: "",
          // selectedTimeslot: ""
        },
        () => {
          this.setState({
            fromDate: moment(this.state.fromDate)
              .add(DATE_RANGE, "days")
              .format("YYYYMMDD"),
            toDate: moment(this.state.toDate)
              .add(DATE_RANGE, "days")
              .format("YYYYMMDD")
          });
          this.props.getCalanderData(this.state.fromIndex, this.state.toIndex);
        }
      );
    }
  };

  handleBackwardPegination() {
    if (this.state.fromIndex !== 0) {
      this.setState(
        {
          peginationFromDate: moment(this.state.fromDate)
            .subtract(DATE_RANGE, "days")
            .format("D"),
          peginationToDate: moment(this.state.toDate)
            .subtract(DATE_RANGE, "days")
            .format("D"),
          fromIndex: this.state.fromIndex - DATE_RANGE,
          toIndex: this.state.toIndex - DATE_RANGE,
          slectedRowIndex: "",
          slectedColumnIndex: ""
          // selectedDate: "",
          // selectedTimeslot: ""
        },

        () => {
          this.setState({
            fromDate: moment(this.state.fromDate)
              .subtract(DATE_RANGE, "days")
              .format("YYYYMMDD"),
            toDate: moment(this.state.toDate)
              .subtract(DATE_RANGE, "days")
              .format("YYYYMMDD")
          });
          this.props.getCalanderData(this.state.fromIndex, this.state.toIndex);
        }
      );
    }
  }
  windowTimeItem = (item, i) => {
    var timeStart = formatTime(item[i].fromTime);
    var timeEnd = formatTime(item[i].toTime);
    if (item) {
      return (
        <div className={styles.timeItemHolder} key={i}>
          <div className={styles.timeItemText}>{`${timeStart}-${timeEnd}`}</div>
        </div>
      );
    }
  };

  renderCalendarHeader(calendarResponse) {
    const lang = localStorage.getItem("lang");

    if (lang === "en") {
      moment.locale("en");
      let deliveryMonth =
        calendarResponse &&
        calendarResponse.length &&
        moment(calendarResponse[0].date).format("MMM");

      return (
        <div className={styles.calanderContaoner}>
          <ArrowBackIos onClick={() => this.handleBackwardPegination()} />
          <div
            className={styles.daterangedisplay}
          >{`${this.state.peginationFromDate} - ${this.state.peginationToDate} ${deliveryMonth}`}</div>
          <ArrowForwardIos
            onClick={() => this.handleForwardPegination(calendarResponse)}
          />
        </div>
      );
    } else {
      moment.locale("ar");
      let arabicDeliveryMonth =
        calendarResponse &&
        calendarResponse.length &&
        moment(calendarResponse[0].date).format("MMM");

      return (
        <div className={styles.calanderContaoner}>
          <ArrowForwardIos onClick={() => this.handleBackwardPegination()} />
          <div
            className={styles.daterangedisplay}
          >{`${this.state.peginationFromDate} - ${this.state.peginationToDate} ${arabicDeliveryMonth}`}</div>
          <ArrowBackIos
            onClick={() => this.handleForwardPegination(calendarResponse)}
          />
        </div>
      );
    }
  }
  render() {
    let calendarResponse =
      this.props.CalanderData && this.props.CalanderData.dates;

    if (this.props.CalendarDataLoading) {
      return <LoaderIndicator />;
    }

    const { strings } = this.props;
    return (
      <div className={styles.container}>
        <ScrollIndicator />

        <div className={styles.subContainer}>
          <Timing
            strings={strings}
            value1={{
              title: strings && strings.calenderForm.timeLine.delieveryDetails,
              status: TIME_LINE_STATUS_EN.COMPLETED
            }}
            value2={{
              title: strings && strings.calenderForm.timeLine.delieveryTiming,
              status: TIME_LINE_STATUS_EN.IN_PROGRESS
            }}
            value3={{
              title: strings && strings.calenderForm.timeLine.confirmation,
              status: TIME_LINE_STATUS_EN.INITIAL
            }}
            value1OnTap={() => this.props.history.push(PathNames.DELIVERY_INFO)}
            value2OnTap={() => console.log("value2OnTap")}
            value3OnTap={() => console.log("value3OnTap")}
          />

          <div className={styles.calenderContainer}>
            <div>
              {this.renderCalendarHeader(calendarResponse)}
              <div>
                <div className={styles.horizontalcontainer}>
                  <div className={styles.windowContainer}>
                    {calendarResponse &&
                      calendarResponse.map((item, i) =>
                        this.windowItem(item, i)
                      )}
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.timeContainer}>
                      {this.props.preSetWindows &&
                        this.props.preSetWindows.map((item, i) =>
                          this.windowTimeItem(item, i)
                        )}
                    </div>

                    <div className={styles.eventContainer}>
                      {this.props.preSetWindows &&
                        this.props.preSetWindows.map((preSetItem, i) =>
                          this.eventItem(
                            preSetItem,
                            i,
                            calendarResponse && calendarResponse
                          )
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BookingActionButton
            homeButton=""
            onClick={() => this.navigatToNext()}
          >
            {strings && strings.calenderForm.button}
          </BookingActionButton>
        </div>
      </div>
    );
  }
}

export default withRouter(DeliveryCalender);
