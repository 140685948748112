import React from "react";
import Timing from "../../customer/components/Timing";
import WelcomeBackUser from "./WelcomeBackUser";
import BookingDetailsBox from "./BookingDetailsBox";
import InfoMsg from "./infoMsg";
import ContactUs from "./ContactUs";
import { Fab, Box } from "@material-ui/core";
import BookingStatus from "./BookingStatus";
import Check from "@material-ui/icons/Check";

class TrackShipment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      val1: <Check />,
      val2: <Check />,
      val3: "3"
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Box className="returnmainContainer">
        <WelcomeBackUser />
        <BookingStatus />
        <Timing
          currentPage={this.props.location.pathname}
          value1={this.state.val1}
          value2={this.state.val2}
          value3={this.state.val3}
        />
        <BookingDetailsBox />
        <Fab
          variant="extended"
          aria-label="add"
          className="trackShipmentbutton"
        >
          Track Your shipment
        </Fab>
        <InfoMsg />
        <ContactUs />
      </Box>
    );
  }
}

export default TrackShipment;
