import React from "react";
import DeliveryAddress from "./DeliveryAddress";
import SiteInformation from "./SiteInformation";
import OtherComponent from "./OtherComponent";
import DeliveryTime from "./DeliveryTime";
import Timing from "../../customer/components/Timing";
import Person from "@material-ui/icons/Person";
import BookingActionButton from "../../../coreComponents/button/Button";
import "../styles/Confirmation.css";
import * as storage from "../../../utils/LocalStorage";
import { TIME_LINE_STATUS_EN } from "../../../utils/Constants";
import LoaderIndicator from "../../../coreComponents/loader/LoaderIndicator";
import moment from "moment/min/moment-with-locales";

class ConfirmationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customerInfo: this.props.customerAddress,
      customerTimeSlot: ""
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps) {
      if (nextProps.createBookingStatus === "success") {
        if (nextProps.createBookingData.isSucess) {
          nextProps.history.push("/confirmBooking");
        } else {
          nextProps.history.push("/error");
        }

        if (nextProps.clearBookingStatus) {
          nextProps.clearBookingStatus();
        }
      }
    }
  }

  componentDidMount() {
    this.props.getLanguageForm();
    this.getLocalData();
    window.scrollTo(0, 0);
  }

  async getLocalData() {
    let persistState = await storage.getContactInfo();
    let persistDate = await storage.getTimeSlot();
    let persistOrder = await storage.getApiData();
    if (persistState && persistDate && persistOrder) {
      try {
        this.setState({
          customerInfo: persistState,
          customerTimeSlot: persistDate,
          customerOrder: persistOrder
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
  navigatToNext = async event => {
    const { customerInfo, customerTimeSlot, customerOrder } = this.state;
    let selectedDate = `${customerTimeSlot.date} ${customerTimeSlot.time.fromTime}`;
    moment.locale("en");
    let postObj = {
      orderNumber: customerOrder.orderNumber,
      validationKey: customerOrder.validationKey,
      language: customerOrder.language,
      date: moment.utc(selectedDate).format(),
      windowCode: customerTimeSlot.time.code,
      customer: {
        name: customerInfo.contact.name,
        mobile: customerInfo.contact.mobile,
        email: customerInfo.contact.email
      },
      deliveryAddress: {
        country: customerInfo.deliveryAddress.country,
        region: customerInfo.deliveryAddress.region,
        city: customerInfo.deliveryAddress.cityEn,
        district: customerInfo.deliveryAddress.districtEn,
        streetName: "string",
        postalCode: customerInfo.deliveryAddress.postalCode,
        addressLine1: customerInfo.deliveryAddress.addressLine1En,
        //addressLine2: `B# ${customerInfo.siteInfo.buildingNumber},T ${customerInfo.siteInfo.buildingType},Floor ${customerInfo.siteInfo.floor}`,
        // addressLine3: "string",
        latitude: customerInfo.deliveryAddress.latitude,
        longitude: customerInfo.deliveryAddress.longitude,
        what3Words: customerInfo.deliveryAddress.what3wordsEn
      },
      questionnaire: customerInfo.siteInfo.questionnaire
    };
    console.log("Order Json", postObj);
    this.props.createBooking(postObj, this.props.orderData.booking);
  };
  navigateToDeliveryInfo() {
    this.props.history.push("/deliveryInfo");
  }
  render() {
    const { strings } = this.props;
    let customerInfo = this.state.customerInfo;
    if (this.props.createBookingLoading) {
      return <LoaderIndicator />;
    }
    return (
      <div className="confirmationBook_mainsection">
        <div>
          <Timing
            strings={strings}
            value1={{
              title: strings && strings.calenderForm.timeLine.delieveryDetails,
              status: TIME_LINE_STATUS_EN.COMPLETED
            }}
            value2={{
              title: strings && strings.calenderForm.timeLine.delieveryTiming,
              status: TIME_LINE_STATUS_EN.COMPLETED
            }}
            value3={{
              title: strings && strings.calenderForm.timeLine.confirmation,
              status: TIME_LINE_STATUS_EN.IN_PROGRESS
            }}
            value1OnTap={() => this.props.history.push("/deliveryInfo")}
            value2OnTap={() => this.props.history.push("/deliveryCalander")}
            value3OnTap={() => console.log("value3OnTap")}
          />
          <div className="Confirmbooking-summary">
            <h1 className="order-detail-header _flex bb1">
              <span className="order-detail-title">
                {strings &&
                  strings.confirmationForm.bookingSummary.bookingSummaryTitle}
              </span>
            </h1>
            <div>
              <div className="flex_sb pv15">
                <div className="booking-summry-card-inner-panel">
                  <div className="card_icon">
                    <Person className="orderDetailIcon" />
                  </div>
                  <div className="card_content">
                    <div className="Confirm_order_panel_value">
                      {strings &&
                        strings.confirmationForm.bookingSummary
                          .contactInformation}
                    </div>
                    {customerInfo.contact && (
                      <div className="order_panel_subvalue">
                        {customerInfo.contact.name}
                        <br />
                        {`${customerInfo.contact.mobile}`}
                        <br />
                        {customerInfo.contact.email}
                      </div>
                    )}
                  </div>
                </div>

                <div className="pl15">
                  <div
                    className="edit_text_blue"
                    onClick={() => this.navigateToDeliveryInfo()}
                  >
                    {strings && strings.confirmationForm.edit}
                  </div>
                </div>
              </div>
              <DeliveryAddress
                strings={strings}
                address={customerInfo.deliveryAddress}
              />
              {/* <SiteInformation
                strings={strings}
                address={customerInfo.siteInfo}
              /> */}
              <OtherComponent address={customerInfo.siteInfo} />
              <DeliveryTime
                strings={strings}
                history={this.props.history}
                data={this.state.customerTimeSlot}
              />
            </div>
          </div>
          <BookingActionButton
            homeButton=""
            onClick={() => this.navigatToNext()}
          >
            {strings && strings.confirmationForm.button}
          </BookingActionButton>
        </div>
      </div>
    );
  }
}

export default ConfirmationDetails;
