import React from "react";
import constants from "../../../constants/Images";

const Banner = () => {
  return (
    <div className="_block banner_container">
      <img
        className="banner_img"
        // src="https://www.artclickireland.com/wp-content/uploads/2017/06/image-6.jpg"
        src={constants.homeBanner}
        alt=""
      />
    </div>
  );
};

export default Banner;
