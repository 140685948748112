import React from "react";
import { Typography, Select, MenuItem } from "@material-ui/core";
import "./DropdownStyle.css";
import PropTypes from "prop-types";

class Dropdown extends React.Component {
  render() {
    const placeholder = this.props.selectedValue && undefined;
    const selectedValue =
      this.props.selectedValue &&
      this.props.options &&
      this.props.options.some(item => item.value === this.props.selectedValue)
        ? this.props.selectedValue
        : undefined;

    return (
      <div className="textFieldContainer">
        <Typography style={{ color: "black", fontWeight: "600" }}>
          {this.props.title}
        </Typography>

        <Select
          style={{ width: "100%" }}
          displayEmpty
          value={selectedValue}
          disableUnderline
          onChange={event => this.props.onChange(event.target.value)}
        >
          <MenuItem
            disabled
            value={undefined}
            style={{ backgroundColor: "white" }}
          >
            <div
              style={{
                color: "lightgray",
                fontSize: 16
              }}
            >
              {this.props.placeholder}
            </div>
          </MenuItem>
          {this.props.options.map((item, i) => (
            <MenuItem key={i} value={item.value}>
              {item.value}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }
}
Dropdown.propTypes = {
  options: PropTypes.array,
  onSelect: PropTypes.func
};

Dropdown.defaultProps = {
  options: [],
  value: ""
};

export default Dropdown;
