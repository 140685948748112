import React from "react";
import LocalShipping from "@material-ui/icons/LocalShipping";
import moment from "moment/min/moment-with-locales";
import { formatTime } from "../../../utils/Validation";
class DeliveryTime extends React.Component {
  constructor(props) {
    super(props);
  }
  navigatTodeliverycalender() {
    this.props.history.push("/deliveryCalander");
  }
  render() {
    const { strings } = this.props;
    let day = this.props.data && moment(this.props.data.date).format("dddd");
    let dayDate =
      this.props.data && moment(this.props.data.date).format("DD MMMM YYYY");
    let fromTime = this.props.data && formatTime(this.props.data.time.fromTime);
    let toTime =
      this.props.data &&
      this.props.data &&
      formatTime(this.props.data.time.toTime);
    return (
      <div className="booking-summry-deliverytime-container">
        <div className="booking-summry-card-inner-panel">
          <div className="card_icon">
            <LocalShipping className="orderDetailIcon" />
          </div>
          <div className="card_content">
            <div className="Confirm_order_panel_value">
              {strings && strings.confirmationForm.bookingSummary.deliveryTime}
            </div>
            <div className="order_panel_subvalue">{`${day},${dayDate} at  ${fromTime} - ${toTime}`}</div>
          </div>
        </div>
        <div className="pl15">
          <div
            className="edit_text_blue"
            onClick={() => this.navigatTodeliverycalender()}
          >
            {strings && strings.confirmationForm.edit}
          </div>
        </div>
      </div>
    );
  }
}

export default DeliveryTime;
