import { connect } from "react-redux";
import Footer from "../../../coreComponents/footer/Footer";
import { getLanguageForm } from "../../global/actions/global.actions";

const mapStateToProps = state => {
  return {
    strings: state.languageForm.strings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLanguageForm: () => dispatch(getLanguageForm())
  };
};

const FooterContainer = connect(mapStateToProps, mapDispatchToProps)(Footer);

export default FooterContainer;
