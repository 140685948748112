import React from "react";
import { Fab } from "@material-ui/core";
import Autocomplete from "react-google-autocomplete";
import Images from "../../constants/Images";
import axios from "axios";
import { blue, grey } from "@material-ui/core/colors";

class MapSearchBar extends React.Component {
  componentWillUnmount() {
    window.removeEventListener("select", this.onSelectSuggestion);
  }

  shouldComponentUpdate() {}

  onSelectSuggestion = event => {
    if (!this.props.isWhat3words) {
      return;
    }

    console.log("scrollProgress");
    console.log(event.target.value);
    const urlString = `https://api.what3words.com/v3/convert-to-coordinates?words=${event.target.value}&key=384BNISX`;
    axios.get(urlString).then(
      res => {
        const coords = {
          latitude: res.data.coordinates.lat,
          longitude: res.data.coordinates.lng
        };
        this.props.onCurrentLocation(coords);
      },
      error => {
        console.log(error);
      }
    );
  };

  componentDidMount() {
    window.addEventListener("select", this.onSelectSuggestion);
    
  }

  render() {
    return (
      <div
        style={{
          position: "absolute",
          left: 10,
          right: 10,
          top: 40,
          height: "auto",
          backgroundColor: "white",
          zIndex: 10,
          padding: 5,
          borderRadius: 5,
          borderColor: "gray",
          borderWidth: 5,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div style={{ width: "90%" }}>
          <div style={{ fontWeight: 'bold' }}>{this.props.searchBarTitle}</div>

          {this.props.isWhat3words ? (
            <what3words-autosuggest
              // ref="Progress1"
              id="autosuggest"
              debug="true"
              placeholder={this.props.what3wordsPlaceholder}
              n-results="5"
              clip-to-country="sa"
              error-message="you have entered an invalid address"
            />
          ) : (
            <Autocomplete
              className=""
              style={{
                display: "flex",
                width: "100%",
                height: "30px",
                
              }}
              onPlaceSelected={this.props.onPlaceSelected}
              types={null}
              componentRestrictions={{ country: "sa" }}
            />
          )}
        </div>
        <Fab
          className="currentLocationPin"
          onClick={() => this.getGeoLocation()}
        >
          <img
            alt=""
            src={Images.currentLocationIcon}
            style={{ height: 25, width: 25 }}
          />
        </Fab>
      </div>
    );
  }

  getGeoLocation = () => {
    console.log("getGeoLocation");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.props.onCurrentLocation(position.coords);
        console.log("logging position....");
        console.log(position);
      });
    } else {
      console.log("navigator.geolocation not found");
    }
  };
}

export default MapSearchBar;
