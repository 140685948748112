import * as confirmationActions from "../actions/confirmation.actions";
const customer = (
  state = {
    createBookingLoading: false,
    createBookingData: [],
    createBookingStatus: "",
    createBookingError: null
  },
  action
) => {
  switch (action.type) {
    case confirmationActions.CREATE_BOOKING_REQUESTING:
      return Object.assign({}, state, {
        createBookingLoading: true,
        createBookingStatus: action.status
      });
    case confirmationActions.CREATE_BOOKING_SUCCESS: {
      return Object.assign({}, state, {
        createBookingLoading: false,
        createBookingData: action.data,
        createBookingStatus: action.status
      });
    }
    case confirmationActions.CREATE_BOOKING_FAILURE: {
      return Object.assign({}, state, {
        createBookingLoading: false,
        createBookingStatus: action.status,
        createBookingError: action.error
      });
    }
    case confirmationActions.CLEAR_BOOKING_STATUS: {
      return Object.assign({}, state, {
        createBookingStatus: ""
      });
    }

    default:
      return state;
  }
};

export default customer;
