import { SUCCESS, REQUESTING, ERROR } from "../../../utils/Constants";
import { getError } from "../../../utils/ErrorHandler";
import * as storage from "../../../utils/LocalStorage";
import { getApiData } from "../../../utils/LocalStorage";

export const ADD_CUSTOMER_ADDRESS_REQUESTING =
  "ADD_CUSTOMER_ADDRESS_REQUESTING";
export const ADD_CUSTOMER_ADDRESS_SUCCESS = "ADD_CUSTOMER_ADDRESS_SUCCESS";
export const ADD_CUSTOMER_ADDRESS_FAILURE = "ADD_CUSTOMER_ADDRESS_FAILURE";

export const GET_CALENDAR_DATA_REQUESTING = "GET_CALENDAR_DATA_REQUESTING";
export const GET_CALENDAR_DATA_SUCCESS = "GET_CALENDAR_DATA_SUCCESS";
export const GET_CALENDAR_DATA_FAILURE = "GET_CALENDAR_DATA_FAILURE";

export const addCustomerAddressRequesting = () => ({
  type: ADD_CUSTOMER_ADDRESS_REQUESTING,
  status: REQUESTING
});

export const addCustomerAddressSuccess = data => ({
  type: ADD_CUSTOMER_ADDRESS_SUCCESS,
  status: SUCCESS,
  data
});

export const addCustomerAddressFailure = error => ({
  type: ADD_CUSTOMER_ADDRESS_FAILURE,
  status: ERROR,
  error
});

export function addCustomerAddress(customerData) {
  return async (dispatch, getState, api) => {
    dispatch(addCustomerAddressRequesting());
    try {
      storage.setContactInfo(customerData);
      dispatch(addCustomerAddressSuccess(customerData));
    } catch (e) {
      dispatch(addCustomerAddressFailure(e.message));
    }
  };
}

export const getCalanderDataRequesting = () => ({
  type: GET_CALENDAR_DATA_REQUESTING,
  status: REQUESTING
});

export const getCalanderDataSuccess = (data, preSetWindows) => ({
  type: GET_CALENDAR_DATA_SUCCESS,
  status: SUCCESS,
  data,
  preSetWindows
});

export const getCalanderDataFailure = error => ({
  type: GET_CALENDAR_DATA_FAILURE,
  status: ERROR,
  error
});

export function getCalanderData(fromIndex, toIndex) {
  return async (dispatch, getState, api) => {
    var apiData = await getApiData();
    dispatch(getCalanderDataRequesting());
    try {
      const result = await api.post(
        // "http://qa-apigw-hd.uk-e1.cloudhub.io/api/v1/Calendar",
        //"https://qa-offline-homedelivery-booking.uk-e1.cloudhub.io/api/v1/Calendar",
        "https://prod-home-delivery-booking-proxi.uk-e1.cloudhub.io/api/v1/Calendar",
        apiData
      );

      let errorDetails = getError(result);

      if (errorDetails.hasError) {
        throw new Error(errorDetails.message);
      }

      var preSetWindows = new Array(result.data.dates[0].windows.length);

      for (var i = 0; i < preSetWindows.length; i++) {
        preSetWindows[i] = [];
        let newObj = result.data.dates[i].windows.map((item, i) => {
          let object = {
            fromTime: result.data.dates[i].windows[i].fromTime,
            toTime: result.data.dates[i].windows[i].toTime,
            code: result.data.dates[i].windows[i].code
          };
          return object;
        });
        preSetWindows[i] = newObj;
      }

      let newData = result.data.dates.filter((item, i) => {
        if (i >= fromIndex && i <= toIndex) {
          return item;
        }
      });
      let data = {
        dates: newData
      };

      dispatch(getCalanderDataSuccess(data, preSetWindows));
    } catch (e) {
      dispatch(getCalanderDataFailure(e.message));
    }
  };
}
