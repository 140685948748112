import React from "react";
import { Typography } from "@material-ui/core";

const width = {
  width: "100%",
  height: "24px",
  marginTop: "7px"
};

class TextField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: props.value ? props.value : ""
    };
  }
  handleChange(event) {
    const NUMBER_REGEX = /^[0-9]+$/;
    const ALPHABET_REGEX = /^[a-zA-Z ]+$/;
    console.log("this.props.onlyNumber=", this.props.onlyNumber);
    if (this.props.onlyNumber) {
      if (event.target.value === "" || NUMBER_REGEX.test(event.target.value)) {
        if (event.target.value.length <= this.props.maxLength) {
          this.setState({ value: event.target.value }, () => {
            if (this.props.onChange) {
              this.props.onChange(this.state.value);
            }
          });
        }
      } else {
        event.preventDefault();
      }
    }
    if (this.props.onlyAlphabet) {
      if (
        event.target.value === "" ||
        ALPHABET_REGEX.test(event.target.value)
      ) {
        this.setState({ value: event.target.value }, () => {
          if (this.props.onChange) {
            this.props.onChange(this.state.value);
          }
        });
      } else {
        event.preventDefault();
      }
    }
    if (!this.props.onlyAlphabet && !this.props.onlyNumber) {
      this.setState({ value: event.target.value }, () => {
        if (this.props.onChange) {
          this.props.onChange(this.state.value);
        }
      });
    }
  }

  handleKeyPress(event) {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(event);
    }
  }

  handleKeyUp = event => {
    if (this.props.onKeyUp) {
      this.props.onKeyUp(event);
    }
  };

  render() {
    return (
      <div className="textFieldContainer">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Typography style={{ color: "black", fontWeight: "600" }}>
            {this.props.title}
          </Typography>
          {this.props.isEditOptionRequired && (
            <Typography style={{ color: "#2196F3", fontWeight: "600" }}>
              Edit
            </Typography>
          )}
        </div>

        <input
          {...this.props}
          id="standard-name11"
          className="inpNotFocus"
          // disableUnderline={true}
          value={this.props.value ? this.props.value : this.state.value}
          placeholder={this.props.placeholder}
          style={width}
          onChange={event => this.handleChange(event)}
          onKeyPress={event => this.handleKeyPress(event)}
          onKeyUp={event => this.handleKeyUp(event)}
          // onChange={event => this.props.onChange(event.target.value)}
        />
      </div>
    );
  }
}

TextField.defaultProps = {
  isEditOptionRequired: false
};

export default TextField;
