import React from "react";

export const setLanguage = value => localStorage.setItem("lang", value);
export const getLanguage = () => localStorage.getItem("lang");

export const setEditingStatus = value => localStorage.setItem("isEditing", value);
export const isEditing = () => localStorage.getItem("isEditing");

export const setContactInfo = async data => {
  try {
    await localStorage.setItem("customerInfo", JSON.stringify(data));
  } catch (error) {
    console.log(error.message);
  }
};
export const getContactInfo = async () => {
  try {
    const value = await localStorage.getItem("customerInfo");
    return JSON.parse(value);
  } catch (e) {
    console.log(e.message);
  }
};

export const setTimeSlot = async data => {
  try {
    await localStorage.setItem("timeslot", JSON.stringify(data));
  } catch (error) {
    console.log(error.message);
  }
};
export const getTimeSlot = async () => {
  try {
    const value = await localStorage.getItem("timeslot");
    return JSON.parse(value);
  } catch (e) {
    console.log(e.message);
  }
};

export const setApiData = async data => {
  try {
    await localStorage.setItem("apiData", JSON.stringify(data));
  } catch (error) {
    console.log(error.message);
  }
};
export const getApiData = async () => {
  try {
    const value = await localStorage.getItem("apiData");
    return JSON.parse(value);
  } catch (e) {
    console.log(e.message);
  }
};

export const setCreateBookingData = async data => {
  try {
    await localStorage.setItem("createBooking", JSON.stringify(data));
  } catch (error) {
    console.log(error.message);
  }
};
export const getCreateBookingData = async () => {
  try {
    const value = await localStorage.getItem("createBooking");
    return JSON.parse(value);
  } catch (e) {
    console.log(e.message);
  }
};
