import React from "react";
import constants from "../../../constants/Images";
import moment from "moment/min/moment-with-locales";

const OrderDetail = props => {
  const { orderDetailsForm, orderData } = props;
  return (
    <div>
      <h1 className="nav order-detail-header _flex">
        <span className="order-detail-title">{orderDetailsForm.title}</span>
        <span className="order-detail-time">
          {moment(orderData.date).format("DD MMMM YYYY")}
        </span>
      </h1>

      <div className="nav">
        <div className="order-store-card _flex nav">
          <div className="order-store-card-inner-panel">
            <div className="card_icon">
              <img
                alt=""
                src={constants.cubeIcon}
                className="orderDetailIcon"
              />
            </div>
            <div className="card_content">
              <div className="order_panel_label">{orderDetailsForm.order}</div>
              <div className="order_panel_value">{orderData.orderNumber}</div>
            </div>
          </div>

          <div className="order-store-card-inner-panel">
            <div className="card_icon">
              <img
                alt=""
                src={constants.shopIcon}
                className="orderDetailIcon"
              />
            </div>
            <div className="card_content">
              <div className="order_panel_label">{orderDetailsForm.store}</div>
              <div className="order_panel_value">{orderData.store}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
