import axios from "axios";

const SHORT_TIME_OUT = 60000;
const apiURLRoot = "";

export async function get(url) {
  return await axios(`${url}`, {
    headers: {
      "Content-Type": "application/json"
      // Authorization: `Bearer ${userToken}`
    },
    timeout: SHORT_TIME_OUT
  });
}

export async function getWithAlgolia(url) {
  return await axios(`${url}`, {
    headers: {
      "X-Algolia-API-Key": "f4bf285b669b9fbf747d7c7ffabf6a52",
      "X-Algolia-Application-Id": "testingYRFDV96GMU"
    },
    timeout: SHORT_TIME_OUT
  });
}
export async function post(url, payload = {}) {
  console.log("URL", url);
  const postman = "homedeliveryportal";
  const password = "ExjEKUyvv37Gtg34";
  const basicAuth = "Basic " + btoa(postman + ":" + password);
  let result = await axios({
    method: "post",
    url: `${url}`,
    data: payload,
    headers: {
      "Content-Type": "application/json",
      Authorization: basicAuth
    },

    timeout: SHORT_TIME_OUT
  });
  return result;
}

export async function put(url, payload = {}, header) {
  let result = await axios({
    method: "put",
    url: `${apiURLRoot}/${url}`,

    headers: {
      //   Authorization: `Bearer ${header.userAuth}`,
      "Content-Type": "application/json;charset=utf-8"
    },
    data: payload,
    timeout: SHORT_TIME_OUT
  });
  return result;
}

export async function deleteApi(url, header) {
  console.log("URL", url);

  let result = await axios({
    method: "delete",
    url: `${url}`,

    headers: {
      "Content-Type": "application/json"
      // Authorization: `Bearer ${userToken}`
    },
    timeout: SHORT_TIME_OUT
  });
  return result;
}
