import { SUCCESS, REQUESTING, ERROR } from "../../../utils/Constants";
import { getError } from "../../../utils/ErrorHandler";
import * as storage from "../../../utils/LocalStorage";

export const CREATE_BOOKING_REQUESTING = "CREATE_BOOKING_REQUESTING";
export const CREATE_BOOKING_SUCCESS = "CREATE_BOOKING_SUCCESS";
export const CREATE_BOOKING_FAILURE = "CREATE_BOOKING_FAILURE";
export const CLEAR_BOOKING_STATUS = "CLEAR_BOOKING_STATUS";

export const createBookingRequesting = () => ({
  type: CREATE_BOOKING_REQUESTING,
  status: REQUESTING
});

export const createBookingSuccess = data => ({
  type: CREATE_BOOKING_SUCCESS,
  status: SUCCESS,
  data
});

export const createBookingFailure = error => ({
  type: CREATE_BOOKING_FAILURE,
  status: ERROR,
  error
});

export function createBooking(bookingData, isExistingCustomer) {
  return async (dispatch, getState, api) => {
    dispatch(createBookingRequesting());
    try {
      let suffixURL = isExistingCustomer ? "update" : "create";
      const result = await api.post(
        // `http://qa-apigw-hd.uk-e1.cloudhub.io/api/v1/Booking/${suffixURL}`,
        //`https://qa-offline-homedelivery-booking.uk-e1.cloudhub.io/api/v1/Booking/${suffixURL}`,
        `https://prod-home-delivery-booking-proxi.uk-e1.cloudhub.io/api/v1/Booking/${suffixURL}`,
        
        bookingData
      );

      let errorDetails = getError(result);

      if (errorDetails.hasError) {
        throw new Error(errorDetails.message);
      }
      storage.setCreateBookingData(result.data);
      dispatch(createBookingSuccess(result.data));
    } catch (e) {
      dispatch(createBookingFailure(e.message));
    }
  };
}

export const clearBookingStatus = () => ({
  type: CREATE_BOOKING_FAILURE
});
