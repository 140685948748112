import React from "react";
import PinDrop from "@material-ui/icons/PinDrop";

const DeliveryAddress = props => {
  var address = "";
  if (props.address && props.address.addressLine1) {
    address = address.concat(props.address.addressLine1);
  }
  if (props.address && props.address.district) {
    const appendString = props.address.addressLine1 && ",";
    address = address.concat(appendString).concat(props.address.district);
  }
  if (props.address && props.address.city) {
    const appendString = props.address.district && ",";
    address = address.concat(appendString).concat(props.address.city);
  }
  return (
    <div className="booking-summry-card-inner-panel pb15">
      <div className="card_icon">
        <PinDrop className="orderDetailIcon" />
      </div>
      <div className="card_content">
        <div className="Confirm_order_panel_value">
          {props.strings &&
            props.strings.confirmationForm.bookingSummary.deliveryAddress}
        </div>
        <div className="order_panel_subvalue">{address}</div>
        {props.address && props.address.what3words && (
          <p style={{ paddingTop: 10 }} className="mapModalDescription">
            <strong className="Confirm_order_panel_value">
              {props.strings && props.strings.customerForm.mapForm.what3Words}
            </strong>
            <br />
            {props.address.what3words}
          </p>
        )}
      </div>
    </div>
  );
};

export default DeliveryAddress;
