import React from "react";
import { TextareaAutosize } from "@material-ui/core";
import CardPanel from "./CardPanel";
import TextField from "../../../coreComponents/testFields/TextField";
import Dropdown from "../../../coreComponents/dropDown/Dropdown";
import RadioButtons from "../../../coreComponents/radio-buttons/RadioButtons";
import CheckBoxValue from "../../../coreComponents/check-box/CheckBoxValue";

const questionnaireType = {
  dropDown: "dropdown",
  checkbox: "checkbox",
  radio: "radio",
  textbox: "textbox"
};

class Site_info extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { siteForm, placeholderForm } = this.props;
    return (
      <CardPanel title={siteForm.title}>
        {/* <TextField
          title={siteForm.building}
          value={this.props.buildingNumber}
          placeholder={placeholderForm.buildingNumber}
          onChange={value => this.props.onChangeBuildingNumber(value)}
        />
        {this.props.errors.buildingNumber.length > 0 && (
          <span className="error">{this.props.errors.buildingNumber}</span>
        )}
        <Dropdown
          title={siteForm.buildingType}
          selectedValue={this.props.buildingType}
          placeholder={placeholderForm.dropdown}
          options={siteForm.buildingTypes}
          onChange={value => this.props.onChangeBuildingType(value)}
        />
        <Dropdown
          title={siteForm.floor}
          selectedValue={this.props.floor}
          placeholder={placeholderForm.dropdown}
          options={[{ value: "1" }, { value: "2" }, { value: "3" }]}
          onChange={value => this.props.onChangeFloor(value)}
        /> */}
        {this.getQuestionnaireView()}
        <TextareaAutosize
          value={this.props.otherComments}
          placeholder={placeholderForm.otherComments}
          className="CustomerInfo_Comments"
          aria-label="minimum height"
          rows={8}
          onChange={event => this.props.onChangeComments(event.target.value)}
        />
        {this.props.errors.otherComments.length > 0 && (
          <span className="error">{this.props.errors.otherComments}</span>
        )}
      </CardPanel>
    );
  }

  getQuestionnaireView() {
    const questionnaire =
      this.props.questionnaire &&
      this.props.questionnaire.map(item => {
        const selectedValue =
          this.props.questionnaireAnswers &&
          this.props.questionnaireAnswers.find(qns => qns.id == item.id);

        const idMatchedAns =
          selectedValue &&
          item.answers.find(qns => qns.id == selectedValue.answer);
        switch (item.type) {
          case questionnaireType.checkbox:
            return (
              <CheckBoxValue
                check={item.answers}
                title={item.title}
                selectedAnswers={idMatchedAns && idMatchedAns.value}
                onChange={value =>
                  this.props.onChangeQuestionnaire(item, value)
                }
              />
            );

          case questionnaireType.dropDown:
            return (
              <Dropdown
                title={item.title}
                placeholder={this.props.placeholderForm.dropdown}
                selectedValue={idMatchedAns && idMatchedAns.value}
                options={item.answers}
                onChange={value => this.onChangeQuationaireValue(item, value)}
              />
            );
          case questionnaireType.radio:
            return (
              <RadioButtons
                title={item.title}
                options={item.answers}
                selectedValue={idMatchedAns && idMatchedAns.value}
                onChange={value => this.onChangeQuationaireValue(item, value)}
              />
            );
          case questionnaireType.textbox:
            return (
              <TextField
                title={item.title}
                value={idMatchedAns && idMatchedAns.value}
                placeholder={"Please Enter"}
                onChange={value =>
                  this.props.onChangeQuestionnaire(item, value)
                }
              />
            );

          default:
        }
      });

    return questionnaire;
  }

  onChangeQuationaireValue(qun, value) {
    const selectedAnswerObj = qun.answers.find(
      ansObj => ansObj.value === value
    );
    this.props.onChangeQuestionnaire(qun, selectedAnswerObj.id);
  }
}

export default Site_info;
