import React from "react";
import MapModal from "./MapModal";
import CardPanel from "./CardPanel";
import AddressField from "./AddressField";
import Dropdown from "../../../coreComponents/dropDown/Dropdown";
import { addresses } from "../../../constants/json/AddressJson";

class Delivery_address extends React.Component {
  render() {
    const { deliveryAddressForm, placeholderForm } = this.props;

    const regionObj =
      this.props.region &&
      addresses.find(item => item.region === this.props.region);

    const matchedRegionObj = regionObj && regionObj.cities;

    const cities =
      matchedRegionObj &&
      matchedRegionObj.map(city => {
        return {
          value:
            this.props.language == "en" ? city.name.capitalize() : city.arName
        };
      });

    const cityObj =
      this.props.city &&
      matchedRegionObj &&
      matchedRegionObj.find(item =>
        this.props.language == "en"
          ? item.name.capitalize() === this.props.city.capitalize()
          : item.arName === this.props.city
      );

    const matchedCityObj = cityObj && cityObj.districts;

    const districts =
      matchedCityObj &&
      matchedCityObj.map(dist => {
        return {
          value:
            // this.props.language == "en" ? dist.name.capitalize() : dist.arName
            this.props.language == "en" ? dist.name : dist.arName

        };
      });

    return (
      <CardPanel title={deliveryAddressForm.title}>
        <AddressField
          title={deliveryAddressForm.address}
          address={this.props.location && this.props.location.addressLine1}
        />

        <MapModal
          mapForm={this.props.mapForm}
          region={this.props.region}
          language={this.props.language}
          selectedCity={this.props.city}
          onTap={this.props.onTap}
          title={deliveryAddressForm.pinOnMap}
          what3words={this.props.what3words}
          locationData={this.props.location}
          onChangeLocation={location => this.props.onChangeLocation(location)}
          onChangeCity={this.props.onChangeCity}
          onChangeDistrict={this.props.onChangeDistrict}
          onChangeLocationEn={this.props.onChangeLocationEn}
          onChangeW3w={this.props.onChangeW3w}
          btnClass=""
          alertMesg={deliveryAddressForm.alertMesg}
          mapButton={deliveryAddressForm.mapButton}
          exitMapAlertMesg={deliveryAddressForm.exitMapAlertMesg}
          alertButton={this.props.alertButton}
        />

        <MapModal
          mapForm={this.props.mapForm}
          region={this.props.region}
          language={this.props.language}
          onTap={this.props.onTap}
          selectedCity={this.props.city}
          title={deliveryAddressForm.what3Words}
          what3words={this.props.what3words}
          isWhat3words
          locationData={this.props.location}
          onChangeLocation={location => this.props.onChangeLocation(location)}
          onChangeCity={this.props.onChangeCity}
          onChangeDistrict={this.props.onChangeDistrict}
          onChangeLocationEn={this.props.onChangeLocationEn}
          onChangeW3w={this.props.onChangeW3w}
          btnClass="What3Words"
          alertMesg={deliveryAddressForm.alertMesg}
          mapButton={deliveryAddressForm.mapButton}
          exitMapAlertMesg={deliveryAddressForm.exitMapAlertMesg}
          alertButton={this.props.alertButton}
        />
        <Dropdown
          title={deliveryAddressForm.city}
          placeholder={placeholderForm.dropdown}
          selectedValue={this.props.city && this.props.city.capitalize()}
          options={cities}
          onChange={value => this.onSelectCity(value, matchedRegionObj)}
        />
        {this.props.city && cityObj && (
          <Dropdown
            title={deliveryAddressForm.district}
            placeholder={placeholderForm.dropdown}
            selectedValue={
              // this.props.district && this.props.district.capitalize()
              this.props.district
            }
            options={districts}
            onChange={value => this.onSelectDistrict(value, matchedCityObj)}
          />
        )}
      </CardPanel>
    );
  }

  onSelectCity = (city, matchedRegionObj) => {
    if (this.props.language == "en") {
      this.props.onChangeCity(city, city);
    } else {
      const cityObj =
        matchedRegionObj && matchedRegionObj.find(item => item.arName == city);

      this.props.onChangeCity(city, cityObj.name);
    }
  };

  onSelectDistrict = (district, matchedCityObjs) => {
    if (this.props.language == "en") {
      this.props.onChangeDistrict(district, district);
    } else {
      const districtObj =
        matchedCityObjs &&
        matchedCityObjs.find(item => item.arName == district);

      this.props.onChangeDistrict(district, districtObj.name);
    }
  };
}

export default Delivery_address;
