import React, { Component } from "react";
import BookingActionButton from "../../../coreComponents/button/Button";
import * as storage from "../../../utils/LocalStorage";
import "../styles/Confirmation.css";

export default class ErrorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createBookingResponse: this.props.createBookingData
    };
  }

  componentDidMount() {
    this.props.getLanguageForm();
    this.getLocalData();
    const { history } = this.props;
    window.onpopstate = function() {
      history.go(1);
    };
  }

  async getLocalData() {
    let persistData = await storage.getCreateBookingData();

    if (persistData) {
      try {
        this.setState({
          createBookingResponse: persistData
        });
      } catch (e) {
        // is not json
      }
    }
  }

  navigatToNext() {
    this.props.history.push("/deliveryInfo");
  }
  render() {
    const { strings } = this.props;
    return (
      <div className="emptyLayout">
        <div>
          <div>{this.state.createBookingResponse.statusMessage}</div>
        </div>

        <div>
          <BookingActionButton
            homeButton=""
            onClick={() => this.navigatToNext()}
          >
            {strings && strings.homeForm.tryAgain}
          </BookingActionButton>
        </div>
      </div>
    );
  }
}
