import React from "react";
import EventNote from "@material-ui/icons/EventNote";
import AirportShuttle from "@material-ui/icons/AirportShuttle";
import "../styles/return.css";
import { Card, Box, Typography, CardContent } from "@material-ui/core";
import moment from "moment/min/moment-with-locales";

class BookingDetailsBox extends React.Component {
  render() {
    const { strings } = this.props;

    return (
      <Box className="bookingDetailsdiv">
        <Box className="bookingDiv">
          <div className="BookinDetailsCard">
            <CardContent>
              <AirportShuttle className="eventNotestyle" />
              <Typography className="bookingnumtitle">
                {strings.thankYouForm.bookingNumber}
              </Typography>
              <Typography className="bookingNumber">{this.props.id}</Typography>
            </CardContent>
          </div>
        </Box>
        <Box className="deliveryDiv">
          <div className="BookinDetailsCard">
            <CardContent>
              <EventNote className="eventNotestyle" />
              <Typography className="bookingnumtitle">
                {strings.thankYouForm.deliveryTime}
              </Typography>
              <Typography className="bookingTime">
                {moment(this.props.date).format("dddd, DD MMMM YYYY")}
              </Typography>
              <Typography className="bookingTime">{this.props.time}</Typography>
            </CardContent>
          </div>
        </Box>
      </Box>
    );
  }
}

BookingDetailsBox.defaultProps = {
  id: "",
  date: ""
};

export default BookingDetailsBox;
