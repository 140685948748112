const PathNames = {
  DELIVERY_CALANDER: "/deliveryCalander",
  DELIVERY_INFO: "/deliveryInfo",
  CONFIRMATION: "/confirmation",
  CONFIRM_BOOKING: "/confirmBooking",
  EXISTING_CUSTOMER: "/existingCustomer",
  NEW_BOOKING: "/newBooking",
  TRACK_SHIPMENT: "/trackShipment",
  ERROR: "/error"
};

export default PathNames;
