import React from "react";

const OtherComponent = props => {
  return (
    <div className="site_content">
      {props.address && props.address.otherComments && (
        <div className="order_panel_subvalue">
          Other Comments:
          <br />
          {props.address.otherComments}
        </div>
      )}
    </div>
  );
};

export default OtherComponent;
