import React from "react";
import Timing from "../../customer/components/Timing";
import WelcomeBackUser from "./WelcomeBackUser";
import BookingDetailsBox from "./BookingDetailsBox";
import InfoMsg from "./infoMsg";
import ContactUs from "./ContactUs";
import BookingStatus from "./BookingStatus";
import { Fab, Box } from "@material-ui/core";
import "../styles/return.css";
import LoaderIndicator from "../../../coreComponents/loader/LoaderIndicator";
import { getLanguage } from "../../../utils/LocalStorage";
import { UNABLE_TO_DELIVER_MESG } from "../../../utils/Constants";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Clear from "@material-ui/icons/Clear";
import { getUrlVars } from "../../../utils/Validation";
import { setApiData } from "../../../utils/LocalStorage";
import BookingActionButton from "../../../coreComponents/button/Button";
import InfoBox from "../../../coreComponents/info-box/InfoBox";

export const FAILD_TO_DELIVER = "Faild To Deliver";

class UpdateBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: getLanguage()
    };
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    // this.props.getOrderData();
    var params = getUrlVars(window.location.href);
    console.log("params", params);
    this.setState({
      urlParams: params
    });
    await setApiData(params);
    this.props.getOrderData();
    this.props.getLanguageForm();
  }

  componentDidUpdate() {
    if (this.state.language !== getLanguage()) {
      this.setState({ language: getLanguage() });
      this.props.getLanguageForm();
    }
  }
  navigateToNext() {
    const { orderData } = this.props;
    if (orderData.booking.displaySettings.isInternalLink) {
      this.props.history.push("/deliveryInfo");
    } else {
      window.location.href = orderData.booking.displaySettings.link;
    }
  }
  navigateToUpdateBooking() {
    const { orderData, strings } = this.props;
    if (orderData.booking.displaySettings.isConfirmationRequired) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="custom-ui">
              <button className="close_Btn" onClick={onClose}>
                <Clear style={{ fontSize: "30px" }} />
              </button>
              <div className="custom-sub-ui">
                <h1>{strings && strings.calenderForm.alertHeader}</h1>
                <p>{orderData.booking.displaySettings.confirmationMessage}</p>
                <button
                  className="success_Btn"
                  onClick={() => {
                    this.navigateToNext();
                    onClose();
                  }}
                >
                  {strings && strings.calenderForm.alertButton}
                </button>
              </div>
            </div>
          );
        }
      });
    } else {
      this.navigateToNext();
    }
  }
  navigateToNewBooking() {
    const { strings } = this.props;
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <button className="close_Btn" onClick={onClose}>
              <Clear style={{ fontSize: "30px" }} />
            </button>
            <div className="custom-sub-ui">
              <h1>{strings && strings.calenderForm.alertHeader}</h1>
              <p>{strings && strings.existingCustomerForm.areYouSureMesg}</p>
              <button
                className="success_Btn"
                onClick={() => {
                  this.props.history.push("/deliveryInfo");
                  onClose();
                }}
              >
                {strings && strings.calenderForm.alertButton}
              </button>
            </div>
          </div>
        );
      }
    });
  }
  render() {
    if (this.props.orderLoading) {
      return <LoaderIndicator />;
    }
    const { orderData, strings } = this.props;
    return (
      <Box className="returnmainContainer">
        {orderData.contact && (
          <WelcomeBackUser
            strings={strings}
            name={orderData.contact.name}
            status={orderData.booking.statusMessage}
          />
        )}
        {orderData.booking && (
          <BookingStatus
            strings={strings}
            status={orderData.booking.statusCode}
          />
        )}
        {orderData.booking && orderData.booking && (
          <Timing
            strings={strings}
            value1={orderData.booking.progress[0]}
            value2={orderData.booking.progress[1]}
            value3={orderData.booking.progress[2]}
            value1OnTap={() => console.log("value1OnTap")}
            value2OnTap={() => console.log("value2OnTap")}
            value3OnTap={() => console.log("value3OnTap")}
          />
        )}
        {orderData.booking && (
          <BookingDetailsBox
            {...this.props}
            id={orderData.booking.id}
            date={orderData.booking.date}
            time={orderData.booking.time}
          />
        )}
        {orderData.booking.statusCode === FAILD_TO_DELIVER ? (
          // {orderData.booking.statusCode === orderData.booking.statusCode  ? (
          <div>
            <div className="failureMesg">
              <div>{UNABLE_TO_DELIVER_MESG}</div>
            </div>
            {/* <Fab
              variant="extended"
              aria-label="add"
              className="updatebutton"
              onClick={() => this.navigateToNewBooking()}
            >
              Start New Booking - {orderData.booking.rescheduleCharges} SR
            </Fab> */}

            <BookingActionButton
              homeButton=""
              onClick={() => this.navigateToNewBooking()}
            >
              Start New Booking - {orderData.booking.rescheduleCharges} SR
            </BookingActionButton>
          </div>
        ) : (
          <div>
            {orderData.booking.displaySettings.isVisible && (
              <div>
                {/* <Fab
                  variant="extended"
                  aria-label="add"
                  className="updatebutton"
                  onClick={() => this.navigateToUpdateBooking()}
                >
                  {orderData.booking.displaySettings.title}
                </Fab> */}

                <BookingActionButton
                  homeButton=""
                  onClick={() => this.navigateToUpdateBooking()}
                >
                  {orderData.booking.displaySettings.title}
                </BookingActionButton>

                {/* <InfoMsg
                  description={
                    strings &&
                    strings.existingCustomerForm.existingCustomer.description
                  }
                /> */}
                <InfoBox
                  message={
                    strings &&
                    strings.existingCustomerForm.existingCustomer.description
                  }
                />
              </div>
            )}
          </div>
        )}
        <ContactUs {...this.props} />
      </Box>
    );
  }
}

export default UpdateBooking;
