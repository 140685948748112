import * as homeActions from "../../../utils/Constants";
const reducer = (
  state = {
    orderLoading: false,
    orderData: [],
    orderStatus: "",
    orderError: null
  },
  action
) => {
  switch (action.type) {
    case homeActions.GET_ORDER_DATA_REQUESTING:
      return Object.assign({}, state, {
        orderLoading: true,
        orderStatus: action.status
      });
    case homeActions.GET_ORDER_DATA_SUCCESS: {
      return Object.assign({}, state, {
        orderLoading: false,
        orderData: action.data,
        orderStatus: action.status
      });
    }
    case homeActions.GET_ORDER_DATA_FAILURE: {
      return Object.assign({}, state, {
        orderLoading: false,
        orderStatus: action.status,
        orderError: action.error
      });
    }

    default:
      return state;
  }
};

export default reducer;
