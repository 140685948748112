import * as customerActions from "../actions/customer.actions";
const customer = (
  state = {
    addressLoading: false,
    customerAddress: [],
    addressStatus: "",
    addressError: null,

    CalendarDataLoading: false,
    CalanderData: {},
    CalendarDataStatus: "",
    CalendarDataError: null,
    preSetWindows: null
  },
  action
) => {
  switch (action.type) {
    case customerActions.ADD_CUSTOMER_ADDRESS_REQUESTING:
      return Object.assign({}, state, {
        addressLoading: true,
        addressStatus: action.status
      });
    case customerActions.ADD_CUSTOMER_ADDRESS_SUCCESS: {
      return Object.assign({}, state, {
        addressLoading: false,
        customerAddress: action.data,
        addressStatus: action.status
      });
    }
    case customerActions.ADD_CUSTOMER_ADDRESS_FAILURE: {
      return Object.assign({}, state, {
        addressLoading: false,
        addressStatus: action.status,
        addressError: action.error
      });
    }
    case customerActions.GET_CALENDAR_DATA_REQUESTING:
      return Object.assign({}, state, {
        CalendarDataLoading: true,
        CalendarDataStatus: action.status
      });
    case customerActions.GET_CALENDAR_DATA_SUCCESS: {
      return Object.assign({}, state, {
        CalendarDataLoading: false,
        CalanderData: action.data,
        CalendarDataStatus: action.status,
        preSetWindows: action.preSetWindows
      });
    }
    case customerActions.GET_CALENDAR_DATA_FAILURE: {
      return Object.assign({}, state, {
        CalendarDataLoading: false,
        CalendarDataStatus: action.status,
        CalendarDataError: action.error
      });
    }

    default:
      return state;
  }
};

export default customer;
