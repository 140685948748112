import React from "react";
import Phone from "@material-ui/icons/Phone";
import { ListItemAvatar, Typography } from "@material-ui/core";
import "../styles/return.css";

class ContactUs extends React.Component {
  render() {
    const { strings } = this.props;
    return (
      <div className="contactUsmain">
        {strings.thankYouForm && (
          <Typography className="contactusTitle">
            {strings.thankYouForm.contactUs}
          </Typography>
        )}
        <div className="contactuslist">
          <ListItemAvatar>
            <Phone className="contactusphone" />
          </ListItemAvatar>
          <div className="tollNumberContainer">
            <span className="contactusnumber">{strings.thankYouForm.toll}</span>
            <div>{strings.thankYouForm.available}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;
