import React, { Component } from "react";
import Banner from "../components/Banner";
import OrderDetail from "../components/OrderDetail";
import OrderList from "./OrderList";
import BookingActionButton from "../../../coreComponents/button/Button";
import { getLanguage } from "../../../utils/LocalStorage";
import LoaderIndicator from "../../../coreComponents/loader/LoaderIndicator";
import { getUrlVars } from "../../../utils/Validation";
import errorImage from "../../../assets/image/problem.png";
import { Fab } from "@material-ui/core";
import {
  setApiData,
  getApiData,
  setLanguage
} from "../../../utils/LocalStorage";
import moment from "moment/min/moment-with-locales";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      language: getLanguage(),
      urlParams: ""
    };
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    var params = getUrlVars(window.location.href);
    var localStorageParams = await getApiData();
    if (
      localStorageParams &&
      localStorageParams.orderNumber !== params.orderNumber
    ) {
      localStorage.clear();
    }
    console.log("params", params);
    this.setState({
      urlParams: params
    });
    moment.locale(params.language);
    await setApiData(params);
    setLanguage(params.language);
    this.props.getOrderData();
    this.props.getLanguageForm();
  }

  componentDidUpdate() {
    if (this.state.language !== getLanguage()) {
      this.setState({ language: getLanguage() });
      this.props.getLanguageForm();
    }
  }

  navigateToNext() {
    this.props.history.push("/deliveryInfo");
  }

  renderContent() {
    const { orderData, strings } = this.props;
    console.log("urlParams==", this.state.urlParams);
    if (
      Object.values(this.state.urlParams).length > 0 &&
      strings &&
      strings.homeForm &&
      orderData.isSucess
    ) {
      return (
        <div>
          <div className="HomecontainerSection">
            <Banner />
            <OrderDetail
              orderDetailsForm={strings.homeForm.orderDetails}
              orderData={orderData}
            />
            <OrderList
              listForm={strings.homeForm.items}
              products={orderData.lines}
            />
            <div style={{ padding: "16px" }}>
              <BookingActionButton
                homeButton="stickyClass"
                onClick={() => this.navigateToNext()}
              >
                {strings.homeForm.button}
              </BookingActionButton>
            </div>
          </div>
          {/* <div style={{padding: '16px'}}>
           <Fab
              variant="extended"
              className=""
              style={{
                width: '100%',
                backgroundColor: '#ffe700',
                color:'#388083',
                fontWeight:'700',
                fontSize: '14px',
                textTransform: 'none'
              }}
              onClick={() => this.navigateToNext()}
            >
              {strings.homeForm.button}
            </Fab>
            </div> */}
        </div>
      );
    } else {
      const message =
        !Object.values(this.state.urlParams).length > 0
          ? strings && strings.homeForm.invalidUrl
          : this.props.orderError;
      return (
        <div className="emptyLayout">
          <img src={errorImage} alt="Error icon" width="60%" />
          <h5 style={{ paddingTop: "50px" , textAlign:'center'}}>{message}</h5>
        </div>
      );
    }
  }

  render() {
    if (this.props.orderLoading) {
      return <LoaderIndicator />;
    }

    return <main className="MainContainer">{this.renderContent()}</main>;
  }
}

export default Home;
