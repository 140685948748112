import React from "react";
import {
  Checkbox,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";

export default class RadioButtons extends React.Component {
  handleChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }
  render() {
    const buttonType = this.props.isRadio ? (
      <Radio color="primary" />
    ) : (
      <Checkbox value="gilad" color="primary" />
    );
    return (
      <div className="textFieldContainer">
        <FormControl component="fieldset">
          <FormLabel
            component="legend"
            style={{
              marginBottom: "10px",
              fontWeight: "600",
              color: "black"
            }}
          >
            {this.props.title}
          </FormLabel>
          <FormGroup>
            {this.props.options &&
              this.props.options.map((item, i) => (
                <FormControlLabel
                  key={i}
                  checked={item.value === this.props.selectedValue}
                  control={buttonType}
                  label={item.value}
                  onChange={() => this.handleChange(item.value)}
                />
              ))}
          </FormGroup>
        </FormControl>
      </div>
    );
  }
}

RadioButtons.defaultProps = {
  isRadio: true
};
