import React from "react";
import "./InfoBox.css"
import Info from "@material-ui/icons/Info";

import {
  ListItemAvatar,
  ListItemText,
  List,
  ListItem,
  Box,
  Icon
} from "@material-ui/core";

class InfoBox extends React.Component {
  render() {
    return (
    //   <Box>
    //     <Box>
    //       <List>
    //         <ListItem className="infomsglist">
    //           <ListItemAvatar className="infomsglistavtar">
    //             <Info className="infoIconstyle" />
    //           </ListItemAvatar>
    //           <ListItemText className="infoText">
    //             {this.props.description}
    //           </ListItemText>
    //         </ListItem>
    //       </List>
    //     </Box>
    //   </Box>
    <div className='box-container'>
        <Info className='box-icon'/>
        <label className='box-message'>{this.props.message}</label>
    </div>
    );
  }
}

export default InfoBox;
