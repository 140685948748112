import { connect } from "react-redux";
import ConfirmationDetails from "../components/ConfirmationDetails";
import {
  createBooking,
  clearBookingStatus
} from "../actions/confirmation.actions";
import { getLanguageForm } from "../../global/actions/global.actions";

const mapStateToProps = state => {
  return {
    customerAddress: state.customer.customerAddress,
    createBookingLoading: state.book.createBookingLoading,
    createBookingData:
      state.book.createBookingData && state.book.createBookingData,
    createBookingStatus: state.book.createBookingStatus,
    orderData: state.orderInquiry.OrderInquiryData,
    strings: state.languageForm.strings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    createBooking: (data, isExistingCustomer) =>
      dispatch(createBooking(data, isExistingCustomer)),
    clearBookingStatus: () => dispatch(clearBookingStatus()),
    getLanguageForm: () => dispatch(getLanguageForm())
  };
};

const ConfirmationDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationDetails);

export default ConfirmationDetailsContainer;
