import React from "react";
import { Typography } from "@material-ui/core";
import Images from "../../../constants/Images";
import "../styles/CustomerStyles.css";

class AddressField extends React.Component {
  render() {
    return (
      <div className="textFieldContainer">
        <div className="addressFieldSection">
          <Typography className="cusaddresstitle">
            {this.props.title}
          </Typography>
          <img
            alt=""
            src={Images.currentLocationIcon}
            style={{ height: 20, width: 20 }}
          />
        </div>

        <Typography className="Displaycusaddress">
          {this.props.address}
        </Typography>
      </div>
    );
  }
}

AddressField.defaultProps = {
  isEditOptionRequired: false
};

export default AddressField;
