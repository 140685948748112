import React, { Component } from "react";
import constants from "../../constants";
import * as storage from "../../utils/LocalStorage";
import { getUrlVars } from "../../utils/Validation";
import "./HeaderStyles.css";

class Header extends Component {
  // constructor(props){
  //   super(props);
  //   this.state = {
  //     isEditing: false
  //   }
  //   storage.setEditingStatus(false);
  //   console.log('setting state for header...' + this.state.isEditing);
  
  // }

  async languageHandler(target) {
    let params = getUrlVars(window.location.href);
    let keys = Object.keys(params);
    let languageStr = "";
    let href = "";

    if (keys.length > 0) {
      params["language"] = params["language"] === "en" ? "ar" : "en";
      let urlStrArr = keys.map(key => `${key}=${params[key]}`);
      let url = "?" + urlStrArr.join("&");
      languageStr = params["language"];
      href = "/" + url;
    } else {
      languageStr = storage.getLanguage() === "en" ? "ar" : "en";
      let urlParams = await storage.getApiData();
      urlParams["language"] = languageStr;
      storage.setApiData(urlParams);
    }
    storage.setLanguage(languageStr);
    target.href = href;
  }

  async componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('storage', this.localStorageUpdated)
    }
    let params = getUrlVars(window.location.href);
    let lang = params.language;
    if (!lang) {
      lang = await storage.getLanguage();
    }
    const html = document.querySelector("html");

    if (!lang || lang === "en") {
      localStorage.setItem("lang", "en");
      html.setAttribute("dir", "ltr");
      html.setAttribute("lang", "en");
    } else {
      html.setAttribute("dir", "rtl");
      html.setAttribute("lang", "ar");
    }
  }

  // localStorageUpdated = () => {
  //   this.setState({isEditing: storage.isEditing()});
  //   console.log('storage has been updated....... isediting value is: ' + this.state.isEditing);
  // }

  render() {
    return (
      <div className="header">
        <div className="bg-blue">
          <div>
            <img
              alt=""
              src={constants.Image.headerLogo}
              className="logoimageStyle"
            />
          </div>
          <div>
      
            {/* <a style={{visibility: this.state.isEditing ? 'hidden' : 'visible'} } onClick={event => this.languageHandler(event.target)}> */}
            {/* {this.state.isEditing === 'false' ? <a onClick={event => this.languageHandler(event.target)}>
              {storage.getLanguage() === "en" ? "عربى" : "English"}
            </a> : null} */}
            <a onClick={event => this.languageHandler(event.target)}>
              {storage.getLanguage() === "en" ? "عربى" : "English"}
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
