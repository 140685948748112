import React from "react";
import styles from "./LoaderIndicator.module.css";
export default class LoaderIndicator extends React.Component {
  render() {
    if (this.props.display) {
      return (
        <div className={styles.base}>
          <div className={styles.loader} />
        </div>
      );
    } else {
      return null;
    }
  }
}

LoaderIndicator.defaultProps = {
  display: true
};
