import { connect } from "react-redux";
import FinalPage from "../components/FinalPage";
import { getOrderEnquiryData } from "../../return/actions/return.action";

import { getLanguageForm } from "../../global/actions/global.actions";

const mapStateToProps = state => {
  return {
    orderLoading: state.orderInquiry.OrderInquiryDataLoading,
    orderData: state.orderInquiry.OrderInquiryData,
    orderStatus: state.orderInquiry.OrderInquiryDataStatus,
    orderError: state.orderInquiry.OrderInquiryDataError,
    strings: state.languageForm.strings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLanguageForm: () => dispatch(getLanguageForm()),
    getOrderData: () => dispatch(getOrderEnquiryData())
  };
};

const FinalPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FinalPage);

export default FinalPageContainer;
