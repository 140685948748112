import { SUCCESS, REQUESTING, ERROR } from "../../../utils/Constants";
import { getError } from "../../../utils/ErrorHandler";
import { getApiData } from "../../../utils/LocalStorage";

export const GET_ORDER_INQUIRY_DATA_REQUESTING =
  "GET_ORDER_INQUIRY_DATA_REQUESTING";
export const GET_ORDER_INQUIRY_DATA_SUCCESS = "GET_ORDER_INQUIRY_DATA_SUCCESS";
export const GET_ORDER_INQUIRY_DATA_FAILURE = "GET_ORDER_INQUIRY_DATA_FAILURE";

export const getOrderInquiryDataRequesting = () => ({
  type: GET_ORDER_INQUIRY_DATA_REQUESTING,
  status: REQUESTING
});

export const getOrderInquiryDataSuccess = data => ({
  type: GET_ORDER_INQUIRY_DATA_SUCCESS,
  status: SUCCESS,
  data
});

export const getOrderInquiryDataFailure = error => ({
  type: GET_ORDER_INQUIRY_DATA_FAILURE,
  status: ERROR,
  error
});

export function getOrderEnquiryData() {
  return async (dispatch, getState, api) => {
    var apiData = await getApiData();
    dispatch(getOrderInquiryDataRequesting());
    try {
      const result = await api.post(
        // "http://qa-apigw-hd.uk-e1.cloudhub.io/api/v1/order",
        //"https://qa-offline-homedelivery-booking.uk-e1.cloudhub.io/api/v1/order",
        "https://prod-home-delivery-booking-proxi.uk-e1.cloudhub.io/api/v1/order",
        apiData
      );

      let errorDetails = getError(result);

      if (errorDetails.hasError) {
        throw new Error(errorDetails.message);
      }
      console.log(result.data);
      dispatch(getOrderInquiryDataSuccess(result.data));
    } catch (e) {
      dispatch(
        getOrderInquiryDataFailure(e.response && e.response.data.statusMessage)
      );
    }
  };
}
