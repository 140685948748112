import React from "react";
import { Divider } from "@material-ui/core";
import "../styles/return.css";

class WelcomeBackUser extends React.Component {
  render() {
    const { strings } = this.props;
    return (
      <div>
        <div>
          <div>
            {strings &&
              strings.existingCustomerForm.existingCustomer.welcomeBack}{" "}
            <b>{this.props.name}</b>
          </div>
          <p className="confirmBookpara">{this.props.status}</p>
        </div>
        <Divider />
      </div>
    );
  }
}

export default WelcomeBackUser;
