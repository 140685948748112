import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import AppContainer from "./AppContainer";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import configureStore from "./configuration/configureStore";

const getstore = configureStore();

ReactDOM.hydrate(
  <Provider store={getstore}>
    <BrowserRouter>
      <AppContainer />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
