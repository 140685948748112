const polygons = [
  [
    { lat: 27.379291586638572, lng: 48.841094970703125 },
    { lat: 27.395143394691445, lng: 49.02099609375 },
    { lat: 27.343921676380333, lng: 49.0264892578125 },
    { lat: 27.401239638884036, lng: 49.16107177734375 },
    { lat: 27.410992930231465, lng: 49.207763671875 },
    { lat: 27.425621252939706, lng: 49.28741455078125 },
    { lat: 27.401239638884036, lng: 49.306640625 },
    { lat: 27.359778550684865, lng: 49.310760498046875 },
    { lat: 27.295117062148176, lng: 49.31488037109375 },
    { lat: 27.362217868304448, lng: 49.483795166015625 },
    { lat: 27.365876743963476, lng: 49.55657958984375 },
    { lat: 27.342701822798272, lng: 49.623870849609375 },
    { lat: 27.312201119735906, lng: 49.68841552734375 },
    { lat: 27.28291255417328, lng: 49.689788818359375 },
    { lat: 27.21698504972909, lng: 49.604644775390625 },
    { lat: 27.17667679731366, lng: 49.607391357421875 },
    { lat: 27.138798201942982, lng: 49.61700439453125 },
    { lat: 27.12046523891622, lng: 49.604644775390625 },
    { lat: 27.08501299098819, lng: 49.626617431640625 },
    { lat: 27.0721251598632, lng: 49.632110595703125 },
    { lat: 27.02564816544512, lng: 49.66094970703125 },
    { lat: 26.987718472315002, lng: 49.678802490234375 },
    { lat: 26.966912886541404, lng: 49.689788818359375 },
    { lat: 26.93018776925245, lng: 49.7296142578125 },
    { lat: 26.895900200560923, lng: 49.810638427734375 },
    { lat: 26.875077668179354, lng: 49.82025146484375 },
    { lat: 26.86459147153855, lng: 49.763946533203125 },
    { lat: 26.830283995941652, lng: 49.681549072265625 },
    { lat: 26.787385036545824, lng: 49.55108642578125 },
    { lat: 26.760411672170523, lng: 49.371185302734375 },
    { lat: 26.76949748311922, lng: 49.088287353515625 },
    { lat: 26.789616181472066, lng: 48.9825439453125 },
    { lat: 27.379291586638572, lng: 48.841094970703125 }
  ],
  [
    { lat: 26.164056, lng: 50.070205 },
    { lat: 26.033793, lng: 50.140757 },
    { lat: 26.129924, lng: 50.163331 },
    { lat: 26.212425, lng: 50.167365 },
    { lat: 26.162823, lng: 50.199294 },
    { lat: 26.185008, lng: 50.22058 },
    { lat: 26.250305, lng: 50.23088 },
    { lat: 26.31495, lng: 50.237059 },
    { lat: 26.391245, lng: 50.235686 },
    { lat: 26.442285, lng: 50.201353 },
    { lat: 26.467029, lng: 50.212511 },
    { lat: 26.495992, lng: 50.221524 },
    { lat: 26.518804, lng: 50.190024 },
    { lat: 26.482395, lng: 50.182299 },
    { lat: 26.454965, lng: 50.173631 },
    { lat: 26.423225, lng: 50.160155 },
    { lat: 26.433524, lng: 50.13226 },
    { lat: 26.493611, lng: 50.132518 },
    { lat: 26.496261, lng: 50.121338 },
    { lat: 26.480474, lng: 50.118399 },
    { lat: 26.456886, lng: 50.111146 },
    { lat: 26.479442, lng: 50.094041 },
    { lat: 26.477411, lng: 50.072817 },
    { lat: 26.514095, lng: 50.04404 },
    { lat: 26.558232, lng: 50.023471 },
    { lat: 26.547767, lng: 50.036525 },
    { lat: 26.544672, lng: 50.052326 },
    { lat: 26.547082, lng: 50.08118 },
    { lat: 26.572503, lng: 50.087483 },
    { lat: 26.597919, lng: 50.089667 },
    { lat: 26.598773, lng: 50.062668 },
    { lat: 26.593487, lng: 50.042535 },
    { lat: 26.589429, lng: 50.020342 },
    { lat: 26.653082, lng: 50.013709 },
    { lat: 26.660396, lng: 49.98806 },
    { lat: 26.673846, lng: 49.989877 },
    { lat: 26.695832, lng: 49.988018 },
    { lat: 26.734886, lng: 49.991167 },
    { lat: 26.677944, lng: 50.038646 },
    { lat: 26.683569, lng: 50.11771 },
    { lat: 26.771475, lng: 50.034827 },
    { lat: 26.810909, lng: 50.004129 },
    { lat: 26.836852, lng: 49.969311 },
    { lat: 26.792049, lng: 49.943427 },
    { lat: 26.700848, lng: 49.901665 },
    { lat: 26.689885, lng: 49.894443 },
    { lat: 26.680072, lng: 49.889882 },
    { lat: 26.65676, lng: 49.861706 },
    { lat: 26.613037, lng: 49.839771 },
    { lat: 26.503443, lng: 49.78353 },
    { lat: 26.397395, lng: 49.809622 },
    { lat: 26.195483, lng: 49.934249 },
    { lat: 25.918516, lng: 49.984889 },
    { lat: 25.911491, lng: 50.044777 },
    { lat: 25.930211, lng: 50.127593 },
    { lat: 25.974143, lng: 50.11407 },
    { lat: 26.010297, lng: 50.04551 },
    { lat: 25.986407, lng: 50.00505 },
    { lat: 26.036172, lng: 50.003359 },
    { lat: 26.082216, lng: 50.011282 },
    { lat: 26.096168, lng: 50.003879 },
    { lat: 26.105186, lng: 49.982743 },
    { lat: 26.195483, lng: 50.01905 },
    { lat: 26.210731, lng: 50.03201 },
    { lat: 26.197639, lng: 50.044284 }
  ],
  [
    { lat: 25.341253, lng: 49.449483 },
    { lat: 25.26676, lng: 49.485188 },
    { lat: 25.261794, lng: 49.678136 },
    { lat: 25.278558, lng: 49.801044 },
    { lat: 25.502494, lng: 49.794178 },
    { lat: 25.618949, lng: 49.660969 },
    { lat: 25.628855, lng: 49.604665 },
    { lat: 25.63257, lng: 49.549045 },
    { lat: 25.619568, lng: 49.526386 },
    { lat: 25.60285, lng: 49.529133 },
    { lat: 25.541531, lng: 49.521579 },
    { lat: 25.496916, lng: 49.479007 }
  ],
  [
    { lat: 26.405769, lng: 43.823734 },
    { lat: 26.242678, lng: 43.919865 },
    { lat: 26.243293, lng: 44.002262 },
    { lat: 26.275314, lng: 44.044834 },
    { lat: 26.307942, lng: 44.068867 },
    { lat: 26.362711, lng: 44.05994 },
    { lat: 26.406384, lng: 44.052387 },
    { lat: 26.448197, lng: 44.001576 },
    { lat: 26.5072, lng: 43.903385 },
    { lat: 26.512116, lng: 43.873173 },
    { lat: 26.511501, lng: 43.86974 },
    { lat: 26.456803, lng: 43.826481 }
  ],
  [
    { lat: 24.208046, lng: 47.378236 },
    { lat: 24.203663, lng: 47.390253 },
    { lat: 24.19051, lng: 47.409994 },
    { lat: 24.180018, lng: 47.403986 },
    { lat: 24.181897, lng: 47.389738 },
    { lat: 24.183307, lng: 47.376176 },
    { lat: 24.174224, lng: 47.371542 },
    { lat: 24.159502, lng: 47.378236 },
    { lat: 24.15449, lng: 47.381498 },
    { lat: 24.159815, lng: 47.403986 },
    { lat: 24.138512, lng: 47.395746 },
    { lat: 24.133499, lng: 47.371027 },
    { lat: 24.120339, lng: 47.354719 },
    { lat: 24.134283, lng: 47.334291 },
    { lat: 24.144622, lng: 47.326051 },
    { lat: 24.142585, lng: 47.318327 },
    { lat: 24.12645, lng: 47.320043 },
    { lat: 24.112662, lng: 47.305624 },
    { lat: 24.112035, lng: 47.292921 },
    { lat: 24.097933, lng: 47.30425 },
    { lat: 24.090725, lng: 47.304937 },
    { lat: 24.059222, lng: 47.309572 },
    { lat: 24.059536, lng: 47.287084 },
    { lat: 24.076307, lng: 47.289659 },
    { lat: 24.075679, lng: 47.245542 },
    { lat: 24.070664, lng: 47.220136 },
    { lat: 24.084769, lng: 47.181512 },
    { lat: 24.171717, lng: 47.130357 },
    { lat: 24.229196, lng: 47.155202 },
    { lat: 24.247432, lng: 47.159408 },
    { lat: 24.282095, lng: 47.166704 },
    { lat: 24.280218, lng: 47.24172 },
    { lat: 24.27834, lng: 47.250818 },
    { lat: 24.267699, lng: 47.271589 },
    { lat: 24.27302, lng: 47.278799 },
    { lat: 24.288403, lng: 47.277161 },
    { lat: 24.291298, lng: 47.323252 },
    { lat: 24.255776, lng: 47.366168 },
    { lat: 24.255776, lng: 47.384192 },
    { lat: 24.23895, lng: 47.39947 },
    { lat: 24.228932, lng: 47.404448 },
    { lat: 24.211398, lng: 47.380673 },
    { lat: 24.211398, lng: 47.380673 }
  ],
  [
    { lat: 24.606929, lng: 46.454087 },
    { lat: 24.465995, lng: 46.591502 },
    { lat: 24.467213, lng: 46.937908 },
    { lat: 24.469244, lng: 46.961254 },
    { lat: 24.481275, lng: 46.97224 },
    { lat: 24.660607, lng: 46.94127 },
    { lat: 24.677476, lng: 46.938084 },
    { lat: 24.814669, lng: 46.91046 },
    { lat: 24.95949, lng: 46.858017 },
    { lat: 24.967971, lng: 46.854584 },
    { lat: 25.008862, lng: 46.799449 },
    { lat: 25.020062, lng: 46.766319 },
    { lat: 25.015707, lng: 46.748466 },
    { lat: 24.989178, lng: 46.672916 },
    { lat: 24.982643, lng: 46.661415 },
    { lat: 24.88256, lng: 46.545926 },
    { lat: 24.872437, lng: 46.537342 },
    { lat: 24.742171, lng: 46.496659 },
    { lat: 24.643757, lng: 46.474514 },
    { lat: 24.611768, lng: 46.474514 }
  ],
  [
    { lat: 26.185796, lng: 44.009813 },
    { lat: 26.167925, lng: 44.095644 },
    { lat: 26.050776, lng: 44.145769 },
    { lat: 25.971788, lng: 44.058565 },
    { lat: 26.003265, lng: 43.980974 },
    { lat: 26.105666, lng: 43.842959 },
    { lat: 26.174088, lng: 43.941149 },
    { lat: 26.179634, lng: 43.987841 }
  ],
  [
    { lat: 21.660264, lng: 39.715598 },
    { lat: 21.66154, lng: 39.677146 },
    { lat: 21.659307, lng: 39.660323 },
    { lat: 21.635054, lng: 39.64968 },
    { lat: 21.603776, lng: 39.670966 },
    { lat: 21.573767, lng: 39.675773 },
    { lat: 21.444484, lng: 39.620841 },
    { lat: 21.287898, lng: 39.64968 },
    { lat: 21.269823, lng: 40.003989 },
    { lat: 21.479631, lng: 40.051625 },
    { lat: 21.516765, lng: 40.045015 },
    { lat: 21.547664, lng: 39.918244 },
    { lat: 21.626757, lng: 39.822371 },
    { lat: 21.631544, lng: 39.805549 },
    { lat: 21.631544, lng: 39.805549 }
  ],
  [
    { lat: 24.509568, lng: 39.740274 },
    { lat: 24.540725, lng: 39.774006 },
    { lat: 24.574372, lng: 39.764479 },
    { lat: 24.606996, lng: 39.661138 },
    { lat: 24.557668, lng: 39.634531 },
    { lat: 24.571563, lng: 39.604318 },
    { lat: 24.567347, lng: 39.553335 },
    { lat: 24.507226, lng: 39.47643 },
    { lat: 24.474499, lng: 39.45626 },
    { lat: 24.437387, lng: 39.456175 },
    { lat: 24.357658, lng: 39.470423 },
    { lat: 24.320747, lng: 39.569987 },
    { lat: 24.325126, lng: 39.67985 },
    { lat: 24.368291, lng: 39.764307 },
    { lat: 24.478952, lng: 39.746455 },
    { lat: 24.499573, lng: 39.734095 }
  ],
  [
    { lat: 21.378122, lng: 39.176397 },
    { lat: 21.515876, lng: 39.150045 },
    { lat: 21.525631, lng: 39.121763 },
    { lat: 21.544966, lng: 39.113394 },
    { lat: 21.591957, lng: 39.105837 },
    { lat: 21.704311, lng: 39.084463 },
    { lat: 21.750251, lng: 39.048799 },
    { lat: 21.818489, lng: 39.027555 },
    { lat: 21.840337, lng: 39.130419 },
    { lat: 21.845293, lng: 39.211311 },
    { lat: 21.780246, lng: 39.246927 },
    { lat: 21.689628, lng: 39.245374 },
    { lat: 21.514128, lng: 39.306213 },
    { lat: 21.473352, lng: 39.313801 },
    { lat: 21.412269, lng: 39.464211 },
    { lat: 21.373735, lng: 39.455742 },
    { lat: 21.353417, lng: 39.318526 },
    { lat: 21.256471, lng: 39.310169 },
    { lat: 21.26481, lng: 39.270043 },
    { lat: 21.26995, lng: 39.231978 },
    { lat: 21.26775, lng: 39.153442 },
    { lat: 21.309293, lng: 39.132212 },
    { lat: 21.319527, lng: 39.123629 },
    { lat: 21.322604, lng: 39.121346 },
    { lat: 21.327571, lng: 39.122277 },
    { lat: 21.335416, lng: 39.126641 },
    { lat: 21.338304, lng: 39.132036 },
    { lat: 21.355736, lng: 39.160568 }
  ]
];

export default polygons;
