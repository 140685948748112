import { connect } from "react-redux";
import DeliveryInfo from "../components/Customer";
import { addCustomerAddress } from "../actions/customer.actions";
import { getLanguageForm } from "../../global/actions/global.actions";
import { getOrderEnquiryData } from "../../return/actions/return.action";

const mapStateToProps = state => {
  return {
    customerAddress: state.customer.customerAddress,
    orderLoading: state.orderInquiry.OrderInquiryDataLoading,
    orderData: state.orderInquiry.OrderInquiryData,
    orderStatus: state.orderInquiry.OrderInquiryDataStatus,
    orderError: state.orderInquiry.OrderInquiryDataError,
    strings: state.languageForm.strings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLanguageForm: () => dispatch(getLanguageForm()),
    getOrderData: () => dispatch(getOrderEnquiryData()),
    addCustomerAddress: data => dispatch(addCustomerAddress(data))
  };
};

const DeliveryInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DeliveryInfo);

export default DeliveryInfoContainer;
