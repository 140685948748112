import { connect } from "react-redux";
import { getOrderEnquiryData } from "../actions/return.action";
import UpdateBooking from "../components/UpdateBooking";
import { getLanguageForm } from "../../global/actions/global.actions";

const mapStateToProps = state => {
  return {
    orderLoading: state.orderInquiry.OrderInquiryDataLoading,
    orderData: state.orderInquiry.OrderInquiryData,
    orderStatus: state.orderInquiry.OrderInquiryDataStatus,
    orderError: state.orderInquiry.OrderInquiryDataError,
    strings: state.languageForm.strings
  };
};

const mapdispatchToProps = dispatch => {
  return {
    getLanguageForm: () => dispatch(getLanguageForm()),
    getOrderData: () => dispatch(getOrderEnquiryData())
  };
};

export default connect(
  mapStateToProps,
  mapdispatchToProps
)(UpdateBooking);
