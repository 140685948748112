import React from "react";
import "./ModalStyles.css";
import Clear from "@material-ui/icons/Clear";

export default class Modal extends React.Component {
  handleClick() {
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
  }

  render() {
    const showHideClassName = this.props.show
      ? "base display-block"
      : "base display-none";
    return (
      <div className={showHideClassName}>
        <div className="background">
          <button onClick={() => this.handleClick()} className="close_Btn">
            <Clear style={{ fontSize: "30px" }} />
          </button>
          {this.props.children}
        </div>
      </div>
    );
  }
}
