import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import * as api from "../utils/ApiRequest";
import rootReducer from "./rootReducer";

const middleWare = [thunk.withExtraArgument(api)];

export default function configureStore() {
  return createStore(rootReducer, applyMiddleware(...middleWare));
}
