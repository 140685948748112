import { connect } from "react-redux";
import Delivery from "../components/DeliveryCalender";
import { getCalanderData } from "../actions/customer.actions";
import { getLanguageForm } from "../../global/actions/global.actions";

const mapStateToProps = state => {
  return {
    CalendarDataLoading: state.customer.CalendarDataLoading,
    CalanderData: state.customer.CalanderData,
    CalendarDataStatus: state.customer.CalendarDataStatus,
    preSetWindows: state.customer.preSetWindows && state.customer.preSetWindows,
    orderData: state.orderInquiry.OrderInquiryData,
    strings: state.languageForm.strings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getCalanderData: (fromIndex, toIndex) =>
      dispatch(getCalanderData(fromIndex, toIndex)),
    getLanguageForm: () => dispatch(getLanguageForm())
  };
};

const DeliveryCalanderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Delivery);

export default DeliveryCalanderContainer;
