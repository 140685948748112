import React from "react";
import CardPanel from "./CardPanel";
import TextField from "../../../coreComponents/testFields/TextField";

class Contact_info extends React.Component {
  onChangeMobile = value => {
    this.props.onChangeMobile(value);
  };
  onChangeEmail = value => {
    this.props.onChangeEmail(value);
  };
  onChangeSomeoneMobile = value => {
    this.props.onChangeSomeoneMobile(value);
  };
  render() {
    const { contactInfoForm, placeholderForm, mobileCheckData } = this.props;
    const mobileCountryCode = mobileCheckData
      ? mobileCheckData.mobileCountryCode
      : "";
    const mobileFieldMaxLength = mobileCheckData
      ? mobileCheckData.mobileLength
      : 12;
    return (
      <CardPanel title={contactInfoForm.title}>
        <TextField
          {...this.props}
          title={contactInfoForm.customerName}
          value={this.props.name}
          placeholder={placeholderForm.name}
          onChange={value => this.props.onChangeName(value)}
        />
        {this.props.errors.customerName.length > 0 && (
          <span className="error">{this.props.errors.customerName}</span>
        )}
        <TextField
          {...this.props}
          title={contactInfoForm.mobile}
          value={this.props.mobile}
          type="number"
          pattern="\d*"
          maxLength={mobileFieldMaxLength}
          placeholder={mobileCountryCode + "XXXXXXXXX"}
          onChange={value => this.onChangeMobile(value)}
          onlyNumber={true}
        />
        {this.props.errors.mobile.length > 0 && (
          <span className="error">
            {this.props.errors.mobile}
            <br />
          </span>
        )}

        <TextField
          {...this.props}
          title={contactInfoForm.email}
          value={this.props.email}
          placeholder={placeholderForm.email}
          onChange={value => this.props.onChangeEmail(value)}
        />
        {this.props.errors.email.length > 0 && (
          <span className="error">{this.props.errors.email}</span>
        )}
        {/* Uncomment this code if you need "isSomeOneElseToReceive" option}
        {/* <FormControlLabel
          checked={this.props.isSomeOneElseToReceive}
          control={<Checkbox value="gilad" color="primary" />}
          label={contactInfoForm.someoneElseReceive}
          onChange={event =>
            this.props.onChangeReceiver(!this.props.isSomeOneElseToReceive)
          }
        /> */}
        {this.props.isSomeOneElseToReceive && (
          <div>
            <TextField
              {...this.props}
              title={contactInfoForm.someoneCustomerName}
              value={this.props.someoneName}
              placeholder={"enter your name please"}
              onChange={value => this.props.onChangeSomeoneName(value)}
              onlyAlphabet={true}
            />
            {this.props.errors.someoneName.length > 0 && (
              <span className="error">{this.props.errors.someoneName}</span>
            )}
            <TextField
              {...this.props}
              title={contactInfoForm.someoneMobile}
              value={this.props.someoneMobile}
              placeholder={"enter your mobile number please"}
              type="number"
              pattern="\d*"
              maxLength="10"
              onChange={value => this.onChangeSomeoneMobile(value)}
              onlyNumber={true}
            />
            {this.props.errors.someoneMobile.length > 0 && (
              <span className="error">{this.props.errors.someoneMobile}</span>
            )}
          </div>
        )}
      </CardPanel>
    );
  }
}

export default Contact_info;
