import React from "react";
import Timing from "../../customer/components/Timing";
import WelcomeBackUser from "./WelcomeBackUser";
import BookingDetailsBox from "./BookingDetailsBox";
import InfoMsg from "./infoMsg";
import ContactUs from "./ContactUs";
import BookingStatus from "./BookingStatus";
import { Box, Fab } from "@material-ui/core";
import "../styles/return.css";
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";

class NewBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      val1: <Check />,
      val2: <Check />,
      val3: <Clear />
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render(props) {
    return (
      <Box className="returnmainContainer">
        <WelcomeBackUser />
        <BookingStatus />
        <Timing
          currentPage={this.props.location.pathname}
          value1={this.state.val1}
          value2={this.state.val2}
          value3={this.state.val3}
        />
        <BookingDetailsBox />
        <InfoMsg />
        <div className="hello">{this.props.success}</div>

        <Fab variant="extended" aria-label="add" className="newbookingbutton">
          Start New Booking - 100SR
        </Fab>
        <ContactUs />
      </Box>
    );
  }
}

export default NewBooking;
