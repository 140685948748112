/* eslint-disable no-undef */
import React from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
  Polygon
} from "react-google-maps";
import Geocode from "react-geocode";
import MapSearchBar from "./MapSearchBar";
import Polygons from "../../constants/json/Polygons";
import { gMapData } from "../../constants/json/ConfigurationJson";
import axios from "axios";
import Modal from "../modal/Modal";

// const gMapKey = "AIzaSyBjdkUySG2ne2VNCvFVdPXwQ91ZKABFLuc";
const gMapKey = "AIzaSyDhqn6NPjPSOLV-EHqlxofvOvqecdj3r08";
Geocode.setApiKey(gMapKey);
Geocode.enableDebug();

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      area: "",
      city: "",
      state: "",
      mapPosition: {
        lat: this.props.location && this.props.location.latitude,
        lng: this.props.location && this.props.location.latitude
      },
      markerPosition: {
        lat: this.props.location && this.props.location.latitude,
        lng: this.props.location && this.props.location.latitude
      },
      polygons: Polygons,
      modalStatus: false
    };
    this.zoom = gMapData.defaultMapZoom;
    this.manualZoom = null;
    this.mapId = "roadmap";
    this.map = React.createRef();
  }

  getGeoLocation = () => {
    console.log("getGeoLocation");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.gMapFromLatLng(position.coords.latitude, position.coords.longitude)
        console.log("logging position....");
        console.log(position);
      });
    } else {
      console.log("navigator.geolocation not found");
    }
  };

  componentDidMount() {
    const coors =
      this.props.location &&
      this.props.location.latitude &&
      this.props.location.latitude != 0
        ? {
            lat: this.props.location.latitude,
            lang: this.props.location.longitude
          }
        : this.props.initialCoors;

    this.gMapFromLatLng(coors.lat, coors.lang);

    if (this.props.location.latitude != 0 ) this.getGeoLocation();

  }

  isPintInsidePolygon(point, vs) {
    let x = point.lat,
      y = point.lng;

    let inside = false;
    for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
      let xi = vs[i].lat,
        yi = vs[i].lng;
      let xj = vs[j].lat,
        yj = vs[j].lng;

      let intersect =
        yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) inside = !inside;
    }

    return inside;
  }

  getCity = addressArray => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0] && "locality" === addressArray[i].types[0]) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getArea = addressArray => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };

  getCountry = addressArray => {
    let country = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "country" === addressArray[i].types[0]
        ) {
          country = addressArray[i].short_name;
          return country;
        }
      }
    }
  };

  getPostalCode = addressArray => {
    let postalCode = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "postal_code" === addressArray[i].types[0]
        ) {
          postalCode = addressArray[i].short_name;
          return postalCode;
        }
      }
    }
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onInfoWindowClose = event => {};

  onMarkerDragEnd = event => {
    console.log("event", event);
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    this.zoom = gMapData.searchMapZoom;
    this.gMapFromLatLng(newLat, newLng);
  };

  async gMapFromLatLng(latitude, longitude) {
    console.log(`gMapFromLatLng ${latitude}${longitude}`);
    const response = await Geocode.fromLatLng(
      latitude,
      longitude,
      gMapKey,
      this.props.language
    );

    const { lat, lng } = response.results[0].geometry.location;
    console.log(`gMapFrom res LatLng ${lat}${lng}`);
    const coor = {
      lat: lat,
      lng: lng
    };

    const enResponse =
      this.props.language == "ar" &&
      (await Geocode.fromLatLng(latitude, longitude, gMapKey, "en"));

    this.get3waFromCoors(coor, response.results[0], enResponse);

    console.log("normalresp", response);
    console.log("enresp", enResponse);
  }

  getAddressResponse(latitude, longitude) {
    Geocode.fromLatLng(latitude, longitude).then(
      response => {
        return response;
      },
      error => {
        console.error(error);
      }
    );
  }

  async onPlaceSelected(place) {
    this.zoom = gMapData.searchMapZoom;
    this.manualZoom = 17;

    if (!place.geometry) {
      return;
    }
    const lat = place.geometry.location.lat(),
      lng = place.geometry.location.lng();

    const coor = {
      lat: lat,
      lng: lng
    };

    const enResponse =
      this.props.language == "ar" &&
      (await Geocode.fromLatLng(lat, lng, gMapKey, "en"));

    this.get3waFromCoors(coor, place, enResponse);
  }

  getMap() {
    const AsyncMap = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          ref={this.map}
          google={this.props.google}
          defaultZoom={this.manualZoom ? this.manualZoom : this.zoom}
          disableDefaultUI={true}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng
          }}
          mapTypeId={this.mapId}
          defaultOptions={{
            fullscreenControl: false,
            streetViewControl: false,
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
              mapTypeIds: ["roadmap", "satellite"],
              position: google.maps.ControlPosition.BOTTOM_LEFT
            }
          }}
          onMapTypeIdChanged={e =>
            (this.mapId = this.map.current.getMapTypeId())
          }
          onZoomChanged={e => (this.manualZoom = this.map.current.getZoom())}
        >
          {this.props.polygons.map(polygon => (
            <Polygon
              path={polygon}
              options={{
                fillColor: "#000",
                fillOpacity: 0.03,
                strokeColor: "#000",
                strokeOpacity: 1,
                strokeWeight: 1
              }}
              onClick={() => {
                console.log("on Tab Polygon");
              }}
            />
          ))}
          <MapSearchBar
            searchBarTitle={this.props.mapForm.findAddress}
            what3wordsPlaceholder={this.props.mapForm.what3wordsPlaceholder}
            isWhat3words={this.props.isWhat3words}
            onPlaceSelected={res => this.onPlaceSelected(res)}
            onCurrentLocation={coor => {
              this.gMapFromLatLng(coor.latitude, coor.longitude);
              this.manualZoom = 17;
            }}
          />
          {this.getMarker()}
        </GoogleMap>
      ))
    );

    return AsyncMap;
  }

  renderPopUp() {
    return (
      <Modal
        show={this.state.modalStatus}
        onCloseModal={() => this.setState({ modalStatus: false })}
      >
        <div className="custom-sub-ui">
          <p>{this.props.alertMesg}</p>
        </div>
      </Modal>
    );
  }

  render() {
    const AsyncMap = this.getMap();

    let map;
    map = (
      <AsyncMap
        googleMapURL={
          "https://maps.googleapis.com/maps/api/js?key=" +
          gMapKey +
          "&libraries=places&language=" +
          this.props.language +
          "&region=KSA"
        }
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: "100%" }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    );

    return (
      <div style={{ height: "100%", width: "100%" }}>
        <div style={{ height: "100%", width: "100%" }}>{map}</div>
        {this.renderPopUp()}
      </div>
    );
  }

  updateAddress(coor, place, enResponse) {
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      district = this.getArea(addressArray),
      country = this.getCountry(addressArray),
      postalCode = this.getPostalCode(addressArray);

    const location = {
      latitude: coor.lat,
      longitude: coor.lng,
      addressLine1: address ? address : "",
      city: city ? city : "",
      district: district ? district : "",
      country: country ? country : "",
      postalCode: postalCode ? postalCode : ""
    };

    const enAddress = enResponse && enResponse.results[0].formatted_address,
      enAddressArray = enResponse && enResponse.results[0].address_components,
      enCity = this.getCity(enAddressArray),
      enDistrict = this.getArea(enAddressArray),
      enCountry = this.getCountry(enAddressArray),
      enPostalCode = this.getPostalCode(enAddressArray);

    const enLocation = {
      latitude: coor.lat,
      longitude: coor.lng,
      addressLine1: enAddress ? enAddress : "",
      city: enCity ? enCity : "",
      district: enDistrict ? enDistrict : "",
      country: enCountry ? enCountry : "",
      postalCode: enPostalCode ? enPostalCode : ""
    };

    this.verifyInPolygons(coor.lat, coor.lng);

    this.props.onAddressChange(location, enLocation);
  }

  verifyInPolygons(lat, lng) {
    let isInside = false;

    for (var poly of this.props.polygons) {
      const check = this.isPintInsidePolygon({ lat: lat, lng: lng }, poly);
      if (check) {
        isInside = check;
        break;
      }
    }
    if (!isInside) {
      this.setState({ modalStatus: true });
      this.props.disableButton(true);
    } else {
      this.props.disableButton(false);
    }
  }

  getMarker() {
    return (
      <Marker
        google={this.props.google}
        name={"Dolores park"}
        draggable={true}
        onDragEnd={this.onMarkerDragEnd}
        position={{
          lat: this.state.markerPosition.lat,
          lng: this.state.markerPosition.lng
        }}
      />
    );
  }

  get3waFromCoors = (coor, googleResponse, enResponse) => {
    const urlString = ` https://api.what3words.com/v3/convert-to-3wa?coordinates= ${coor.lat}%2C${coor.lng}&key=384BNISX&language=${this.props.language}`;
    axios.get(urlString).then(
      res => {
        if (this.props.language == "en") {
          console.log("What3wordsss", res.data.words);
          this.props.onChangeW3w(res.data.words, res.data.words);

          this.updateAddress(coor, googleResponse, enResponse);
          this.setState({
            markerPosition: coor,
            mapPosition: coor
          });
        } else {
          this.get3waEnFromCoors(
            coor,
            googleResponse,
            enResponse,
            res.data.words
          );
        }
      },
      error => {
        console.log(error);
      }
    );
  };

  get3waEnFromCoors = (coor, googleResponse, enResponse, w3wAr) => {
    const urlString = ` https://api.what3words.com/v3/convert-to-3wa?coordinates= ${coor.lat}%2C${coor.lng}&key=384BNISX&language=en`;
    axios.get(urlString).then(
      res => {
        console.log("What3wordsss", res.data.words);
        this.props.onChangeW3w(w3wAr, res.data.words);

        this.updateAddress(coor, googleResponse, enResponse);
        this.setState({
          markerPosition: coor,
          mapPosition: coor
        });
      },
      error => {
        console.log(error);
      }
    );
  };
}

Map.defaultProps = {};
export default Map;
