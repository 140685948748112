import React from "react";
import { Box } from "@material-ui/core";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import {
  TIME_LINE_STATUS_EN,
  TIME_LINE_STATUS_AR
} from "../../../utils/Constants";

const successTrack = {
  border: "2px solid #4bb25b",
  borderRadius: "50%",
  backgroundColor: "#4bb25b",
  color: "white",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
const OnprogressTrack = {
  border: "2px solid rgb(213, 216, 214)",
  color: "rgb(213, 216, 214)",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
const PendingTrack = {
  border: "2px solid #4bb25b",
  color: "#4bb25b",
  borderRadius: "50%",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const Failedstatus = {
  border: "2px solid #d01c1a",
  borderRadius: "50%",
  backgroundColor: "#d01c1a",
  color: "white",
  width: "40px",
  height: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

const successHorizontal = {
  flex: "1 1 auto",
  backgroundColor: "#4bb25a",
  height: "2px",
  border: "none"
};
const pendingHorizontal = {
  flex: "1 1 auto"
};

class Timing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  boxStyle(value) {
    console.log("debug time ", TIME_LINE_STATUS_AR.IN_PROGRESS);
    console.log("debug value ", value);
    switch (value.status) {
      case TIME_LINE_STATUS_EN.COMPLETED:
      case TIME_LINE_STATUS_AR.COMPLETED:
        return successTrack;
      case TIME_LINE_STATUS_EN.PICKED:
      case TIME_LINE_STATUS_AR.PICKED:
        return successTrack;
      case TIME_LINE_STATUS_EN.IN_PROGRESS:
      case TIME_LINE_STATUS_AR.IN_PROGRESS:
        return PendingTrack;
      case TIME_LINE_STATUS_EN.INITIAL:
      case TIME_LINE_STATUS_AR.INITIAL:
        return OnprogressTrack;
      case TIME_LINE_STATUS_EN.FAILED:
      case TIME_LINE_STATUS_AR.FAILED:
        return Failedstatus;
      default:
        return OnprogressTrack;
    }
  }

  firstHr() {
    if (
      this.props.value1.status === TIME_LINE_STATUS_EN.COMPLETED ||
      this.props.value1.status === TIME_LINE_STATUS_AR.COMPLETED
    ) {
      return successHorizontal;
    }
    return pendingHorizontal;
  }
  secondHr() {
    if (
      this.props.value2.status === TIME_LINE_STATUS_EN.COMPLETED ||
      this.props.value2.status === TIME_LINE_STATUS_AR.COMPLETED
    ) {
      return successHorizontal;
    }
    return pendingHorizontal;
  }

  onTap(value, action) {
    if (
      (value.status === TIME_LINE_STATUS_EN.COMPLETED ||
        value.status === TIME_LINE_STATUS_AR.COMPLETED) &&
      action
    ) {
      action();
    }
  }
  render() {
    const { strings } = this.props;
    return (
      <Box className="deliveryTimingSection">
        <Box className="parent_timing_content">
          <Box className="box-timing-text">
            <hr className="horizantal-line horizantal-hidden" />
            <Box
              style={this.boxStyle(this.props.value1)}
              onClick={event =>
                this.onTap(this.props.value1, this.props.value1OnTap)
              }
            >
              {this.getFiller(
                this.props.value1,
                strings && strings.calenderForm.one
              )}
            </Box>

            <hr className="horizantal-line" style={this.firstHr()} />
          </Box>
          <Box className="box-timing-text">
            <hr className="horizantal-line" style={this.firstHr()} />
            <Box
              style={this.boxStyle(this.props.value2)}
              onClick={event =>
                this.onTap(this.props.value1, this.props.value2OnTap)
              }
            >
              {this.getFiller(
                this.props.value2,
                strings && strings.calenderForm.two
              )}
            </Box>
            <hr className="horizantal-line" style={this.secondHr()} />
          </Box>
          {/* <hr style={this.secondHr()} /> */}
          <Box className="box-timing-text">
            <hr className="horizantal-line" style={this.secondHr()} />
            <Box
              style={this.boxStyle(this.props.value3)}
              onClick={event =>
                this.onTap(this.props.value1, this.props.value3OnTap)
              }
            >
              {this.getFiller(
                this.props.value3,
                strings && strings.calenderForm.three
              )}
            </Box>
            <hr className="horizantal-line horizantal-hidden" />
          </Box>
        </Box>
        <Box className="parent_timing_content">
          <Box className="box-timing-text">
            <div className="timeStatus">{this.props.value1.title}</div>
          </Box>
          <Box className="box-timing-text">
            <div className="timeStatus">{this.props.value2.title}</div>
          </Box>
          <Box className="box-timing-text">
            <div className="confirmStatus">{this.props.value3.title}</div>
          </Box>
        </Box>
      </Box>
    );
  }

  getFiller(value, position) {
    switch (value.status) {
      case TIME_LINE_STATUS_EN.COMPLETED:
        return <Check />;
      case TIME_LINE_STATUS_EN.PICKED:
        return <Check />;
      case TIME_LINE_STATUS_EN.FAILED:
        return <Clear />;
      default:
        return position;
    }
  }
}

Timing.defaultProps = {
  value1: { title: "", status: "" },
  value2: { title: "", status: "" },
  value3: { title: "", status: "" }
};

export default Timing;
