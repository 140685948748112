import React from "react";
import "../../coreComponents/Styles/CoreComponents.css";
import { FormLabel, Checkbox, FormControlLabel } from "@material-ui/core";

class CheckBoxValue extends React.Component {
  render() {
    console.log("porps", this.props.check);
    const checkboxElements = this.props.check.map((item, ind) => (
      <div key={item.id}>
        <FormControlLabel
          control={
            <Checkbox
              checked={this.props.selectedAnswers.includes(item.value)}
              onChange={e => this.onChange(item)}
              value={item.value}
              color="primary"
              id={item.id}
            />
          }
          label={item.value}
        />
      </div>
    ));

    return (
      <div className="textFieldContainer">
        <FormLabel
          component="legend"
          style={{
            marginBottom: "10px",
            fontWeight: "600",
            color: "black"
          }}
        >
          {this.props.title}
        </FormLabel>
        {checkboxElements}
      </div>
    );
  }

  onChange(item) {
    let selectedAnswers = [];
    if (this.props.selectedAnswers.includes(item.value)) {
      selectedAnswers = this.props.selectedAnswers.filter(el => {
        return item.value != el;
      });
    } else {
      selectedAnswers = [...this.props.selectedAnswers, item.value];
    }

    this.props.onChange(selectedAnswers);
  }
}

CheckBoxValue.defaultProps = {
  selectedAnswers: []
};

export default CheckBoxValue;
