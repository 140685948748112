import React from "react";
import styles from "../styles/DeliveryCalender.module.css";

class Button extends React.Component {
  handleClick() {
    if (this.props.status !== this.props.strings.calenderForm.notAvailable) {
      if (this.props.onClick) {
        this.props.onClick();
      }
    }
  }
  render() {
    let className = styles.eventItemHolder;
    let textStyle = styles.eventItemText;
    if (this.props.status === this.props.strings.calenderForm.notAvailable) {
      className = styles.fadeItemHolder;
    }
    if (this.props.selected) {
      className = styles.selectedItemHolder;
      textStyle = styles.selectedItemText;
    }
    return (
      <div className={className} onClick={() => this.handleClick()}>
        <div className={textStyle}>{this.props.status}</div>
      </div>
    );
  }
}

export default Button;
