import React from "react";
import Colors from "../../constants/Colors";

export default class ScrollIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: 0
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.scrollProgress);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollProgress);
  }
  scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop;
    const winHeightPx =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = `${(scrollPx / winHeightPx) * 100}%`;

    this.setState({
      scrolled: scrolled
    });
  };
  render() {
    const progressContainerStyle = {
      background: "#eafbea ",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
      height: "5px",
      position: "fixed",
      top: 74,
      width: "100vw",
      maxWidth: "500px",
      zIndex: 99
    };

    const progressBarStyle = {
      height: "5px",
      background: Colors.primaryYellow,
      width: this.state.scrolled
    };

    return (
      <div>
        <div className="progress-container" style={progressContainerStyle}>
          <div className="progress-bar" style={progressBarStyle} />
        </div>
      </div>
    );
  }
}
