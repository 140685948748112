import { SUCCESS, REQUESTING, ERROR } from "../../../utils/Constants";
import languageFormData from "../../../constants/json/LanguageJson";

export const GET_LANGUAGE_FORM_REQUESTING = "GET_LANGUAGE_FORM_REQUESTING";
export const GET_LANGUAGE_FORM_SUCCESS = "GET_LANGUAGE_FORM_SUCCESS";
export const GET_LANGUAGE_FORM_FAILURE = "GET_LANGUAGE_FORM_FAILURE";

export const getCustomerFormRequesting = () => ({
  type: GET_LANGUAGE_FORM_REQUESTING,
  status: REQUESTING
});

export const getCustomerFormSuccess = data => ({
  type: GET_LANGUAGE_FORM_SUCCESS,
  status: SUCCESS,
  data
});

export const getCustomerFormFailure = error => ({
  type: GET_LANGUAGE_FORM_FAILURE,
  status: ERROR,
  error
});

export function getLanguageForm() {
  return async (dispatch, getState, api) => {
    dispatch(getCustomerFormRequesting());
    try {
      dispatch(getCustomerFormSuccess(languageFormData()));
    } catch (e) {
      dispatch(getCustomerFormFailure(e.message));
    }
  };
}
