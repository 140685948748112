import React from "react";
import { confirmAlert } from "react-confirm-alert";
import Clear from "@material-ui/icons/Clear";
import moment from "moment/min/moment-with-locales";

export const isInArray = (value, array) => {
  return array.indexOf(value) > -1;
};

export const showPopUp = mesg => {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className="custom-ui">
          <button onClick={onClose} className="close_Btn">
            <Clear style={{ fontSize: "30px" }} />
          </button>
          <div className="custom-sub-ui">
            <p>{mesg}</p>
          </div>
        </div>
      );
    }
  });
};

export const getUrlVars = url => {
  let vars = {};
  var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m, key, value) {
    vars[key] = value;
  });
  return vars;
};

export const formatTime = time => {
  if (time) {
    if (moment(time).format("hh:mm A") !== "Invalid date") {
      return moment(time).format("hh:mm A");
    } else {
      return moment(time, "hh:mm A").format("LT");
    }
  } else {
    return time;
  }
};

export const isValidEmail = email => {
  const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (EMAIL_REGEX.test(email)) {
    return true;
  } else {
    return false;
  }
};
