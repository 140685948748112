import * as globalActions from "../actions/global.actions";
const language = (
  state = {
    languageFormLoading: false,
    strings: null,
    languageFormStatus: "",
    languageFormError: null
  },
  action
) => {
  switch (action.type) {
    case globalActions.GET_LANGUAGE_FORM_REQUESTING:
      return Object.assign({}, state, {
        languageFormLoading: true,
        languageFormStatus: action.status
      });
    case globalActions.GET_LANGUAGE_FORM_SUCCESS: {
      return Object.assign({}, state, {
        languageFormLoading: false,
        strings: action.data,
        languageFormStatus: action.status
      });
    }
    case globalActions.GET_LANGUAGE_FORM_FAILURE: {
      return Object.assign({}, state, {
        languageFormLoading: false,
        languageFormStatus: action.status,
        languageFormError: action.error
      });
    }

    default:
      return state;
  }
};

export default language;
