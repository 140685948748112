export const gMapData = {
  // initialGeocode: { lat: "24.704972", lang: " 46.715368" },
  defaultMapZoom: 9,
  searchMapZoom: 12,
  mobileData: [
    { country: "SA", mobileLength: 12, mobileCountryCode: 966 },
    { country: "BH", mobileLength: 11, mobileCountryCode: 973 },
    { country: "OM", mobileLength: 11, mobileCountryCode: 968 }
  ]
};

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};
