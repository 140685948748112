import { connect } from "react-redux";
import ErrorPage from "../components/ErrorPage";
import { getLanguageForm } from "../../global/actions/global.actions";

const mapStateToProps = state => {
  console.log("state.book.createBookingData", state.book.createBookingData);
  return {
    createBookingData:
      state.book.createBookingData && state.book.createBookingData,
    strings: state.languageForm.strings
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getLanguageForm: () => dispatch(getLanguageForm())
  };
};

const ErrorContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorPage);

export default ErrorContainer;
