import React from "react";
import Info from "@material-ui/icons/Info";
import { YOU_CAN_UPDATE_BOOKING_MESG } from "../../../utils/Constants";
import {
  ListItemAvatar,
  ListItemText,
  List,
  ListItem,
  Box
} from "@material-ui/core";

class infoMsg extends React.Component {
  render() {
    return (
      <Box>
        <Box>
          <List>
            <ListItem className="infomsglist">
              <ListItemAvatar className="infomsglistavtar">
                <Info className="infoIconstyle" />
              </ListItemAvatar>
              <ListItemText className="infoText">
                {this.props.description}
              </ListItemText>
            </ListItem>
          </List>
        </Box>
      </Box>
    );
  }
}

export default infoMsg;
